<template>
  <InnerLayoutWithSidebar
    v-if="hasEmailBooked"
    :header-label="i18n.$gettext('Mailbox')"
    :default-open="openMobileMenuDefault"
    @created-clicked="showNewFolderModal = true"
  >
    <template #side-panel-content>
      <Tree
        class="p-0 sidebar-tree"
        :value="folders"
        selection-mode="single"
        :context-menu="showContextMenu"
        :active="isActive"
        @node-select="goToFolder"
      >
        <template #default="slotProps">
          <div class="d-flex flex-row">
            <span class="mb-0 flex-shrink-1" :class="{ 'font-italic': slotProps.node.noSelect }" style="word-break: break-word">{{ slotProps.node.label }}</span>
            <Badge v-if="slotProps.node.numberOfUnread" class="ml-1">
              {{ slotProps.node.numberOfUnread }}
            </Badge>
          </div>
        </template>
      </Tree>
      <div v-if="foldersAreLoading">
        <div class="side-panel-menu-item pl-3 pr-3 pt-2 pb-4 d-flex">
          <Skeleton height="18px" class="flex-grow-1" />
        </div>
        <div class="side-panel-menu-item pl-3 pr-3 pt-2 pb-4 d-flex">
          <Skeleton height="18px" class="flex-grow-1" />
        </div>
        <div class="side-panel-menu-item pl-3 pr-3 pt-2 pb-4 d-flex">
          <Skeleton height="18px" class="flex-grow-1" />
        </div>
      </div>
    </template>
    <template #main-content>
      <teleport to="#topmenubar">
        <Button
          v-if="hasEmailBooked"
          :label="i18n.$gettext('Compose new')"
          icon="cil-plus"
          class="p-button p-button-inverse mr-3"
          @click="newMessage"
        />
      </teleport>
      <div class="d-flex flex-row bg-light h-100 w-100">
        <div v-if="folderId" class="mt-3 mt-xl-0 p-0 h-100" :style="flexListStyle">
          <EmailList :folder-id="folderId" :email-id="emailId" :search-query="searchQuery" />
        </div>
        <div
          v-if="emailId"
          class="d-none d-xl-inline-block pr-0 h-100 bg-white"
          :style="flexListStyle"
          style="overflow: auto"
        >
          <DetailViewDialog
            v-if="isOnMobile"
            :folder-id="folderId"
            :email-id="emailId"
            @email:close="closeEmail"
          />
          <DetailView
            v-else
            :folder-id="folderId"
            :email-id="emailId"
            @email:close="closeEmail"
          />
        </div>
      </div>

      <ContextMenu ref="menu" :key="JSON.stringify(menuItems)" :model="menuItems" />

      <Dialog
        v-model:visible="showNewFolderModal"
        :header="i18n.$gettext('New Mail Folder')"
        :modal="true"
        :draggable="false"
        @hide="newFolderName = ''"
      >
        <div>
          <p><translate>Please specify a name for the new Mail Folder:</translate></p>
          <span class="p-float-label w-100">
            <InputText
              v-model="newFolderName"
              type="text"
              class="w-100"
              autofocus
              @keyup.stop.prevent.enter="createFolder"
            />
            <label><translate>Name</translate></label>
          </span>
          <div class="d-flex justify-content-end mt-4">
            <Button
              icon="cil-x"
              class="mr-2 p-button-raised p-button-secondary"
              :label="i18n.$gettext('Abort')"
              @click="showNewFolderModal = false"
            />
            <Button
              icon="cil-plus"
              class="p-button-raised p-button-success"
              :label="i18n.$gettext('Create')"
              :loading="newFolderLoading"
              @click="createFolder"
            />
          </div>
        </div>
      </Dialog>

      <Dialog v-model:visible="showRenameFolderModal" @hide="renameFolderName = folderToRename = ''">
        <template #header>
          <h6><translate>Rename this folder</translate></h6>
        </template>

        <p><translate>Warning: Renaming a folder that contains many messages may result in noticable delays due to reindexing.</translate></p>

        <span class="p-float-label w-100">
          <InputText
            v-model="renameFolderName"
            type="text"
            class="w-100"
            autofocus
            @keyup.stop.prevent.enter="renameFolder"
          />
          <label><translate>Name</translate></label>
        </span>

        <template #footer>
          <Button class="p-button-secondary p-button-text" @click="showRenameFolderModal = false">
            <translate translate-context="Abbrechen">
              Cancel
            </translate>
          </Button>
          <LoadingButton variant="success" :action="renameFolder">
            <translate>Rename Folder</translate>
          </LoadingButton>
        </template>
      </Dialog>

      <Dialog v-model:visible="showPermissionChangeModal" @hide="folderForPermissionChange = null">
        <template #header>
          <h6><translate>Change folder Permissions</translate></h6>
        </template>

        <UserSharingInput
          v-model="permissionsForFolder"
          :access-options="permissionOptions"
          only-allow-suggestions
        >
          <template #header>
            <p class="mt-4">
              <translate>Select the users you want to share this folder with</translate>
            </p>
          </template>
        </UserSharingInput>

        <template #footer>
          <Button class="p-button-secondary p-button-text" @click="showPermissionChangeModal = false">
            <translate translate-context="Abbrechen">
              Cancel
            </translate>
          </Button>
          <LoadingButton variant="success" :action="changeFolderPermissionsRecursive">
            <translate>Apply recursive</translate>
          </LoadingButton>
          <LoadingButton variant="success" :action="changeFolderPermissions">
            <translate>Apply</translate>
          </LoadingButton>
        </template>
      </Dialog>

      <EmailComposer ref="composer" />
      <teleport to="#menubarcontent">
        <SearchBar
          class="pt-2"
          collection-type="EMAIL"
          :collection-id="folderId"
          collection-icon="cil cil-chat-square"
          :collection-name="folderName"
          @search="searchQuery = $event"
        />
      </teleport>
    </template>
  </InnerLayoutWithSidebar>
  <div v-else class="d-flex flex-row bg-light h-100" style="border-radius: 3px; overflow: hidden">
    <div class="d-flex flex-grow-1 flex-column justify-content-center h-100">
      <div class="text-center">
        <p class="h5 mb-2">
          <translate>Emails are disabled</translate>
        </p>
        <p><translate>Please talk to your administrator to enable Emails for your account or purchase an upgrade to your subscription.</translate></p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component'
import EmailList from './EmailList.vue'
import DetailView from './DetailView.vue'
import {rpcClient} from "@/api/WebsocketClient"
import {mailFolderServiceApi} from "@/api/MailFolderServiceApi"
import MailFolder from "@/model/directory/MailFolder"
import SWR from "@/api/SWR"
import MenuBar from "@/components/common/MenuBar.vue"
import EmailComposer from "@/components/email/EmailComposer.vue"
import { ref } from "@vue/reactivity"
import Email from "@/model/entry/Email"
import Tree from "@/components/common/Tree.vue"
import ContextMenu from "primevue/contextmenu"
import Dialog from "primevue/dialog"
import LoadingButton from "@/components/common/LoadingButton.vue"
import Avatar from "@/components/common/Avatar.vue"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import SideBar from "@/components/SideBar.vue"
import Button from "primevue/button"
import RpcError from "@/api/RpcError"
import useToast from "@/util/toasts"
import Skeleton from "primevue/skeleton"
import SettingsUtil from "@/util/SettingsUtil"
import EmailUtil from "@/util/EmailUtil"
import {mailServiceApi} from "@/api/MailServiceApi"
import featureSubset from "@/util/FeatureSubsets"
import InnerLayoutWithSidebar from "@/components/common/InnerLayoutWithSidebar.vue"
import breakpointUtil from "@/util/BreakpointUtil"
import UserSharingInput from "@/components/common/UserSharingInput.vue"
import Permission from "@/model/entry/Permission"
import DetailViewDialog from "@/components/email/DetailViewDialog.vue"
import {useConfirm} from "primevue/useconfirm"
import SearchBar from "@/components/common/SearchBar.vue"
import Query from "@/model/common/Query"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import Badge from 'primevue/badge'
import InputText from "primevue/inputtext"

@Options({
  components: {
    DetailViewDialog,
    UserSharingInput,
    //@ts-ignore
    InnerLayoutWithSidebar, EmailList, DetailView, Tree, MenuBar, InputText, LoadingButton, EmailComposer,
    ContextMenu, Dialog, Avatar, SideBar, Button, Skeleton, SearchBar, Badge
  }
})
export default class EmailInterface extends Vue {

  i18n: Language = useGettext()
  rpcClient = rpcClient
  toast = useToast()
  confirm = useConfirm()
  searchQuery: Query | null = null

  projects: any[] = []
  menuItems: any[] = []

  //@ts-ignore
  menu: ContextMenu = ref<ContextMenu | null>(null)
  //@ts-ignore
  composer: EmailComposer = ref<EmailComposer | null>(null)

  folderOrder: string[] = [ 'inbox', 'sent messages', 'drafts', 'junk', 'trash' ]
  folderForPermissionChange: MailFolder | null = null
  showPermissionChangeModal: boolean = false

  showNewFolderModal = false
  newFolderName = ''
  newFolderLoading = false
  newFolderLocation: string | null = null
  renameFolderName: string = ""
  showRenameFolderModal: boolean = false
  folderToRename: string = ""

  openMobileMenuDefault: boolean = false

  permissionsForFolder: any[] = []
  permissionOptions = [
    {
      id: "READ",
      name: this.i18n.$gettext("Read only")
    },
    {
      id: "WRITE",
      name: this.i18n.$gettext("Read-Write")
    },
    {
      id: "OWNER",
      name: this.i18n.$gettext("Owner")
    }
  ]

  get foldersAreLoading() {
    return Boolean(this.folderSWR?.call?.loading)
  }

  folderSWR!: SWR<MailFolder[], string[]>
  get folders(): any[] {
    this.folderSWR = mailFolderServiceApi.getFolders()
    const folders = this.folderSWR.data ? [...this.folderSWR.data] : []
    const treeNodes: any[] = []
    const order: string[] | null | undefined = SettingsUtil.getLastViewedCollectionOrder('mailFolder')
    if (order) {
      for (let id of order) {
        const folderIndex: number = folders.findIndex(b => b.originalId === id || b.id === id)
        if (folderIndex >= 0) {
          const node: any | undefined = EmailUtil.treeNodeFromFolder(folders[folderIndex], order, this.i18n)
          if (node) {
            treeNodes.push(node)
            folders.splice(folderIndex, 1)
          }
        }
      }
    }
    for (const folderName of this.folderOrder) {
      const folderIndex: number = folders.findIndex(f => f.name?.toLowerCase() === folderName)
      if (folderIndex >= 0) {
        const node: any | undefined = EmailUtil.treeNodeFromFolder(folders[folderIndex], order, this.i18n)
        if (node) {
          treeNodes.push(node)
          folders.splice(folderIndex, 1)
        }
      }
    }
    folders.sort((a, b) => SortAndFilterUtil.compare(a.name, b.name))
    for (const folder of folders) {
      const node: any | undefined = EmailUtil.treeNodeFromFolder(folder, order, this.i18n)
      if (node) {
        treeNodes.push(node)
      }
    }
    const newOrder: string[] = []
    this.getOrderRecursive(newOrder, treeNodes)
    void SettingsUtil.setLastViewedCollectionOrder('mailFolder', newOrder)
    return treeNodes
  }

  getOrderRecursive(order: string[], treeNodes: any[] | null | undefined) {
    for (const node of (treeNodes || [])) {
      if (node.key) {
        order.push(node.key)
      }
      if (node.children) {
        this.getOrderRecursive(order, node.children)
      }
    }
  }

  isActive(node: any): boolean {
    const routedId = this.folderId
    if(routedId){
      return node.key === routedId
    } else {
      return false
    }
  }

  get folderId(): string | null {
    if (this.$route?.params?.hasOwnProperty("folder")) {
      return this.$route.params["folder"] as string
    } else {
      return null
    }
  }

  get folderName(): string | null {
    if (this.folderId) {
      const folder: MailFolder | undefined = mailFolderServiceApi.getMailFolder(this.folderId)
      if (folder) {
        const mapping: { name: string, icon: string } | undefined = EmailUtil.folderMapping(folder, this.i18n)
        return mapping?.name || folder.name
      }
    }
    return null
  }

  get emailId(): string | null {
    if (this.$route?.params?.hasOwnProperty("email")) {
      return this.$route.params["email"] as string
    } else {
      return null
    }
  }

  closeEmail(): void {
    this.goToFolderId(this.folderId || "")
  }

  get isAdmin() {
    return rpcClient.session.user && rpcClient.session.user.roles && (rpcClient.session.user.roles.indexOf('ADMIN') >= 0)
  }

  get isAudit() {
    return rpcClient.session.user && rpcClient.session.user.roles && (rpcClient.session.user.roles.indexOf('AUDIT') >= 0)
  }

  get width() {
    return window.innerWidth
  }

  goToFolder(folder: any) {
    if (folder.key && !folder.noSelect) {
      this.goToFolderId(folder.key)
    }
  }

  goToFolderId(folderId: string){
    void this.$router.push('/mail/' + encodeURIComponent(folderId))
    if (folderId !== "") {
      void SettingsUtil.setLastViewedCollection('mailFolder', folderId)
    }
  }

  newMessage(): void {
    this.composer.show(new Email(), null, this.folderId, null, null)
  }

  get isOnMobile(){
    return breakpointUtil.isOnXl()
  }

  showInboxContextMenu(event: Event) {
    if (this.menu) {
      this.menuItems = [
        {
          label:'New Subfolder',
          icon:'cil-inbox',
          command: () => {
            this.newFolderLocation = null
            this.showNewFolderModal = true
          }
        },
        {
          label:'Share with...',
          icon:'cil-share',
          command: () => {  }
        }
      ]
      void this.$nextTick(() => {
        this.menu.toggle(event)
      })
    }
  }

  showContextMenu(item: any, event: Event) {
    if (this.menu) {
      this.updateMenuItems(item)
      void this.$nextTick(() => {
        this.menu.toggle(event)
      })
    }
  }

  menuItemFromFolder(source: MailFolder, target: MailFolder, copy: boolean): any {
    const mapping: { name: string, icon: string } | undefined = EmailUtil.folderMapping(target, this.i18n)
    const item: any = {
      label: mapping?.name || target.name,
      icon: mapping?.icon || 'cil-inbox',
      command: () => {
        if (source.originalId && target.originalId) {
          mailFolderServiceApi._move(source.originalId, target.originalId, copy).then(() => {
            this.toast.success(this.i18n.$gettext("Folder moved"))
          }).catch((e: RpcError) => {
            this.toast.error(e.message, this.i18n.$gettext("Could not move folder"))
          })
        }
      }
    }

    const otherFolders: MailFolder[] | undefined = target.subFolders ? target.subFolders.filter(other => other.originalId !== source.originalId) : undefined
    if (otherFolders && otherFolders.length > 0) {
      item['items'] = otherFolders.map(subFolder => this.menuItemFromFolder(source, subFolder, copy))
    }

    return item
  }

  renameFolder(){
    this.confirm.require({
      message: this.i18n.$gettext('Do you really want to move this folder? Moving big folders may result in reindexing!'),
      header: this.i18n.$gettext('Confirmation'),
      icon: 'cil-warning',
      accept: () => {
        if (this.folderToRename !== "" && this.renameFolderName.trim() !== "") {
          mailFolderServiceApi._renameFolder(this.folderToRename, this.renameFolderName.trim()).then(() => {
            this.toast.success(this.i18n.$gettext("Folder renamed"))
            this.$nextTick(() => {
              mailFolderServiceApi.getFolders(true)
            })
          }).catch((e: RpcError) => {
            this.toast.error(e.message, this.i18n.$gettext("Could not rename Folder"))
          }).finally(() => {
            this.showRenameFolderModal = false
            this.renameFolderName = ""
          })
        } else {
          this.toast.error(this.i18n.$gettext("Could not determine new folder or new name."))
        }

      },
      reject: () => {
        this.folderToRename = ""
        //callback to execute when user rejects the action
      }
    })
  }

  updateMenuItems(treeItem: any) {
    const folders: MailFolder[] | null = mailFolderServiceApi.getFolders().data
    const folder: MailFolder | undefined = folders ? this.findFolder(treeItem.key, folders) : undefined

    if (!folder || folder?.noSelect){
      this.menuItems = [{
        label: this.i18n.$gettext("This folder is shared. You cannot edit it."),
        disabled: true
      }]
      return
    }

    const myUsername: string = rpcClient.session.user?.userName || ""
    const canWrite = folder.permissions?.find(perm =>  perm.userName === myUsername )?.mode === "WRITE"
    const owner = folder.permissions?.find(perm =>  perm.userName === myUsername )?.mode === "OWNER"

    if (folder && (canWrite || owner)) {
      const items: any[] = [
        {
          label: this.i18n.$gettext('Mark all read'),
          icon: 'fa fa-eye',
          command: () => {
            if (folder.originalId) {
              void mailServiceApi._markAllRead(folder.originalId)
            }
          }
        },
        {
          label: this.i18n.$gettext('Create Subfolder'),
          icon: 'fa fa-folder',
          command: () => {
            this.newFolderLocation = folder.id
            this.showNewFolderModal = true
          }
        }
      ]

      if(owner){
        items.push({
          label: this.i18n.$gettext('Set Permissions'),
          icon: 'cil-security',
          command: () => {
            if(folder){
              //Copy over permissions:
              this.permissionsForFolder = []
              folder.permissions?.forEach( (perm: Permission) => {
                this.permissionsForFolder.push({
                  sharee: 'mailto:' + perm.userName,
                  userName: perm.userName,
                  commonName: perm.userName,
                  accessRight: perm.mode
                })
              })
              this.openFolderPermissionsModal(folder)
            }
          }
        })
      }


      if(!folder.type && (canWrite || owner)){

        items.push({
          label: this.i18n.$gettext('Rename Folder'),
          icon: 'cil-pencil',
          command: () => {
            if(folder){
              this.showRenameFolderModal = true
              this.folderToRename = folder.originalId || ""
            }
          }
        })
      }

      if(!folder.type && (canWrite || owner)){
        items.push({
          label: this.i18n.$gettext('Delete'),
          icon: 'cil-trash',
          command: () => {
            this.deleteFolder(folder)
          }
        })
      }

      const otherFolders: MailFolder[] | undefined = folders ? folders.filter(other => other.originalId !== folder.originalId) : undefined
      if (otherFolders && otherFolders.length > 0 && canWrite) {
        items.push({
          label: this.i18n.$gettext('Move to...'),
          icon: 'cil-folder-move',
          items: otherFolders.map(target => this.menuItemFromFolder(folder, target, false))
        })
        /*items.push({
          label: this.i18n.$gettext('Copy to...'),
          icon: '',
          items: folders.map(folder => this.menuItemFromFolder(folder, false))
        })*/
      }

      if(items.length == 0){
        this.menuItems = [{
          label: this.i18n.$gettext("No actions available."),
          disabled: true
        }]
      }

      this.menuItems = items
    } else {
      this.menuItems = []
    }
  }

  findFolder(id: string, folders: MailFolder[]): MailFolder | undefined {
    for (const folder of folders) {
      if (folder.originalId === id) {
        return folder
      } else if (folder.subFolders) {
        const subFolder: MailFolder | undefined = this.findFolder(id, folder.subFolders)
        if (subFolder) {
          return subFolder
        }
      }
    }
    return undefined
  }

  deleteFolder(folder: MailFolder) {
    this.confirm.require({
      message: this.i18n.$gettext('Do you really want to delete this folder?'),
      header: this.i18n.$gettext('Confirmation'),
      icon: 'cil-warning',
      accept: () => {
        if (folder.originalId) {
          mailFolderServiceApi._deleteFolder(folder.originalId).then(() => {
            this.toast.success(this.i18n.$gettext("Folder deleted"))
            if (this.folderId == folder.originalId) {
              this.goToFolderId("")
            }
          }).catch((e: RpcError) => {
            this.toast.error(e.message, this.i18n.$gettext("Could not delete Folder"))
          })
        }
      },
      reject: () => {
        //callback to execute when user rejects the action
      }
    })
  }

  createFolder() {
    if (this.newFolderName === "") return
    let newFolderName = this.newFolderName.trim()
    if(newFolderName.indexOf(".") > -1){
      this.toast.error(this.i18n.$gettext("Folder names are not allowed to contain dots."))
      return Promise.reject()
    }
    this.newFolderLoading = true
    return mailFolderServiceApi._createFolder(this.newFolderName, this.newFolderLocation).then(() => {
      this.toast.success(this.i18n.$gettext("Mail folder created"))
      this.newFolderName = ""
      this.showNewFolderModal = false
    }).catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext("Could not create mail folder"))
    }).finally(() => {
      this.newFolderLoading = false
    }) //TODO subfolder
  }

  get hasEmailBooked(): boolean {
    return featureSubset.hasMail
  }

  get flexListStyle(): Object {
    if (breakpointUtil.isOnMobile()) {
      return { "width": "100%"}
    } else if (breakpointUtil.isOnXl()) {
      return { "width": "100%"}
    } else {
      return {
        "flex-basis": "50%"
      }
    }
  }

  openFolderPermissionsModal(folder: MailFolder){
    this.folderForPermissionChange = folder
    this.showPermissionChangeModal = true
  }

  changeFolderPermissionsRecursive(): Promise<void> {
    return this.changeFolderPermissions(true)
  }

  changeFolderPermissions(recursive: boolean = false): Promise<void> {

    if(!this.folderForPermissionChange?.originalId || !this.permissionsForFolder){
      this.toast.error(this.i18n.$gettext("Could not determine folder to change permissions for"))
      return Promise.reject()
    }

    const perms: Permission[] = []
    this.permissionsForFolder.forEach( (it: any ) => {
      const p = new Permission()
      p.userName = it.userName
      p.mode = it.accessRight
      perms.push(p)
    })

    return mailFolderServiceApi._changeFolderACL(this.folderForPermissionChange.originalId, perms, recursive).then(() => {
      this.toast.success(this.i18n.$gettext("Folder permissions changed"))
      this.showPermissionChangeModal = false
    }).catch((err: RpcError) => {
      this.toast.error(this.i18n.$gettext("Could not change folder permissions"), err.message)
    })
  }

  mounted() {
    if (!this.folderId) {
      let lastViewed: string | null | undefined = SettingsUtil.getLastViewedCollection('mailFolder')
      const folder = this.folders?.find((a: any) => !a.noSelect && (a.key === lastViewed || a.legacyId === lastViewed))
      if (!this.foldersAreLoading && !folder) {
        //This mailFolder no longer exist.
        lastViewed = undefined
        //Reset settings
        void SettingsUtil.setLastViewedCollection('mailFolder', '')
      }
      if (folder) {
        this.goToFolderId(folder.key)
      } else {
        this.openMobileMenuDefault = true
      }
    }
  }
}
</script>

<style scoped lang="scss">
.row [class*='col-'] {
  transition: flex 0.5s ease-in-out, max-width .5s ease-in-out, max-height .5s ease-in-out;
}

@media screen and (min-width: 1200px) {
  .min-vh-xl-60 {
    min-height: 60vh;
  }
}

.side-panel {

  .p-tree {

    border: none;

    .p-tree-container .p-treenode {
      border-radius: 0px;
      padding: 0;

      .p-treenode-content {
        border-radius: 0px;
      }
    }

  }

}
</style>
