import Email from "@/model/entry/Email"
import MailFolder from "@/model/directory/MailFolder"
import {Language} from "@jshmrtn/vue3-gettext"
import dayjs from "dayjs"
import MailFilter from "@/model/settings/MailFilter"

class TreeNode {
    key!: string | null
    label!: string
    data!: string | null
    icon!: string
    noSelect!: boolean
    children!: TreeNode[]
}

export default class EmailUtil {

    static emailRegex = new RegExp('^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$')

    static insaneOptions: any = {
        "allowedAttributes": {
            "a": ["href", "name", "target"],
            "img": ["src"],
            "span": ["class"]
        },
        "allowedClasses": {},
        "allowedSchemes": ["http", "https", "mailto"],
        "allowedTags": [
            "!DOCTYPE", "DOCTYPE", "html", "head", "meta", "body", "style", "link", "a", "article", "b", "blockquote", "br", "caption", "code", "del", "details", "div", "em",
            "h1", "h2", "h3", "h4", "h5", "h6", "hr", "i", "img", "ins", "kbd", "li", "main", "ol",
            "p", "pre", "section", "span", "strike", "strong", "sub", "summary", "sup", "table",
            "tbody", "td", "th", "thead", "tr", "u", "ul"
        ],
        "filter": null,
        "transformText": null
    }

    static isValidEmail(input: string | null | undefined): boolean {
        //TODO: The regex doens't work correctly
        return !!input && input.includes('@') && input.split('@')[1].includes('.')
    }

    static createReply(email: Email, replyAll: boolean): Email {
        const reply: Email = new Email()
        reply.subject = 'Re: ' + email.subject
        reply.to = (email.replyTo && email.replyTo.length > 0) ? email.replyTo :email.from
        if (replyAll) {
            if (reply.to && email.to) reply.to.push(...email.to)
            if (reply.to && email.cc) reply.to.push(...email.cc)
            if (reply.to && email.bcc) reply.to.push(...email.bcc)
        }
        const date: Date | null = email.receivedDate ? new Date(email.receivedDate) : null
        const name = email.senderName || ''
        const address = email.senderEmail || ''
        let html: string | null = null
        if (email.htmlBody) {
            html = this.extractBodyHTML(email.htmlBody)
        } else if (email.textBody) {
            const text = email.textBody
            html = ''
            let start = 0, end = 0
            const nl = '\n'
            while ((end = text.indexOf(nl, start)) !== -1) {
                html += '<br>' + text.substring(start, end)
                start = end + 1
            }
        }
        if (date && html) {
            reply.htmlBody = '<html><body><br><br><div><blockquote type="cite">' +
                '<p>Am ' + dayjs(date).format('LLLL') + ' schrieb ' + name + ' ' +
                '&lt;<a href=3D"mailto:' + address + '">' + address + '</a>&gt;:<br></p><div>' +
                html + '</div></blockquote></div></body></html>'
        } else if (html) {
            reply.htmlBody = '<html><body><br><br><div><blockquote type="cite">' + name + ' ' +
                '&lt;<a href=3D"mailto:' + address + '">' + address + '</a>&gt; schrieb:<br></p><div>' +
                html + '</div></blockquote></div></body></html>'
        }
        return reply
    }

    static createForward(email: Email): Email {
        const reply: Email = new Email()
        reply.subject = 'Fwd: ' + (email.subject || "")
        const date: Date | null = email.receivedDate ? new Date(email.receivedDate) : null
        const name = email.senderName || ''
        const address = email.senderEmail || ''
        let html: string | null = null
        if (email.htmlBody) {
            html = this.extractBodyHTML(email.htmlBody)
        } else if (email.textBody) {
            const text = email.textBody
            html = ''
            let start = 0, end = 0
            const nl = '\n'
            while ((end = text.indexOf(nl, start)) !== -1) {
                html += '<br>' + text.substring(start, end)
                start = end + 1
            }
        }
        reply.attachments = email.attachments
        if (date && html) {
            reply.htmlBody = '<html><body><br><br><div><blockquote type="cite">' +
                '<p>Weitergeleitete Nachricht von ' + name + ' &lt;<a href=3D"mailto:' + address + '">' + address +
                '</a>&gt; vom ' + dayjs(date).format('LLLL') + ':<br></p><div>' +
                html + '</div></blockquote></div></body></html>'
        } else if (html) {
            reply.htmlBody = '<html><body><br><br><div><blockquote type="cite">' +
                '<p>Weitergeleitete Nachricht von ' + name + ' &lt;<a href=3D"mailto:' + address + '">' + address +
                '</a>&gt;:<br></p><div>' +
                html + '</div></blockquote></div></body></html>'
        }
        return reply
    }

    static extractBodyHTML(html: string): string {
        const startIndex: number = html.indexOf('<body')
        const endIndex: number = html.indexOf('</body')
        let body = html.substring(startIndex > 0 ? startIndex : 0, endIndex > 0 ? endIndex : html.length)
        if (startIndex >= 0) {
            body = body.substring(body.indexOf('>') + 1).trim()
        }
        return body
    }

    static treeNodeFromFolder(folder: MailFolder, order: string[] | null | undefined, i18n: Language): any | undefined {
        if (folder) {
            let label: string = folder.name || i18n.$gettext('Unnamed Folder')
            let icon = 'cil-inbox'
            if (folder.name) {
                const mapping: { name: string, icon: string } | undefined = this.folderMapping(folder, i18n)
                if (mapping) {
                    label = mapping.name || label
                    icon = mapping.icon || icon
                }
            }
            const children: TreeNode[] = []
            const subFolders: MailFolder[] = [...(folder.subFolders || [])]
            let numberOfUnread = 0
            if (order && subFolders.length > 0) {
                for (const id of order) {
                    const folderIndex: number = subFolders.findIndex(b => b.originalId === id || b.id === id)
                    if (folderIndex >= 0) {
                        const node: any | undefined = EmailUtil.treeNodeFromFolder(subFolders[folderIndex], order, i18n)
                        if (node) {
                            numberOfUnread += node.numberOfUnread || 0
                            children.push(node)
                            subFolders.splice(folderIndex, 1)
                        }
                    }
                }
            }
            for (const subFolder of subFolders) {
                const subNode: any | undefined = this.treeNodeFromFolder(subFolder, order, i18n)
                if (subNode) {
                    numberOfUnread += subNode.numberOfUnread || 0
                    children.push(subNode)
                }
            }
            return {
                key: folder.originalId,
                label: label,
                data: folder.fullName,
                icon: icon,
                children: children,
                noSelect: folder.noSelect || false,
                legacyId: folder.id,
                numberOfUnread: (folder.numberOfUnread || 0) + numberOfUnread
            }
        }
    }

    static folderMapping(folder: MailFolder, i18n: Language): { name: string, icon: string } | undefined {
        const folderMapping: any = {
            INBOX: {
                name: i18n.$gettext('Inbox'),
                icon: 'cil-inbox-in'
            },
            Outbox: {
                name: i18n.$gettext('Outbox'),
                icon: 'cil-inbox-out'
            },
            '\\Sent': {
                name: i18n.$gettext('Sent Messages'),
                icon: 'cil-send'
            },
            '\\Trash': {
                name: i18n.$gettext('Deleted Messages'),
                icon: 'cil-trash'
            },
            '\\Drafts': {
                name: i18n.$gettext('Drafts'),
                icon: 'cil-pencil'
            },
            '\\Junk': {
                name: i18n.$gettext('Spam'),
                icon: 'cil-trash-x'
            },
            notes: {
                name: i18n.$gettext('Notes'),
                icon: 'cil-note'
            }
        }
        return folder.type ? folderMapping[folder.type] : (folder.name ? folderMapping[folder.name.toLowerCase()] : undefined)
    }

    static isValid(mailFilter: MailFilter) {
        return Boolean(mailFilter.rules && mailFilter.rules.length > 0 && mailFilter.action && !mailFilter.rules.find(rule => {
            if (!rule.prefix || !rule.field) {
                return true
            } else if (['HAS_ATTACHMENT', 'HAS_NO_ATTACHMENT', 'IS_SPAM', 'IS_NOT_SPAM'].indexOf(rule.field) < 0) {
                return !rule.operator || !rule.value
            }
        }) && (![ 'REDIRECT', 'REJECT', 'FILEINTO' ].includes(mailFilter.action) || mailFilter.target))
    }
}
