
import {Options, Vue} from "vue-class-component"
import CalendarEvent from "../../model/entry/Event"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import User from "@/model/User"
import {userServiceApi} from "@/api/UserServiceApi"
import {rpcClient} from "@/api/WebsocketClient"
import Dialog from "primevue/dialog"
import Button from "primevue/button"
import useToast from "@/util/toasts"
import Chip from 'primevue/chip'
import breakpointUtil from "@/util/BreakpointUtil"
import SchedulingObject from "@/model/SchedulingObject"
import {calendarInboxServiceApi} from "@/api/CalendarInboxServiceApi"
import SWR from "@/api/SWR"
import {Router, useRouter} from "vue-router"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import {eventServiceApi} from "@/api/EventServiceApi"
import {reactive} from "@vue/reactivity"
import Event from "../../model/entry/Event"
import InboxItem from "./InboxItem.vue"

@Options({
  components: {
    //@ts-ignore
    InboxItem, Dialog, Button, Chip
  },
  //@ts-ignore
  emits: [
    'hide'
  ]
})
export default class InboxDetails extends Vue {

  rpcClient = rpcClient

  i18n: Language = useGettext()
  toast = useToast()
  router: Router = useRouter()

  inboxIsLoading: boolean = false
  visibleInternal: boolean = false
  handlingIMIPLoading: boolean = false

  ITIP_REQUEST: string = "REQUEST"

  Participation_NEEDS_ACTION: string = "NEEDS_ACTION"
  Participation_ACCEPTED: string = "ACCEPTED"
  Participation_DECLINED: string = "DECLINED"
  Participation_TENTATIVE: string = "TENTATIVE"
  Participation_DELEGATED: string = "DELEGATED"

  show() {
    this.visibleInternal = true
  }

  toggle() {
    this.visibleInternal = !this.visibleInternal
  }

  hide() {
    this.visibleInternal = false
    this.$emit('hide')
  }

  get users(): User[] {
    return userServiceApi.getUsers().data || []
  }

  goToEvent(event: CalendarEvent) {
    if (event && event.originalParentId && event.originalId) {
      void this.router.push('/calendar/' + event.originalParentId + '/' + event.originalId)
    }
  }

  get inbox(): { eventId: string, event: CalendarEvent | null, messages: SchedulingObject[] }[] {
    const swr: SWR<SchedulingObject[], string[]> = calendarInboxServiceApi.readInbox()
    this.inboxIsLoading = Boolean(swr.call?.loading && swr.call?.promise)
    if (swr.call?.promise){
      swr.call.promise.finally(() => {
        this.inboxIsLoading = false
      })
    }
    const inbox: Map<string, { eventId: string, event: CalendarEvent, messages: SchedulingObject[] }> = new Map<string, { eventId: string, event: CalendarEvent, messages: SchedulingObject[] }>();
    (swr.data || []).forEach((s: SchedulingObject) => {
      const eventWithRequest: CalendarEvent | undefined = s.eventsFromRequest ? s.eventsFromRequest.find(e => !!e.requestFor) : undefined
      const requestFor: CalendarEvent | null | undefined = eventWithRequest?.requestFor
      if (eventWithRequest && requestFor) {
        const eventId: string = requestFor.originalId || ''
        eventWithRequest.requestFor = requestFor
        let swr: SWR<Event | null, string> | null = null
        if (eventId) {
          swr = eventServiceApi.getEvent(eventId)
          eventWithRequest.requestFor = swr.data || requestFor
        }
        let objects = inbox.get(eventId)
        if (!objects) {
          objects = reactive({
            eventId: eventId,
            event: eventWithRequest,
            messages: []
          })
          inbox.set(eventId, objects)
          if (swr) {
            swr.call?.promise?.finally(() => {
              if (swr) {
                eventWithRequest.requestFor = swr.data || eventWithRequest.requestFor
              }
            })
          }
        } else if (!objects.event) {
          objects.event = eventWithRequest
        }
        objects.messages.push(s)
      } else if (s.eventsFromRequest && s.eventsFromRequest.length > 0) {
        const eventId: string = s.eventsFromRequest[0].uid || ''
        let objects = inbox.get(eventId)
        if (!objects) {
          objects = {
            eventId: eventId,
            event: s.eventsFromRequest[0],
            messages: []
          }
          inbox.set(eventId, objects)
        }
        objects.messages.push(s)
      }
    })
    inbox.forEach(value => {
      value.messages = value.messages.sort((i, j) => {
        if (i.eventsFromRequest && j.eventsFromRequest) {
          const iDate = i.eventsFromRequest[0].stamp || i.eventsFromRequest[0].lastModified
          const jDate = j.eventsFromRequest[0].stamp || j.eventsFromRequest[0].lastModified
          return SortAndFilterUtil.compare(jDate, iDate)
        } else {
          return 0
        }
      })
    })
    return Array.from(inbox.values()).sort((i, j) => {
      if (i.messages && i.messages[0].eventsFromRequest && j.messages && j.messages[0].eventsFromRequest) {
        const iEvents = i.messages[0].eventsFromRequest
        const jEvents = j.messages[0].eventsFromRequest
        if (iEvents && jEvents) {
          const iDate = iEvents[0].stamp || iEvents[0].lastModified
          const jDate = jEvents[0].stamp || jEvents[0].lastModified
          return SortAndFilterUtil.compare(jDate, iDate)
        }
      }
      return 0
    })
  }

  deleteAll() {
    for (let i of this.inbox) {
      this.deleteRequest(i)
    }
  }

  deleteRequest(i: { eventId: string, event: CalendarEvent | null, messages: SchedulingObject[] }) {
    for (let s of i.messages) {
      if (s.id) {
        calendarInboxServiceApi._deleteRequest(s.id).then(() => {
          this.toast.success(this.i18n.$gettext('Notification dismissed'))
        }).catch(() => {
          this.toast.error(this.i18n.$gettext("Failed to dismiss notification"))
        }).finally(() => {
          if (!this.inbox || this.inbox.length == 0) this.hide()
        })
      }
    }
  }

  handleCounter(so: SchedulingObject, accepted: boolean) {
    if (so && so.id) {
      this.handlingIMIPLoading = true
      calendarInboxServiceApi._handleCounter(so, accepted).then(() => {
        if (accepted) this.toast.success(this.i18n.$gettext('You accepted the counter event'))
        else this.toast.success(this.i18n.$gettext('You declined the counter event'))
      }).catch(() => {
        this.toast.error(this.i18n.$gettext("Failed to reply to counter event"))
      }).finally(() => {
        this.handlingIMIPLoading = false
      })
    } else{
      this.toast.error(this.i18n.$gettext("Invalid scheduling request"))
    }
  }

  get userEmail(): string | null {
    return rpcClient.session?.user?.email || null
  }

  setMyParticipationStatus(event: CalendarEvent, status: string): void {
    if (!event.attendees) return
    let attendee = event.attendees.find(a => a.email === this.userEmail)
    if (attendee) attendee.status = status
  }

  handleInvitation(so: SchedulingObject, event: CalendarEvent, status: string) {
    if(so && so.id && event && status) {
      this.handlingIMIPLoading = true
      calendarInboxServiceApi._handleInvitation(so, event, status).then(() => {
        if (status === this.Participation_ACCEPTED) this.toast.success(this.i18n.$gettext('Your new attendee status is accepted'))
        if (status === this.Participation_DECLINED) this.toast.success(this.i18n.$gettext('Your new attendee status is declined'))
        if (status === this.Participation_TENTATIVE) this.toast.success(this.i18n.$gettext('Your new attendee status is tentative'))
        this.setMyParticipationStatus(event, status)
      }).catch(() => {
        this.toast.error(this.i18n.$gettext("Failed to update participation"))
      }).finally(() => {
        this.handlingIMIPLoading = false
      })
    } else{
      this.toast.error(this.i18n.$gettext("Invalid scheduling request"))
    }
  }

  handleCancel(so: SchedulingObject, event: CalendarEvent) {
    if (so && so.id && event) {
      this.handlingIMIPLoading = true
      calendarInboxServiceApi._handleCancel(so, event).then(() => {
        this.toast.success(this.i18n.$gettext('Cancel accepted'))
      }).catch(() => {
        this.toast.error(this.i18n.$gettext("Cancel failed"))
      }).finally(() => {
        this.handlingIMIPLoading = false
      })
    } else if (event?.originalId) {
      eventServiceApi._deleteEvent(event.originalId)
    } else {
      this.toast.error(this.i18n.$gettext("Invalid scheduling request"))
    }
  }

  get modalStyle(){
    if (breakpointUtil.isOnMobile()) {
      return { width: "100%", margin: "0", height: "100% !important", maxHeight: "100%" }
    } else {
      return { width: "50%", margin: "1rem", height: "100%" }
    }
  }
}
