

import {Options, Vue} from "vue-class-component"
import Button from "primevue/button"
import Skeleton from "primevue/skeleton"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {pSTImportServiceApi} from "@/api/PSTImportServiceApi"
import useToast, {ToastAPI} from "@/util/toasts"
import Dialog from "primevue/dialog"
import FileUpload, {FileUploadBeforeUploadEvent} from "primevue/fileupload"
import {rpcClient} from "@/api/WebsocketClient"
import fileSizeString from "@/util/fileSize"
import dayjs from "@/util/dayjs"
import TreeSelect from "primevue/treeselect"
import LoadingButton from "@/components/common/LoadingButton.vue"
import Dropdown from "@/components/common/Dropdown.vue"
import {useConfirm} from "primevue/useconfirm"
import RpcError from "@/api/RpcError"

@Options({
  //@ts-ignore
  props: {
  },
  components: {
    LoadingButton,
    Button, Skeleton, Dialog, FileUpload, TreeSelect, Dropdown
  }
})
export default class PSTImport extends Vue {

  i18n: Language = useGettext()
  toast: ToastAPI = useToast()
  confirm = useConfirm()

  loading: boolean = false

  showUploadModal: boolean = false

  elementToImport: { name: string, size: string, mdate: string } | null = null
  contentLoading: boolean = false
  pstContent: any = null
  selectedRootFolder: any = null
  selectedFile: string = ""

  files: { name: string, size: string, mdate: string }[] = []
  users: any[] = []
  selectedUser: string = ""

  mounted(){
    void this.loadData()
  }

  async loadData(){
    this.loading = true
    try {
      this.files = await pSTImportServiceApi._getFiles()
    } catch (e){
      this.toast.error(this.i18n.$gettext("An Error occured while fetching the PST files from the server"))
    }
    try {
      this.users = await pSTImportServiceApi._getAvailableMailboxesForImport()
    } catch (e){
      this.toast.error(this.i18n.$gettext("An Error occured while fetching the PST files from the server"))
    }
    this.loading = false
  }

  async loadPstContent(file: string){
    this.contentLoading = true
    try {
      this.selectedFile = file
      this.pstContent = [ await pSTImportServiceApi._getFileContents(file) ]
    } catch (e){
      this.toast.error(this.i18n.$gettext("An Error occured while fetching the PST folders from the server"))
      this.pstContent = null
    } finally {
      this.contentLoading = false
    }
  }

  async startImport(){
    //Get selected node:
    let foldername = ""
    for (const [key, value] of Object.entries(this.selectedRootFolder)) {
      if(value === true){
        foldername = key //use first match
        break
      }
    }

    //Create options:

    const payload : any = {
      "users": {},
      "mailContainer": "de_uniki_mail_emailpostfcher"
    }

    payload.users[this.selectedUser] = {
      "filePath": "~" + this.selectedFile,
      "folderPath": this.foldername !== "" ? foldername: null
    }

    try {
      await rpcClient.getAjaxClient().post("/migration/pst", payload)
      this.toast.success(this.i18n.$gettext("The import will run in the background"), this.i18n.$gettext("PST Import started"))
      this.elementToImport = null
      this.selectedFile = ""
      this.pstContent  = null
    } catch (e){
      this.toast.error(this.i18n.$gettext("Could not start PST File import"))
    }
  }

  get hasElementToImport(): boolean {
    return this.elementToImport !== null
  }

  openUploadDialog(){
    this.showUploadModal = true
  }

  openImportDialog(toImport: { name: string, size: string, mdate: string }){
    this.elementToImport = toImport
    void this.loadPstContent(toImport.name)
  }

  authenticateUpload(event: FileUploadBeforeUploadEvent){
    rpcClient.authenticateXHR(event.xhr)
  }

  onUploadDone(){
    this.showUploadModal = false
    void this.loadData()
  }

  getHumanReadableDate(date: string){
    const asDate = new Date(Number.parseInt(date))
    return dayjs(asDate).format("DD.MM.YYYY, HH:mm")
  }

  getFileSizeHumanReadable(num: string) {
    const asNum: number = Number.parseInt(num)
    return fileSizeString(asNum)
  }

  openDeleteConfirm(name: string){
    this.confirm.require({
      message: this.i18n.$gettext("Do you want to delete this PST File?"),
      header: this.i18n.$gettext("Confirmation"),
      icon: 'cil-warning',
      accept: () => {
        pSTImportServiceApi._deleteFile(name).then(() => {
          this.toast.success(this.i18n.$gettext("Delete successful"))
          void this.loadData()
        }).catch((e: RpcError) => {
          this.toast.error(e.message, this.i18n.$gettext("Failed to delete"))
        })
      },
      reject: () => {
        //callback to execute when user rejects the action
      }
    })
  }
}
