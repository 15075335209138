import GeneratedTaskServiceApi from "./generated/GeneratedTaskServiceApi"
import { taskStore } from '@/store/TaskStore'
import {taskBoardStore} from "@/store/TaskBoardStore"
import TaskBoard from "@/model/directory/TaskBoard";
import Task from "@/model/entry/Task";

class TaskServiceApi extends GeneratedTaskServiceApi {
    getCategoriesOptions(boardId: string = ""): string[] {
        const columns: string[] = []
        for (const taskBoard of taskBoardStore.state.taskBoards.values()) {
            if (taskBoard.meta?.taskLists) {
                for (const list of taskBoard.meta?.taskLists) {
                    if (list.name && !columns.includes(list.name)) {
                        columns.push(list.name)
                    }
                }
            }
        }

        const categories: string[] = []
        for (const task of taskStore.state.tasks.values()) {
            if (task.categories) {
                for (const category of task.categories) {
                    if (!columns.includes(category) && !categories.includes(category)) {
                        if(boardId === "" || (boardId === task.originalParentId)){
                            categories.push(category)
                        }
                    }
                }
            }
        }
        return categories
    }
}

export const taskServiceApi = new TaskServiceApi()
