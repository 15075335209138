<template>
  <div class="container-fluid">
    <div v-if="hasLink" class="row">
      <div class="col">
        <p><translate>For this Item a upload link already exists</translate>:</p>
        <a :href="href">{{ href }}</a>
        <p>{{ humanreadableDate }}</p>
        <Button
          class="p-button-raised"
          :loading="actionIsLoading"
          :label="i18n.$gettext('Delete')"
          @click="deleteLink"
        />
      </div>
    </div>
    <div v-else-if="loading" class="row">
      <div class="col">
        <ProgressBar mode="indeterminate" />
      </div>
    </div>
    <div v-else-if="fetchFailed" class="row">
      <div class="col">
        <p><translate>Fetching Link Information failed, please try again</translate></p>
        <Button class="p-button-raised" :label="i18n.$gettext('Try again')" @click="loadData" />
      </div>
    </div>
    <div v-else>
      <div class="col">
        <div class="row mb-2">
          <div class="col">
            <translate>
              Create a sharing link. This link can be accessed without an account. If you have sensible Data to share, protect the link with a password and expiration date.
            </translate>
          </div>
        </div>
        <div class="row">
          <div class="col-auto pb-2 pt-2">
            <InputSwitch v-model="createWithExpiryDate" />
          </div>
          <div class="col pb-2 pt-2 pl-0">
            <translate>
              Protect this link with an expiry date
            </translate>
          </div>
        </div>
        <div v-if="createWithExpiryDate" class="row">
          <div class="col-auto pb-2 pt-2">
            <InputNumber
              id="expiry"
              v-model="expiryDays"
              :prefix="i18n.$gettext('Expires in') + ' '"
              :suffix=" ' ' + i18n.$gettext('days')"
            />
          </div>
          <div class="col pb-3 pt-3">
            <translate>
              Please select the expiry date in days from today
            </translate>
          </div>
        </div>
        <div class="row pt-2">
          <div class="col-auto pb-2 pt-2">
            <InputSwitch v-model="createWithPassword" />
          </div>
          <div class="col pb-2 pt-2 pl-0">
            <translate>
              Protect this link with a password
            </translate>
          </div>
        </div>
        <div v-if="createWithPassword" class="row">
          <div class="col-auto pb-2 pt-2">
            <Password v-model="password" toggle-mask :feedback="false" />
          </div>
          <div class="col pb-3 pt-3">
            <translate>
              Protect this link with a strong password: The password should be at least 8 characters long.
            </translate>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <Button
              class="p-button-raised  mt-2"
              :loading="actionIsLoading"
              :label="i18n.$gettext('Create Link')"
              @click="createLink"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import INode from "@/model/entry/INode"
import {Options, Vue} from "vue-class-component"
import {fileServiceApi} from "@/api/FileServiceApi"
import Dialog from "primevue/dialog"
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import dayjs from 'dayjs'
import LoadingButton from "@/components/common/LoadingButton.vue"
import InputSwitch from "primevue/inputswitch"
import InputNumber from "primevue/inputnumber"
import Password from "primevue/password"
import ProgressBar from "primevue/progressbar"
import UploadLink from "@/model/common/UploadLink"
import RpcError from "@/api/RpcError"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import Button from "primevue/button"
import useToast from "@/util/toasts"

@Options({
  components: {
    Dialog, TabView, TabPanel, LoadingButton, InputSwitch, InputNumber, Password, ProgressBar, Button
  },
  //@ts-ignore
  props: {
    inode: INode,
  },
  emits: ["shouldClose"]
})
export default class DownloadLinkForm extends Vue {

  api = fileServiceApi

  inode!: INode

  links: UploadLink[] = []
  loaded = false
  loading = false
  toast = useToast()
  createWithPassword = false
  createWithExpiryDate = false
  expiryDays = 14 //default
  password = ""
  fetchFailed = false
  i18n: Language = useGettext()

  actionIsLoading = false;

  mounted(): void {
    if(this.loaded && !this.fetchFailed) return
    this.loadData().catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext('Could not get sharing information'))
    })
  }

  loadData(): Promise<void> {
    if(this.inode.path === null) return Promise.reject()
    this.loading = true
    return this.api._listUploadLinks(this.inode.path).then((links: UploadLink[]) => {
      this.links = links
      this.loading = false
      this.loaded = true
    }).catch((error: RpcError) => {
      this.toast.error(error.message)
      this.loading = false
      this.fetchFailed = true
    })
  }

  get hasLink(): boolean {
    if(!this.inode) return false
    return this.links.length > 0
  }

  getLink(): UploadLink | null {
    return (this.links.length > 0 ) ? this.links[0] : null
  }

  get href(): string {
    if(this.getLink() === null) return ""
    return this.links[0].link || ""
  }

  get humanreadableDate(): string {
    if(this.getLink()?.expireDate === null || this.getLink()?.expireDate === "") return this.i18n.$gettext("This link has no expiration date")
    const date: string = this.getLink()?.expireDate || ""
    return this.i18n.$gettext("This link expires on") + dayjs(date).format("HH:mm, DD.MM.YYYY")
  }

  deleteLink(): Promise<void> {
    const token: string = this.getLink()?.token || ""
    if(token === "") return Promise.reject()
    this.actionIsLoading = true
    return this.api._deleteUploadLink(token).catch((e: RpcError) => {
      this.toast.info(e.message, this.i18n.$gettext("Upload Link could not be deleted"))
    }).then(() => {
      this.toast.info(this.i18n.$gettext("Upload Link deleted"))
      void this.loadData()
    }).finally(() => { this.actionIsLoading = false })
  }

  createLink(): Promise<void> {
    if(!this.inode.path) return Promise.reject()
    let expireDays: string | null = null
    if(this.createWithExpiryDate) {
      if(this.expiryDays < 1) {
        this.toast.error(this.i18n.$gettext("You need to specify at least one day"))
        return Promise.reject()
      }
      expireDays = dayjs().add(this.expiryDays, 'day').toISOString()
    }
    const password: string | null =  (this.createWithPassword ? this.password : null)
    this.actionIsLoading = true
    return this.api._createUploadLink(this.inode.path, password, expireDays).catch((e: RpcError) => {
      this.toast.info(e.message, this.i18n.$gettext("Upload Link could not be created"))
    }).then(() => {
      this.toast.info(this.i18n.$gettext("Upload Link created"))
      void this.loadData()
    }).finally(() => { this.actionIsLoading = false })
  }

}
</script>

<style scoped lang="scss">

</style>
