

import {Options, Vue} from "vue-class-component"
import InfiniteList from "@/components/common/InfiniteList.vue"
import SWR from "@/api/SWR"
import Message from "@/model/entry/Message"
import {messageServiceApi} from "@/api/MessageServiceApi"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import ProgressBar from "primevue/progressbar"
import {messageStore} from "@/store/MessageStore"
import ChatMessage from "@/components/chat/subcomponents/ChatMessage.vue"
import {channelServiceApi} from "@/api/ChannelServiceApi"
import {userServiceApi} from "@/api/UserServiceApi"
import Channel from "@/model/directory/Channel"
import {ref} from "@vue/reactivity"
import AnimatedInput from "@/components/common/AnimatedInput.vue"
import RpcError from "@/api/RpcError"
import LoadingButton from "@/components/common/LoadingButton.vue"
import {useConfirm} from "primevue/useconfirm"
import User from "@/model/User"
import Dialog from "primevue/dialog"
import Button from "primevue/button"
import Textarea from "primevue/textarea"
import InputText from "primevue/inputtext"
import Listbox from "primevue/listbox"
import {rpcClient} from "@/api/WebsocketClient"
import AutoComplete from "primevue/autocomplete"
import TokenAttachmentList from "@/components/common/TokenAttachmentList.vue"
import useToast from "@/util/toasts"
import ContextMenu from "primevue/contextmenu"
import ChatMessageComposer from "@/components/common/chatMessageComposer/ChatMessageComposer.vue"
import ChatUtil from "@/util/ChatUtil"
import Skeleton from "primevue/skeleton"
import ForwardModal from "@/components/chat/subcomponents/ForwardModal.vue"
import Avatar from "@/components/common/Avatar.vue"
import FocusListener from "@/util/focusUtil"
import Menu from "primevue/menu"
import Page from "@/model/Page"
import Query from "@/model/common/Query"

const PAGESIZE = 100

@Options({
  //@ts-ignore
  components: {
    AnimatedInput, ChatMessage, InfiniteList, LoadingButton, ChatChannel, Dialog, Button, Textarea,
    InputText, Listbox, AutoComplete, ProgressBar, TokenAttachmentList, ContextMenu, ChatMessageComposer, Skeleton, Avatar,
    ForwardModal, Menu
  },
  //@ts-ignore
  props: {
    channelId: {
      type: String,
      default: ""
    },
    projectId: {
      type: String,
      default: ""
    },
    threadRoot: {
      type: String,
      default: null
    },
    searchQuery: {
      type: [ Query, Object ],
      default: null
    }
  },
  emits: [
    'editChannelMembers', 'editChannelDisplay'
  ]
})
export default class ChatChannel extends Vue {

  isDropzone = false

  channelId!: string
  projectId!: string
  threadRoot!: string | null
  searchQuery!: Query | null
  pagesize: number = PAGESIZE
  messageApi = messageServiceApi
  channelApi = channelServiceApi
  userApi = userServiceApi
  messagestore = messageStore
  i18n: Language = useGettext()
  rpcClient = rpcClient
  toast = useToast()
  confirm = useConfirm()
  message = ""

  rootMessageId: string | null = null
  showThreadDialog = false

  messageToEdit: Message | null = null
  editIsLoading: boolean = false
  messageToForward: Message | null = null

  typingTimeouts: Map<string, number> = new Map<string, number>()
  lastTypingEventSent = 0;

  channelViewed = false

  //@ts-ignore
  attachmentcontrol: TokenAttachmentList = ref<TokenAttachmentList | null>(null)
  //@ts-ignore
  attachMenu: Menu = ref(null)
  attachments: {name: string, size: string, handle: string, loading: boolean, progress: number}[] = []

  attachMenuItems = [
    {
      label: this.i18n.$gettext('Upload from your computer'),
      icon: 'cil-data-transfer-up',
      command: () => {
        this.attachmentcontrol.openNativeFileChooser()
      }
    },
    {
      label: this.i18n.$gettext('Choose from files'),
      icon: 'cil-inbox-out',
      command: () => {
        this.attachmentcontrol.openInodeChooser()
      }
    },
  ]

  handleFileDrop(e: DragEvent): void {
    this.isDropzone = false

    const items: DataTransferItemList | undefined = e.dataTransfer?.items
    if (!items) {
      return
    }
    let files: File[] = []
    for( let i = 0; i < items.length; i++) {
      const item: DataTransferItem = items[i]
      const webkitItem = item.webkitGetAsEntry()
      const file = item.getAsFile()
      if (webkitItem && webkitItem.isFile && file) {
        files.push(file)
      } else {
        this.toast.error(this.i18n.$gettext('Only files can be dropped here'))
        return
      }
    }
    this.attachmentcontrol.addAttachments(files)
  }

  //@ts-ignore
  messagecomposer: ChatMessageComposer = ref(null)
  //@ts-ignore
  forwardModal: ForwardModal = ref(null)

  focusListener: FocusListener = new FocusListener(() => {
    if (this.channelViewed) {
      this.fetchNewMessages()
      this.markChannelAsViewed()
    }
  })

  fetchNewMessages() {
    if (this.allItems.length > 0 && this.allItems[0].id) {
      this.messageApi.getMessagesInChannelAfter(this.channelId, this.allItems[0].id, 10000)
    } else {
      this.messageApi.getMessagesInChannel(this.channelId, null, null, "created:desc", 10000)
    }
  }

  get channel(): Channel | null {
    return this.channelApi.getChannel(this.channelId, false).data
  }

  //This is different from Uniki Chat where Direct and Group chats don't have a display name and always display the members
  get displayName(): string {
    return this.channel?.displayName || ChatUtil.getChannelNameForDirectConversation(this.channel) || ''
  }

  get channelNameForDirectConversation(): string {
    return (this.channel && ChatUtil.getChannelNameForDirectConversation(this.channel)) || ''
  }

  get canEditMembers(): boolean {
    return Boolean(this.channel && ChatUtil.canEditMembers(this.channel))
  }

  get canEditProperties(): boolean {
    return Boolean(this.channel && ChatUtil.canEditChannelProperties(this.channel))
  }

  get canAddReaction(): boolean {
    return Boolean(this.channel?.permissions?.includes('add_reaction'))
  }

  get canRemoveReaction(): boolean {
    return Boolean(this.channel?.permissions?.includes('remove_reaction'))
  }

  get canUploadFile(): boolean {
    return Boolean(this.channel?.permissions?.includes('upload_file'))
  }

  get canStartMeeting(): boolean {
    return Boolean(this.channel?.permissions?.includes('use_slash_commands'))
  }

  get canUseMentions(): boolean {
    return Boolean(this.channel?.permissions?.includes('use_channel_mentions'))
  }

  get canDeletePosts(): boolean {
    return Boolean(this.channel?.permissions?.includes('delete_post'))
  }

  get canEditPosts(): boolean {
    return Boolean(this.channel?.permissions?.includes('edit_post'))
  }

  get canCreatePosts(): boolean {
    return Boolean(this.channel?.permissions?.includes('create_post'))
  }

  get rootMessage(): Message | null {
    return this.threadRoot ? this.messageApi.getMessage(this.threadRoot).data : null
  }

  get allItems(): Message[] {
    if (this.threadRoot) {
      const root: Message | null = this.rootMessage
      const messages: Message[] = (this.messageApi.getMessageThread(this.threadRoot).data || []).reverse()
      if (root) {
        messages.push(root)
      }
      return messages
    } else if (this.searchQuery) {
      return []
    } else {
      const messages: Message[] = this.messageApi.getMessagesInChannel(this.channelId, null, null, "created:desc",  -1).data || []
      if (messages.length > 0 && !this.channelViewed) {
        this.handleInitialLoadDone()
      }
      return messages
    }
  }

  get itemPage(): undefined | ((pageIndex: number, pageSize: number) => SWR<Message[], Page<string>>) {
    if (this.threadRoot) {
      return undefined
    } else if (this.searchQuery) {
      const searchQuery: Query = this.searchQuery
      return (pageIndex: number, pageSize: number) => {
        return this.messageApi.queryMessages(searchQuery, pageIndex, pageSize,"ctime:desc")
      }
    } else {
      return (pageIndex: number, pageSize: number) => {
        return this.messageApi.getMessagesInChannel(this.channelId,  pageIndex, pageSize,"created:desc", false)
      }
    }
  }

  get users(): User[] {
    return this.userApi.getUsers(60000).data || []
  }

  get typingUsers(): User[] {
    const threshold: number = new Date().getTime() - 10000
    const typingUsers: User[] = this.users.filter(u => {
      return u.userName !== rpcClient.session.user?.userName && (u.lastTypingActivity || 0) > threshold && u.lastTypingChannel === this.channelId
    })
    for (const user of typingUsers) {
      if (user.userName) {
        const timeout = this.typingTimeouts.get(user.userName)
        if (timeout) {
          clearTimeout(timeout)
        }
        this.typingTimeouts.set(user.userName, window.setTimeout(() => {
          user.lastTypingActivity = null
        }, 10000))
      }
    }
    return typingUsers
  }

  get newMessageIsLoading(): boolean {
    return messageStore.getTempMessagesInChannel(this.channelId).size > 0
  }

  sendMessage(): void {
    try {
      this.message = this.messagecomposer.getMarkdownMessage()
    } catch (e) {
      this.message = ""
      return
    }

    let hasIncompleteUploads: boolean = this.attachmentcontrol.checkForIncompleteUploads()
    if (hasIncompleteUploads) {
      this.toast.error(this.i18n.$gettext("You cannot send the message while attachments are still uploading"))
      return
    }

    const fileTokens: string[] = this.attachmentcontrol.getFileTokens()

    if (this.message.trim() !== '' || fileTokens.length > 0 ) {

      let msg: Message = new Message()
      msg.text = this.message
      msg.channelId = this.channelId
      msg.rootId = this.threadRoot
      msg.fileTokens = fileTokens

      //TODO: Recode emoji to :smile:,etc ?
      const savedMessage = this.messagecomposer.getHTML()
      this.messagecomposer.clearMessage()

      let tempMessage: Message | null = null
      if (rpcClient.session.user?.cn) {
        tempMessage = Object.assign(new Message(), msg)
        tempMessage.id = Math.random().toString(36).substr(2)
        tempMessage.created = new Date().toISOString()
        tempMessage.userId = rpcClient.session.user.cn
        tempMessage.type = "DEFAULT"
        if (this.allItems.length) {
          const prev: Message = this.allItems[0]
          tempMessage.isFollowup = prev.userId === tempMessage.userId
        }
        messageStore.addTempMessage(tempMessage)
      }

      this.messageApi._createMessage(msg, null).then(() => {
        this.attachments = []
      }).catch((err: RpcError) => {
        this.messagecomposer.insertContent(savedMessage)
        this.toast.error(err.message, this.i18n.$gettext("Could not post message"))
      }).finally(() => {
        if (tempMessage) messageStore.removeTempMessage(tempMessage)
      })
    }
  }

  addEmojiToMessage(emoji: string) {
    this.messagecomposer.addEmojiToEditor(" " + emoji + " ")
  }

  openMessageThread(rootMessageId: string) {
    this.rootMessageId = rootMessageId
    this.showThreadDialog = true
  }

  handleInitialLoadDone() {
    if (this.channelId && !this.channelViewed) {
      this.channelViewed = true
      this.markChannelAsViewed()
    }
  }

  handleScrollToBottom() {
    if (this.channel?.unreadMsgCount && this.channelViewed) {
      this.markChannelAsViewed()
    }
  }

  markChannelAsViewed() {
    void this.channelApi._viewChannel(this.channelId, '')
  }

  handleNewMessageInput(e: KeyboardEvent) {
    const seconds = 2.5
    const now = new Date().getTime()
    if (now - this.lastTypingEventSent > seconds * 1000) {
      if (e.key !== "Enter") {
        void this.channelApi._emitTypingEvent(this.channelId)
        this.lastTypingEventSent = now
      }
    }
  }

  startMeeting(): Promise<any> {
    return messageServiceApi._executeCommand(this.projectId, this.channelId, '/jitsi').catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext('Could not start video call'))
    })
  }

  get showEditModal(): boolean {
    return !!this.messageToEdit
  }

  set showEditModal(value: boolean) {
    if (!value) this.messageToEdit = null
  }

  openEditModal(message: Message) {
    this.messageToEdit = message || null
  }

  showForwardModal(message: Message) {
    this.messageToForward = message
    void this.$nextTick(() => {
      this.forwardModal.toggle()
    })
  }

  editMessage() {
    if (this.messageToEdit?.id) {
      this.editIsLoading  = true
      messageServiceApi._patchMessage(this.messageToEdit.id, null, this.messageToEdit.text).then(() => {
        this.toast.success(this.i18n.$gettext("Message edited"))
        this.showEditModal = false
      }).catch((e: RpcError) => {
        this.toast.error(e.message, this.i18n.$gettext("Message could not be edited"))
      }).finally(() => {
        this.editIsLoading = false
      })
    }
  }

  mounted() {
    this.focusListener.add()
    if (this.channelViewed) {
      this.fetchNewMessages()
    }
  }

  unmounted() {
    this.focusListener.remove()
  }

}
