<template>
  <div>
    <input
      ref="attachmentinput"
      type="file"
      multiple
      style="display: none"
      @change="loadFileFromFilesystem"
    >
    <div v-if="hasAttachments" :class="listStyle" class="d-flex flex-wrap">
      <div v-for="(attachment, index) in modelValue" :key="JSON.stringify(attachment)" :class="{ 'attachment-item': mimicBigAttachments}" class="badge-border-dark p-1 mr-2 mb-2 flex-shrink-0 flex-grow-0 d-flex">
        <div v-if="mimicBigAttachments" class="flex-grow-1 w-100 d-flex align-items-center justify-content-center">
          <i class="fa fa-paperclip fa-4x text-secondary"></i>
        </div>
        <div :class="{ 'attachment-item-label': mimicBigAttachments}">
          <Button class="p-button-rounded p-button-danger p-button-sm" icon="fa fa-trash" @click="removeAttachment(index)" />{{ getShortName(attachment.name) }}: <span class="small">{{ attachment.size }}</span>
          <ProgressBar v-if="attachment.loading" :value="attachment.progress" :show-value="false" />
        </div>
      </div>
    </div>

    <Dialog
      v-model:visible="showAttachmentChooser"
      :modal="true"
      :breakpoints="{'960px': '75vw', '640px': '100vw'}"
      style="height: 90vh; min-width: 60vw"
      @hide="fileChooserPath = '/'"
    >
      <INodeList
        :project-id="currentProjectId"
        :current-path="fileChooserPath"
        class="flex-grow-1 h-100"
        is-embedded
        @inode-chosen="handleInodeChoose"
        @upper-chosen="goToUpperDir"
      />
    </Dialog>
  </div>
</template>

<script lang="ts">

import {Options, Vue} from "vue-class-component"
import fileSizeString from "@/util/fileSize"
import {reactive, ref} from "@vue/reactivity"
import {rpcClient} from "@/api/WebsocketClient"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import Button from "primevue/button"
import ProgressBar from "primevue/progressbar"
import useToast from "@/util/toasts"
import Dialog from "primevue/dialog"
import {projectServiceApi} from "@/api/ProjectServiceApi"
import INode from "@/model/entry/INode"
import INodeList from '../filemanger/INodeList.vue'


@Options({
  components: {
    Button, INodeList, ProgressBar, Dialog
  },
  //@ts-ignore
  props: {
    modelValue: Array,
    listStyle: String,
    mimicBigAttachments: Boolean
  },
  emits: ['update:modelValue'],
  name: "TokenAttachmentList"
})
export default class TokenAttachmentList extends Vue {
  i18n: Language = useGettext()
  toast = useToast()

  //@ts-ignore
  attachmentinput: HTMLInputElement = ref<HTMLInputElement | null>(null)
  modelValue!: {name: string, size: string, handle: string, loading: boolean, progress: number}[]

  fileChooserPath: string  = "/"

  showAttachmentChooser: boolean = false

  get projects() {
    return projectServiceApi.getProjects().data || []
  }

  get currentProjectId(): string | null {
    if (this.projects && this.projects.length > 0) {
      return this.projects[0].id
    } else {
      return null
    }
  }

  private handleInodeChoose(inode: INode) {
    if (!inode) {
      this.fileChooserPath = '/'
    } else if (inode.isDirectory) {
      let path: string = inode.path || "/"
      this.fileChooserPath = path.endsWith("/") ? path : (path + "/")
    } else if (inode.directLink) {
      this.createAttachmendFromInode(inode)
    }
  }

  private goToUpperDir(): void {
    if (this.fileChooserPath !== "/") {
      const pathElements: string[] = (this.fileChooserPath?.split("/")).filter(elem => elem !== "")
      pathElements.pop()
      const topLevelPath: string = "/" +  pathElements.join("/")
      this.fileChooserPath = topLevelPath.endsWith("/") ? topLevelPath : (topLevelPath + "/")
    }
  }

  public openInodeChooser(): void {
    this.showAttachmentChooser = true
  }

  public openNativeFileChooser(): void{
    this.attachmentinput.click()
  }

  private loadFileFromFilesystem(event: any) {
    // Reference to the DOM input element
    const {files} = event.target
    // Ensure that you have a file before attempting to read it
    if (files) {
      this.addAttachments(files)
    }
  }

  public createAttachmendFromInode(inode: INode) {
    this.showAttachmentChooser = false

    if (!inode || !inode.path) {
      this.toast.error(this.i18n.$gettext("Could not load Attachment from files"))
      return
    }

    const newThing = reactive({name: inode.name || inode.path.split('/').reverse()[0], size: fileSizeString(inode.size || 0), handle: "", loading: true, progress: 0})

    let data = new FormData()
    data.append("inodePath", inode.path)

    const client = rpcClient.getAjaxClient()
    client.post('uploads/frominode', data).then((res) => {
      newThing.progress = 100
      newThing.loading = false
      newThing.handle = res.data.token
    }).catch(() => {
      this.removeAttachment(this.modelValue.findIndex(value => value == newThing))
      this.toast.error(this.i18n.$gettext("Attachment could not be uploaded"))
    })

    let newVal = [ ...this.modelValue ]
    newVal.push(newThing)
    this.$emit('update:modelValue', newVal)
    return false
  }

  public addAttachments(files: File[]) {
    let newVal = [ ...this.modelValue ]

    for (const f of files) {
      const newThing = reactive({name: f.name, size: fileSizeString(f.size), handle: "", loading: true, progress: 0})

      const options = {
        onUploadProgress: (progressEvent: ProgressEvent) => {
          newThing.progress = Math.round((progressEvent.loaded / progressEvent.total) * 100)
        }
      }

      let data = new FormData()
      data.append("file", f)

      const client = rpcClient.getAjaxClient()
      client.post('uploads', data, options).then((res) => {
        newThing.progress = 100
        newThing.loading = false
        newThing.handle = res.data.token
      }).catch(() => {
        this.removeAttachment(this.modelValue.findIndex(value => value == newThing))
        this.toast.error(this.i18n.$gettext("Attachment could not be uploaded"))
      })

      newVal.push(newThing)
    }

    this.$emit('update:modelValue', newVal)
  }

  removeAttachment(idx: number) {
    let newVal = [ ...this.modelValue ]
    newVal.splice(idx, 1)
    //TODO: Revoke file on backend
    this.$emit('update:modelValue', newVal)
  }

  getShortName(name: string): string {
    return name.length > 10 ? name.substring(0,10) + "..." : name
  }

  get hasAttachments() {
    return this.modelValue.length > 0
  }

  checkForIncompleteUploads(): boolean {
    let hasIncompleteUploads = false
    this.modelValue.forEach((attachment: {name: string, size: string, handle: string, loading: boolean, progress: number}) => {
      if (attachment.handle === "") {
        hasIncompleteUploads = true
      }
    })
    return hasIncompleteUploads
  }

  getFileTokens(): string[] {
    let result: string[] = []
    this.modelValue.forEach((attachment: {name: string, size: string, handle: string, loading: boolean, progress: number}) => {
      if (!attachment.loading && attachment.handle !== "") {
        result.push(attachment.handle)
      }
    })
    return result
  }
}
</script>

<style lang="scss" scoped>

</style>
