<template>
  <Dialog
    v-model:visible="showSharingDialog"
    :header="title"
    style="height: 20rem; min-height: 50vh"
    @hide="hide()"
  >
    <UserSharingInput
      v-model="sharedTo"
      :only-allow-suggestions="onlyAllowSuggestions"
    >
      <template #header>
        <p>
          <translate>Select the users you want to share this folder with</translate>:
        </p>
      </template>
    </UserSharingInput>

    <template #footer>
      <Button class="p-button-secondary p-button-text" @click="hide">
        <translate translate-context="Abbrechen">
          Cancel
        </translate>
      </Button>
      <LoadingButton variant="success" :action="saveSharees">
        <translate>Save</translate>
      </LoadingButton>
    </template>
  </Dialog>
</template>

<script lang="ts">
import {Options, Vue} from "vue-class-component"
import Dialog from "primevue/dialog"
import Button from "primevue/button"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {calendarServiceApi} from "@/api/CalendarServiceApi"
import {Watch} from "vue-property-decorator"
import Calendar from "@/model/directory/Calendar"
import TaskBoard from "@/model/directory/TaskBoard"
import AddressBook from "@/model/directory/AddressBook"
import UserSharingInput from "@/components/common/UserSharingInput.vue"
import RpcError from "@/api/RpcError"
import LoadingButton from "@/components/common/LoadingButton.vue"
import useToast from "@/util/toasts"
import {addressBookServiceApi} from "@/api/AddressBookServiceApi"
import {taskBoardServiceApi} from "@/api/TaskBoardServiceApi"

@Options({
  //@ts-ignore
  props: {
    title: {
      type: String,
      default: ''
    },
    asCardDav: {
      type: Boolean,
      default: false
    },
    isTaskBoard: {
      type: Boolean,
      default: false
    },
    collection: [ Calendar, TaskBoard, AddressBook, Object ],
    onlyAllowSuggestions: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Dialog, Button, UserSharingInput, LoadingButton
  },
  emits: [ 'hide' ]
})
export default class DavSharingDialog extends Vue {

  i18n: Language = useGettext()
  toast = useToast()

  collection!: Calendar | TaskBoard | AddressBook

  title: string = this.i18n.$gettext('Share resource')
  asCardDav: boolean = false
  isTaskBoard: boolean = false
  showSharingDialog = false
  sharedTo: any[] = []

  saveSharees() {
    if (this.asCardDav) {
      void this.saveResourceShares()
    } else {
      if (this.isTaskBoard) {
        void this.saveTaskBoardShares()
      } else {
        void this.saveCalendarShares()
      }
    }
  }

  saveCalendarShares() {
    if (this.collection.originalId) {
      return calendarServiceApi._setCalendarShares(this.collection.originalId, this.sharedTo).then(() => {
        this.toast.success(this.i18n.$gettext("Calendar permissions changed"))
        this.hide()
      }).catch((err: RpcError) => {
        this.toast.error(this.i18n.$gettext("Could not change calendar permissions"), err.message)
      })
    } else {
      return Promise.resolve() //TODO: Display error?
    }
  }

  saveTaskBoardShares() {
    if (this.collection.originalId) {
      return taskBoardServiceApi._setTaskBoardShares(this.collection.originalId, this.sharedTo).then(() => {
        this.toast.success(this.i18n.$gettext("Taskboard permissions changed"))
        this.hide()
      }).catch((err: RpcError) => {
        this.toast.error(this.i18n.$gettext("Could not change taskboard permissions"), err.message)
      })
    } else {
      return Promise.resolve() //TODO: Display error?
    }
  }

  saveResourceShares() {
    if (this.collection.originalId) {
      return addressBookServiceApi._setAddressbookShares(this.collection.originalId, this.sharedTo).then(() => {
        this.toast.success(this.i18n.$gettext("Addressbook permissions changed"))
        this.hide()
      }).catch((err: RpcError) => {
        this.toast.error(this.i18n.$gettext("Could not change addressbook permissions"), err.message)
      })
    } else {
      return Promise.resolve() //TODO: Display error?
    }
  }


  hide() {
    this.showSharingDialog = false
    this.$emit('hide')
  }

  @Watch('collection')
  watchCollection(collection: Calendar | TaskBoard | AddressBook) {
    this.showSharingDialog = !!collection
    if (collection) {
      if (!collection.sharedTo) {
        collection.sharedTo = []
      }
      this.sharedTo = collection.sharedTo.filter(sharee => sharee.accessRight !== 'FORBIDDEN')
    } else {
      this.sharedTo = []
    }
  }

  mounted() {
    this.watchCollection(this.collection)
  }
}
</script>

<style scoped lang="scss">

</style>
