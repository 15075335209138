

import {Options, Vue} from 'vue-class-component'
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import useToast from "@/util/toasts"
import Button from "primevue/button"
import VirtualMailboxesPasswordItem from "@/components/settings/virtualmailboxes/VirtualMailboxesPasswordItem.vue"
import RpcError from "@/api/RpcError"
import Dialog from "primevue/dialog"
import UserSharingInput from "@/components/common/UserSharingInput.vue"
import LoadingButton from "@/components/common/LoadingButton.vue"
import Permission from "@/model/entry/Permission"
import {virtualMailboxServiceApi} from "@/api/VirtualMailboxServiceApi"
import {Watch} from "vue-property-decorator"
import User from "@/model/User"
import SWR from "@/api/SWR"
import {userServiceApi} from "@/api/UserServiceApi"
import {rpcClient} from "@/api/WebsocketClient"

@Options({
  //@ts-ignore
  props: {
    address: {type: String, default: ''},
    permissions: {type: Array, default: []}
  },
  components: {
    VirtualMailboxesPasswordItem, Button, Dialog, UserSharingInput, LoadingButton
  },
  emits: [ 'hide' ]
})
export default class VirtualMailboxesUserManagerDialog extends Vue {

  address!: string

  i18n: Language = useGettext()
  toast = useToast()

  permissions: Permission[] = []
  sharedTo: any[] = []
  permissionOptions = [
    {
      id: "READ",
      name: this.i18n.$gettext("Read only")
    },
    {
      id: "WRITE",
      name: this.i18n.$gettext("Read-Write")
    }
  ]

  hide() {
    this.$emit('hide')
  }

  get users(): User[] {
    const swr: SWR<User[], string[]> = userServiceApi.getUsers()
    if (swr.call?.promise && (swr.call?.loading || swr.call?.refreshing)) {
      this.loadingUsers = true
      swr.call.promise.finally(() => {
        this.loadingUsers = false
      })
    }
    return swr.data || rpcClient.session.user?.settings?.mailFilters || []
  }

  changeMailboxPermissions(): Promise<void> {
    const perms: Permission[] = []
    this.sharedTo.forEach( (it: any ) => {
      const p = new Permission()
      p.userName = it.userName
      p.mode = it.accessRight
      perms.push(p)
    })

    return virtualMailboxServiceApi._setPermissions(this.address, perms).then(() => {
      this.toast.success(this.i18n.$gettext("Mailbox permissions changed"))
      this.hide()
    }).catch((err: RpcError) => {
      this.toast.error(this.i18n.$gettext("Could not change mailbox permissions"), err.message)
    })
  }

  @Watch('permissions')
  onValueChanged(permissions: Permission[]) {
    //Copy over permissions:
    this.sharedTo = []
    permissions?.forEach( (perm: Permission) => {
      const user: User | undefined = this.users.find(u => u.cn === perm.userName)
      if (user) {
        this.sharedTo.push({
          sharee: 'mailto:' + perm.userName,
          userName: perm.userName,
          commonName: perm.userName,
          accessRight: perm.mode
        })
      }
    })
  }

  mounted() {
    this.users
    this.onValueChanged(this.permissions)
  }
}
