<template>
  <div class="d-flex flex-column flex-grow-1 h-100">
    <slot name="header">
      <p class="mt-4">
        <translate>Please select all groups you want to share this resource with</translate>
      </p>
    </slot>
    <div class="p-inputgroup">
      <span class="p-float-label">
        <Dropdown
          v-model="newSharee"
          small
          class="mb-0 mr-4 flex-fill"
          :options="seafileGroups"
          style="flex-basis: 20%; min-width: 200px; flex-grow: 0"
          @change="addSharee"
        />
        <label><slot name="placeholder"><translate>Add Group</translate></slot></label>
      </span>
    </div>
    <div class="flex-grow-1" style="overflow-y: scroll">
      <div v-for="sharee in sharedTo" :key="JSON.stringify(sharee)" class="d-flex flex-row align-items-center mt-2">
        <p class="flex-grow-1 mb-0" style="width: 1px">
          {{ sharee.commonName }}
        </p>
        <Dropdown
          v-model="sharee.accessRight"
          small
          class="mb-0 mr-4"
          :options="tAccessOptions"
          option-label="name"
          option-value="id"
          style="flex-basis: 20%; min-width: 200px; flex-grow: 0"
          @change="updateSharee(sharee)"
        />
        <Button
          v-tooltip="i18n.$gettext('Remove')"
          class="p-button-secondary"
          icon="cil-trash"
          @click="removeSharee(sharee)"
        />
      </div>
    </div>
    <slot name="footer" />
  </div>
</template>

<script lang="ts">
import {Options, Vue} from "vue-class-component"
import Button from "primevue/button"
import AutoComplete from "primevue/autocomplete"
import Dropdown from "primevue/dropdown"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {Watch} from "vue-property-decorator"
import {groupServiceApi} from "@/api/GroupServiceApi"
import Group from "@/model/directory/Group"

@Options({
  //@ts-ignore
  props: {
    modelValue: Array,
    accessOptions: {
      type: Array,
      default: null
    }
  },
  components: {
    Button, AutoComplete, Dropdown
  },
  emits: [  'added', 'removed','updated', 'update:modelValue' ]
})
export default class GroupSharingInput extends Vue {

  i18n: Language = useGettext()

  modelValue!: any[]
  accessOptions!: any[] | null

  sharedTo: any[] = []
  selectableGroups: string[] = []
  newSharee = ''

  get tAccessOptions(): any[] {
    return this.accessOptions ||  [
      {
        id: 'READ',
        name: this.i18n.$gettext('Read Only')
      },
      {
        id: 'WRITE',
        name: this.i18n.$gettext('Read & Write')
      }
    ]
  }

  get seafileGroups(): string[] {
    return this.groups?.filter((group: Group) => {
      return group.syncedWithSeafile && !this.sharedTo.find((sharee: any) => {return sharee.commonName == group.description})
    }).map((group: Group) => {
      return group.description || ""
    }).filter((str: string) => {
      return str !== ""
    }) || []
  }

  get groups(): Group[] {
    return groupServiceApi.getGroupsForCurrentUser().data || []
  }

  addSharee() {
    const name = this.newSharee
    if (name === "" || !!this.sharedTo.find(g => g.commonName === name)) {
      return
    } else {
      const sharee: any = {}
      const group: Group | undefined = this.groups.find(u => u.description === name)
      if (group) {
        sharee.sharee = group
        sharee.commonName = name
        if (this.tAccessOptions && this.tAccessOptions.length > 0) sharee.accessRight = this.tAccessOptions[0].id
        this.sharedTo.push(sharee)
        this.$emit('added', sharee)
        this.$emit('update:modelValue', this.sharedTo)
        this.newSharee = ""
      }
    }
  }

  updateSharee(sharee: any) {
    this.$emit('updated', sharee)
  }

  removeSharee(sharee: any) {
    const removed: number = this.sharedTo.indexOf(sharee)
    if (removed >= 0) {
      const removedSharee = this.sharedTo.splice(removed, 1)[0]
      this.$emit('removed', removedSharee)
      this.$emit('update:modelValue', this.sharedTo)
    }
  }

  @Watch('modelValue')
  onValueChanged(sharedTo: any[]) {
    this.sharedTo = sharedTo
  }

  mounted() {
    this.onValueChanged(this.modelValue)
  }
}
</script>

<style scoped lang="scss">

</style>
