import { rpcClient } from "@/api/WebsocketClient"
import SWR, { Call } from "@/api/SWR"
import { reactive } from "@vue/reactivity"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import Query from '@/model/common/Query';
import Page from '@/model/Page';
import { queryStore } from '@/store/QueryStore';

export default class GeneratedQueryServiceApi {

    cache: Map<string, Call<any>> = new Map<string, Call<any>>()

    constructor() {
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.apis.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    clearState() {
        this.cache = new Map<string, Call<any>>()
    }

    get connected(): boolean {
        return rpcClient.state.connected
    }

    _getSuggestionsForQuery(query: Query): Promise<string[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getSuggestionsForQuery', rpcParams, false) as Promise<string[]>
    }

    _startQuery(query: Query): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('startQuery', rpcParams, false).then((data: any) => {
            const model = Object.assign(new Query(), data)
            queryStore.addOrReplaceQuery(model)
            return model.id
        })
    }

    getQuerys(pageIndex?: number, pageSize?: number): Query[] {
        let querys: Query[] = [...queryStore.state.querys.values()]
        
        
        if (pageSize !== null && pageSize !== undefined) {
            querys = querys.slice((pageIndex || 0) * pageSize, ((pageIndex || 0) + 1) * pageSize)
        }
        return querys
    }

    getQuery(id: string): Query | undefined {
        return queryStore.state.querys.get(id)
    }
}
