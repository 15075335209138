<template>
  <div class="flex-shrink-1 result-list p-2" style="min-height: 0; overflow: auto;">
    <div v-if="isAdmin" class="home container-lg p-0 ml-0">
      <SettingsListItem v-for="setting in adminSettings" :key="setting.linkTo" :setting="setting" />
    </div>
  </div>
</template>

<script lang="ts">

import {Options, Vue} from "vue-class-component"
import SettingsListItem from "@/components/settings/SettingsListItem.vue"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {rpcClient} from '@/api/WebsocketClient'
import adminpanelHolder from "@/util/AdminpanelHolder"

@Options({
  components: {
    SettingsListItem
  },
  //@ts-ignore
  props: {},
  emits: []
})
export default class SystemSettingsList extends Vue {

  i18n: Language = useGettext()

  get adminSettings() : any[] {

    const settings: any[] = [
      {
        name: this.i18n.$gettext('Backup'),
        icon: 'fa fa-history',
        linkTo: '/settings/backup'
      },
      {
        name: this.i18n.$gettext('Failover'),
        icon: 'fa fa-bolt',
        linkTo: '/settings/failover'
      },
      {
        name: this.i18n.$gettext('Users'),
        icon: 'fa fa-user',
        linkTo: '/settings/users'
      },
      {
        name: this.i18n.$gettext('Groups'),
        icon: 'fa fa-users',
        linkTo: '/settings/groups'
      },
      {
        name: this.i18n.$gettext('LDAP / ActiveDirectory'),
        icon: 'fa fa-users-cog',
        linkTo: '/settings/authentication'
      },
      {
        name: this.i18n.$gettext('Hosting'),
        icon: 'fa fa-server',
        linkTo: '/settings/hosting'
      },
      {
        name: this.i18n.$gettext('Domains'),
        icon: 'fa fa-link',
        linkTo: '/settings/domains'
      },
      {
        name: this.i18n.$gettext('Synchronization'),
        icon: 'fa fa-sync',
        linkTo: '/settings/synchronization'
      },
      {
        name: this.i18n.$gettext('Data Import'),
        icon: 'fa fa-arrow-circle-down',
        linkTo: '/settings/dataimport'
      },
      {
        name: this.i18n.$gettext('Updates'),
        icon: 'fa fa-cloud-upload-alt',
        linkTo: '/settings/updates'
      },
      {
        name: this.i18n.$gettext('System Info'),
        icon: 'fa fa-info-circle',
        linkTo: '/settings/info'
      },
      {
        name: this.i18n.$gettext('System Notifications'),
        icon: 'fa fa-envelope-square',
        linkTo: '/settings/notifications'
      },
      {
        name: this.i18n.$gettext('Passwords'),
        icon: 'fa fa-key',
        linkTo: '/settings/passwords'
      },
      {
        name: this.i18n.$gettext('Firewall'),
        icon: 'fa fa-exchange-alt',
        linkTo: '/settings/firewall'
      },
      {
        name: this.i18n.$gettext('Email Aliases'),
        icon: 'fa fa-random',
        linkTo: '/settings/mailaliases'
      },
      {
        name: this.i18n.$gettext('Virtual Mailboxes'),
        icon: 'fa fa-at',
        linkTo: '/settings/virtual-mailboxes'
      },
      {
        name: this.i18n.$gettext('Mail Domains'),
        icon: 'fa fa-at',
        linkTo: '/settings/maildomains'
      },
      {
        name: this.i18n.$gettext('Global Signatures'),
        icon: 'fa fa-file-signature',
        linkTo: '/settings/global-signature'
      },
      {
        name: this.i18n.$gettext('PST Import'),
        icon: 'fa fa-file-upload',
        linkTo: '/settings/pstimport'
      },
      {
        name: this.i18n.$gettext('Wallpaper'),
        icon: 'fa fa-images',
        linkTo: '/settings/wallpapers'
      },
      {
        name: this.i18n.$gettext('Feedback'),
        icon: 'fa fa-comment-dots',
        linkTo: '/settings/feedback'
      },
      {
        name: this.i18n.$gettext('Maintenance'),
        icon: 'fa fa-wrench',
        linkTo: '/settings/maintenance'
      },
      {
        name: this.i18n.$gettext('Licenses'),
        icon: 'fa fa-gavel',
        linkTo: '/settings/licenses'
      },
      {
        name: this.i18n.$gettext('Reset'),
        icon: 'fa fa-skull',
        linkTo: '/settings/reset'
      }
    ]

    if (!this.areNetworkSharesDisabled) {
      settings.splice(7, 0, {
        name: this.i18n.$gettext('Network Shares'),
        icon: 'fa fa-network-wired',
        linkTo: '/settings/networkshares'
      })
    }

    if (this.isKopanoInstalledAndRunning) {
      settings.splice(17, 0, {
        name: this.i18n.$gettext('PST Import (Kopano)'),
        icon: 'fa fa-file-upload',
        linkTo: '/settings/pstupload'
      })
    }

    return settings
  }

  get isAdmin() {
    return rpcClient.session.user?.posixGroupName === 'admin'
  }

  get areNetworkSharesDisabled() {
    let disabledFeatures: string[] | null = rpcClient.status?.disabledFeatures
    if (disabledFeatures) {
      return disabledFeatures.indexOf("smbshares") > -1
    } else {
      return false
    }
  }

  get isKopanoInstalledAndRunning() {
    if (adminpanelHolder && adminpanelHolder.instance) {
      return adminpanelHolder.instance.store.getters.kopanoIsInstalled
    } else {
      return false //Hide feature in groupware by default
    }
  }

  mounted() {
    if (!this.isAdmin) {
      void this.$router.push('/')
    }
  }
}
</script>

<style scoped lang="scss">

@import "node_modules/elly-bs4/sass/variables";



</style>
