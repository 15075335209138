<template>
  <span class="p-float-label w-100">
    <Calendar
      ref="myinput"
      v-model="internalValue"
      class="w-100 h-100"
      :class="disabled && 'p-disabled'"
      :placeholder="placeholder"
      :show-time="showTime"
      :month-navigator="monthNavigator"
      :year-navigator="yearNavigator"
      :year-range="yearRange"
      :show-button-bar="showButtonBar"
      :manual-input="true"
      :select-other-months="true"
      :min-date="minDate"
      :max-date="maxDate"
      @show="handleFocus"
      @hide="handleBlur"
    />
    <label>{{ label }}</label>
  </span>
</template>

<script lang="ts">

import {Options, Vue} from "vue-class-component"
import {Watch} from "vue-property-decorator"
import { ref } from "@vue/reactivity"
import Calendar from "primevue/calendar"
import {pasteRegex} from "@tiptap/extension-highlight";

@Options({
  //@ts-ignore
  props: {
    label: String,
    placeholder: String,
    modelValue: [ Date, String ],
    inline: Boolean,
    autocomplete: String,
    error: String,
    type: String,
    disabled: Boolean,
    name: String,
    showTime: Boolean,
    small: Boolean,
    noBackground: Boolean,
    monthNavigator: Boolean,
    yearNavigator: Boolean,
    yearRange: String,
    showButtonBar: Boolean,
    minDate: Date,
    maxDate: Date,
  },
  components: {
    Calendar
  }
})
export default class DatePicker extends Vue {

  label!: string;
  placeholder!: string;
  modelValue!: Date;
  inline!: boolean;
  autocomplete!: string;
  error!: string;
  type!: string;
  disabled!: boolean;
  name!: string;
  small!: boolean;
  noBackground!: boolean;
  showTime!: boolean
  monthNavigator!: boolean
  yearNavigator!: boolean
  yearRange!: string
  showButtonBar!: boolean
  minDate!: Date
  maxDate!: Date

  focused = false;
  internalValue: Date | null = this.modelValue || null;

  //@ts-ignore
  myinput: Calendar = ref<Calendar | null>(null);

  get animationClass (): string {
    if (this.noBackground) {
      return 'bg-white' + this.sizeClass + this.inlineClass
    } else if (this.focused || this.internalValue) {
      return 'ai-filled' + this.errorClass + this.sizeClass + this.inlineClass
    } else {
      return '' + this.errorClass + this.sizeClass + this.inlineClass
    }
  }

  get inlineClass (): string {
    return (this.inline ? ' animated-input-inline' : '')
  }

  get sizeClass(): string {
    return (this.small ? ' animated-input-sm' : '')
  }

  get errorClass(): string {
    if (this.error) {
      if (this.focused) {
        return ' border-danger'
      } else {
        return ' border-danger danger'
      }
    } else {
      return ''
    }
  }

  @Watch('internalValue')
  handleInput (val: Date, oldVal: Date) {
    this.$emit('update:modelValue', this.internalValue)
  }

  checkPopupLocationAndMoveIfNecessary(){
    const padding: number = 10
    const popup: HTMLElement | null = document.querySelector('.p-datepicker[role="dialog"]')
    if(!popup) return

    const inputElem: HTMLElement  = this.$el
    const rectPopup: DOMRect = popup.getBoundingClientRect()
    const rectInput: DOMRect = inputElem.getBoundingClientRect()

    let isOver = rectPopup.bottom > rectInput.top && rectPopup.right > rectInput.left
      && rectPopup.top < rectInput.bottom && rectPopup.left < rectInput.right

    if(isOver){
      console.debug("Detected overlap, trying to move dialog to the left")

      //Check if we have enough space on the left:
      //TODO: What if there is only enough space on the right?
      const leftIsFine: boolean = rectInput.left > (rectInput.width + padding)
      if(leftIsFine) {
        popup.style.left =  rectInput.left - padding - rectPopup.width + "px"
      }
    }
    //Calculate: is the popup over the input?
  }

  handleFocus () {
    this.checkPopupLocationAndMoveIfNecessary()
    this.focused = true
    this.$emit('focus')
  }

  handleBlur () {
    this.focused = false
    this.$emit('blur')
  }

  @Watch('modelValue')
  onValueChanged (val: Date, oldVal: Date) {
    if (val) { //TODO: Parse if string
      this.internalValue = val
    } else {
      this.internalValue = null
    }
  }
}
</script>

<style scoped lang="scss">
</style>
