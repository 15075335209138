<template>
  <InnerLayoutWithSidebar
    v-if="hasContactsBooked"
    :header-label="i18n.$gettext('Addressbooks')"
    :default-open="openMobileMenuDefault"
    @created-clicked="openNewAddressbookModal"
  >
    <template #side-panel-content>
      <div v-if="hasAddressbooks">
        <div
          v-for="addressBook in addressbooks"
          :key="addressBook.originalId"
          class="side-panel-menu-item pl-3 pt-2 pb-2 d-flex align-items-center"
          :class="{'active': addressBookIsActive(addressBook)}"
          @click="openAddressBook(addressBook.originalId)"
          @contextmenu="showAddressBookContextMenu(addressBook, $event)"
        >
          <i v-if="!addressBookToRename || addressBookToRename.originalId !== addressBook.originalId" class="cil-group collection-icon" />
          <span v-if="!addressBookToRename || addressBookToRename.originalId !== addressBook.originalId" class="pl-1 flex-grow-1">{{ addressBook.name }}</span>
          <div v-if="!addressBookToRename || addressBookToRename.originalId !== addressBook.originalId" class="h-100 d-flex align-items-center">
            <i
              v-if="addressBook.isShared && addressBook.shareAccess !== 'OWNER'"
              v-tooltip="i18n.$gettext('This Addressbook is shared to you by ') + addressBook.ownerId + (addressBook.shareAccess === 'READ' ? ' ' + i18n.$gettext('(read only)') : '')"
              class="cil-share-alt"
            />
            <Button icon="cil-menu" class="p-button-text p-button-sm context-menu-btn" @click.stop.prevent="showAddressBookMenu(addressBook, $event)" />
          </div>
          <div v-else class="d-flex w-100 align-items-center">
            <InputText
              v-model="addressBookToRename.name"
              class="p-inputtext-sm w-100 flex-grow-1"
              @click.stop
              @keyup.enter="saveName"
            />
            <Button
              icon="cil-check-alt"
              :loading="renameLoading"
              class="p-button-text p-button-success p-button-sm"
              @click.stop="saveName"
            />
            <Button icon="cil-x" class="p-button-text p-button-danger p-button-sm" @click.stop="addressBookToRename = null" />
          </div>
        </div>
        <div v-if="addressBooksAreLoading">
          <div class="side-panel-menu-item pl-3 pr-3 pt-2 pb-4 d-flex">
            <Skeleton height="18px" class="flex-grow-1" />
          </div>
          <div class="side-panel-menu-item pl-3 pr-3 pt-2 pb-4 d-flex">
            <Skeleton height="18px" class="flex-grow-1" />
          </div>
          <div class="side-panel-menu-item pl-3 pr-3 pt-2 pb-4 d-flex">
            <Skeleton height="18px" class="flex-grow-1" />
          </div>
        </div>
      </div>
      <div v-else class="d-flex flex-column h-100">
        <div v-if="!addressBooksAreLoading" class="d-flex flex-column justify-content-center h-100">
          <div class="text-center">
            <p class="h5 mb-2">
              <translate>No Addressbooks</translate>
            </p>
            <p><translate>Click "New" to create one.</translate></p>
          </div>
        </div>
        <div v-else>
          <div class="side-panel-menu-item pl-3 pr-3 pt-2 pb-4 d-flex">
            <Skeleton height="18px" class="flex-grow-1" />
          </div>
          <div class="side-panel-menu-item pl-3 pr-3 pt-2 pb-4 d-flex">
            <Skeleton height="18px" class="flex-grow-1" />
          </div>
          <div class="side-panel-menu-item pl-3 pr-3 pt-2 pb-4 d-flex">
            <Skeleton height="18px" class="flex-grow-1" />
          </div>
        </div>
      </div>
    </template>
    <template #main-content>
      <teleport to="#topmenubar">
        <Button
          v-if="hasContactsBooked && isAllowedToCreate"
          :label="i18n.$gettext('New Contact')"
          icon="cil-plus"
          class="p-button p-button-inverse mr-3"
          @click="openNewContactModal"
        />
      </teleport>
      <div v-if="selectedAddressBook" class="flex-grow-1 h-100">
        <!-- Addressbook content -->
        <ContactsList :address-book="selectedAddressbookObject" :search-query="searchQuery" />
      </div>
      <div v-else class="flex-grow-1 h-100 d-flex flex-row justify-content-center align-items-center">
        <div class="">
          <p class="h5">
            <translate>Please select an addressbok</translate>
          </p>
          <p><translate>You can select an addressbook on the left side or create a new one in the top bar.</translate></p>
        </div>
      </div>
      <Dialog
        v-model:visible="showCreateBookModal"
        :header="i18n.$gettext('Create Adressbook')"
        :modal="true"
        :draggable="false"
      >
        <div>
          <p><translate>Please specify a name for the new Addressbook:</translate></p>
          <span class="p-float-label w-100 mb-4">
            <InputText
              v-model="newBook.name"
              type="text"
              class="w-100"
              autofocus
              @keyup.prevent.stop.enter="createAdressbook"
            />
            <label><translate>Name</translate></label>
          </span>
          <div class="d-flex justify-content-end mt-4">
            <Button
              icon="cil-x"
              class="mr-2 p-button-raised p-button-secondary"
              :label="i18n.$gettext('Abort')"
              @click="showCreateBookModal = false"
            />
            <Button
              icon="cil-plus"
              :label="i18n.$gettext('Create')"
              class="p-button-raised p-button-success"
              :loading="createBookLoading"
              @click="createAdressbook"
            />
          </div>
        </div>
      </Dialog>

      <Dialog
        v-model:visible="showCreateContactModal"
        :header="i18n.$gettext('Create Contact')"
        :modal="true"
        :draggable="false"
      >
        <div>
          <p><translate>Please specify a name for the new Contact:</translate></p>
          <span class="p-float-label inline mr-2">
            <InputText v-model="newContact.structuredName.given" type="text" autofocus />
            <label><translate>First Name</translate></label>
          </span>
          <span class="p-float-label inline ">
            <InputText v-model="newContact.structuredName.family" type="text" @keyup.prevent.stop.enter="createContact" />
            <label><translate>Last Name</translate></label>
          </span>
          <br>
          <div class="d-flex justify-content-end mt-4">
            <Button
              icon="cil-x"
              class="mr-2 p-button-raised p-button-secondary"
              :label="i18n.$gettext('Abort')"
              @click="showCreateContactModal = false"
            />
            <Button
              icon="cil-plus"
              :label="i18n.$gettext('Create')"
              class="p-button-raised p-button-success"
              :loading="createContactLoading"
              @click="createContact"
            />
          </div>
        </div>
      </Dialog>
      <DavSharingDialog
        :title="i18n.$gettext('Share Addressbook')"
        :as-card-dav="true"
        only-allow-suggestions
        :collection="addressBookToShare"
        @hide="addressBookToShare = null"
      />
      <ContextMenu ref="contextMenu" :key="JSON.stringify(menuItems)" :model="menuItems" />
      <Menu ref="menu" :model="menuItems" :popup="true" />
      <teleport to="#menubarcontent">
        <SearchBar
          class="pt-2"
          collection-type="CONTACT"
          :collection-id="selectedAddressbookObject ? selectedAddressBook : null"
          collection-icon="cil cil-group"
          :collection-name="selectedAddressbookName"
          @search="searchQuery = $event"
        />
      </teleport>
    </template>
  </InnerLayoutWithSidebar>
  <div v-else class="d-flex flex-row bg-light h-100" style="border-radius: 3px; overflow: hidden">
    <div class="d-flex flex-grow-1 flex-column justify-content-center h-100">
      <div class="text-center">
        <p class="h5 mb-2">
          <translate>Adressbooks are disabled</translate>
        </p>
        <p><translate>Please talk to your administrator to enable adressbooks for your account or purchase an upgrade to your subscription.</translate></p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import {Options, Vue} from "vue-class-component"
import {addressBookServiceApi} from "@/api/AddressBookServiceApi"
import AddressBook from "@/model/directory/AddressBook"
import AnimatedInput from "@/components/common/AnimatedInput.vue"
import ContactsList from "@/components/contacts/ContactsList.vue"
import { ref } from "@vue/reactivity"
import Menu from "primevue/menu"
import Dialog from "primevue/dialog"
import {useGettext} from "@jshmrtn/vue3-gettext"
import InputText from "primevue/inputtext"
import Button from "primevue/button"
import RpcError from "@/api/RpcError"
import Contact from "@/model/entry/Contact"
import StructuredName from "@/model/common/carddav/StructuredName"
import TextValue from "@/model/common/carddav/TextValue"
import {contactServiceApi} from "@/api/ContactServiceApi"
import ProgressBar from "primevue/progressbar"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import useToast from "@/util/toasts"
import Skeleton from "primevue/skeleton"
import SettingsUtil from "@/util/SettingsUtil"
import {useConfirm} from "primevue/useconfirm"
import ContextMenu from "primevue/contextmenu"
import DavSharingDialog from "@/components/common/DavSharingDialog.vue"
import featureSubset from "@/util/FeatureSubsets"
import breakpointUtil from "@/util/BreakpointUtil"
import InnerLayoutWithSidebar from "@/components/common/InnerLayoutWithSidebar.vue"
import SearchBar from "@/components/common/SearchBar.vue"
import Query from "@/model/common/Query"

@Options({
  components: {
    InnerLayoutWithSidebar, ContactsList, AnimatedInput, Menu, Dialog, InputText, Button, ProgressBar,
    Skeleton, DavSharingDialog, ContextMenu, SearchBar
  },
  //@ts-ignore
  props: {},
  emits: []
})
export default class ContactsView extends Vue {

  addressbookApi = addressBookServiceApi
  i18n = useGettext()
  toast = useToast()
  confirm = useConfirm()

  menuItems: any[] = []
  addressBooksAreLoading: boolean = true

  showCreateBookModal = false
  newBook: AddressBook = new AddressBook()
  createBookLoading = false

  addressBookToShare: AddressBook | null = null
  addressBookToRename: AddressBook | null = null
  renameLoading: boolean = false

  showCreateContactModal = false
  newContact: Contact = new Contact()
  createContactLoading = false

  //@ts-ignore
  contextMenu: ContextMenu = ref<ContextMenu | null>(null)
  //@ts-ignore
  menu: Menu = ref<Menu | null>(null)

  openMobileMenuDefault: boolean = false

  searchQuery: Query | null = null

  openNewAddressbookModal(): void {
    this.newBook = new AddressBook()
    this.showCreateBookModal = true
  }

  openNewContactModal(): void {
    this.setupNewContact()
    this.showCreateContactModal = true
  }

  get selectedAddressBook() {
    return (this.$route.params["book"] as string) || ""
  }

  get addressbooks(): AddressBook[] {
    let swr = this.addressbookApi.getAddressBooks()
    this.addressBooksAreLoading = Boolean(swr.call?.loading && swr.call?.promise)
    if (swr.call?.promise) {
      swr.call.promise.finally(() => { this.addressBooksAreLoading  = false })
    }
    const collections = swr.data ? [...swr.data] : []
    const orderedCollections: AddressBook[] = []
    const order: string[] | null | undefined = SettingsUtil.getLastViewedCollectionOrder('addressBook')
    if (order) {
      for (let id of order) {
        const collectionIndex: number = collections.findIndex(b => b.originalId === id || b.id === id)
        if (collectionIndex >= 0) {
          orderedCollections.push(collections[collectionIndex])
          collections.splice(collectionIndex, 1)
        }
      }
    }
    orderedCollections.push(...collections.sort((a, b) => SortAndFilterUtil.compare(a.name, b.name)))
    if (order) void SettingsUtil.setLastViewedCollectionOrder('addressBook', orderedCollections.map(b => b.originalId || ''))
    return orderedCollections
  }

  get hasAddressbooks(): boolean {
    return this.addressbooks.length > 0
  }

  get selectedAddressbookObject(): AddressBook | null {
    return this.addressbookApi.getAddressBook(this.selectedAddressBook) || null
  }

  get selectedAddressbookName(): string | null {
    return this.selectedAddressbookObject?.name || null
  }

  addressBookIsActive(addressBook: AddressBook): boolean {
    return this.selectedAddressBook === addressBook.originalId
  }

  openAddressBook(addressBookId: string): void {
    void this.$router.push({path: `/contacts/${addressBookId}`})
    void SettingsUtil.setLastViewedCollection('addressBook', addressBookId)
  }

  createAdressbook(): void {
    if (this.newBook.name == "") return
    this.createBookLoading = true
    void addressBookServiceApi._createAddressBook(this.newBook).then(() => {
      this.toast.success(this.i18n.$gettext("Addressbook created"))
      this.showCreateBookModal = false
    }).catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext("Could not create Addressbook"))
    }).finally(() => {
      this.createBookLoading = false
    })
  }

  setupNewContact() {
    this.newContact = new Contact()
    this.newContact.structuredName = new StructuredName()
    this.newContact.formattedName = new TextValue()
  }

  createContact(){
    this.createContactLoading = true
    const bookId: string = this.selectedAddressBook
    if (!bookId || !this.newContact.formattedName || !this.newContact.structuredName) return
    this.newContact.formattedName.text = (this.newContact.structuredName.given || '') + " " + (this.newContact.structuredName.family || '')
    this.newContact.vCardVersion = "4.0"
    this.newContact.originalParentId = bookId
    contactServiceApi._addContact(this.newContact).then((id: string) => {
      this.toast.success(this.i18n.$gettext("Contact created"))
      this.showCreateContactModal = false
      this.setupNewContact()
      void this.$router.push({path: `/contacts/${bookId}/${id}`})
    }).catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext("Contact could not be created"))
    }).finally(() => { this.createContactLoading = false})
  }

  showAddressBookContextMenu(addressBook: AddressBook, event: Event) {
    this.updateMenuItems(addressBook)
    this.menu.hide()
    void this.$nextTick(() => {
      this.contextMenu.toggle(event)
    })
  }

  showAddressBookMenu(addressBook: AddressBook, event: Event) {
    this.updateMenuItems(addressBook)
    this.contextMenu.hide()
    void this.$nextTick(() => {
      this.menu.toggle(event)
    })
  }

  updateMenuItems(addressBook: AddressBook) {
    this.menuItems = []
    if (['OWNER'].includes(addressBook.shareAccess || '')) {
      this.menuItems.push({
        label: this.i18n.$gettext('Share with...'),
        icon: 'cil-share',
        command: () => {
          this.addressBookToShare = addressBook
        }
      })
    }
    this.menuItems.push({
      label: this.i18n.$gettext('Rename Addressbook'),
      icon:'cil-pencil',
      command: () => {
        this.addressBookToRename = Object.assign(new AddressBook(), addressBook)
      }
    })
    if (!addressBook.isDefault) {
      this.menuItems.push({
        label: this.i18n.$gettext('Delete'),
        icon: 'cil-trash',
        command: () => {
          this.deleteAddressBook(addressBook)
        }
      })
    }
  }

  saveName() {
    if (this.addressBookToRename) {
      this.renameLoading = true
      addressBookServiceApi._updateAddressBook(this.addressBookToRename).then(() => {
        this.toast.success(this.i18n.$gettext("Addressbook renamed"))
      }).catch((e: RpcError) => {
        this.toast.error(e.message, this.i18n.$gettext("Addressbook could not be renamed"))
      }).finally(() => {
        this.renameLoading = false
      })
      this.addressBookToRename = null
    }
  }

  deleteAddressBook(addressBook: AddressBook) {
    this.confirm.require({
      message: this.i18n.$gettext('Do you really want to delete this address book?'),
      header: this.i18n.$gettext('Confirmation'),
      icon: 'cil-warning',
      accept: () => {
        if (addressBook.originalId) {
          addressBookServiceApi._deleteAddressBook(addressBook.originalId).then(() => {
            this.toast.success(this.i18n.$gettext("Address book deleted"))
          }).catch((e: RpcError) => {
            this.toast.error(e.message, this.i18n.$gettext("Address book could not be deleted"))
          })
        }
      },
      reject: () => {
        //callback to execute when user rejects the action
      }
    })
  }

  get isAllowedToCreate(): boolean {
    if (this.selectedAddressbookObject) {
      return ['WRITE', 'OWNER'].includes(this.selectedAddressbookObject.shareAccess || '')
    }
    return false
  }

  get hasContactsBooked(): boolean {
    return featureSubset.hasDAV
  }

  get isOnMobile(){
    return breakpointUtil.isOnMobile()
  }

  mounted() {
    if (!this.selectedAddressBook) {
      let lastViewed: string | null | undefined = SettingsUtil.getLastViewedCollection('addressBook')
      const addressBook = (this.addressbooks || []).find((a: AddressBook) => a.originalId === lastViewed || a.id === lastViewed)
      if (!this.addressBooksAreLoading && !addressBook) {
        //This addressBook no longer exist.
        lastViewed = undefined
        //Reset settings
        void SettingsUtil.setLastViewedCollection('addressBook', '')
      }
      if (addressBook?.originalId) {
        this.openAddressBook(addressBook.originalId)
      } else {
        if(this.isOnMobile){
          this.openMobileMenuDefault = true
        } else {
          //Select default addressbook:
          if(this.addressbooks.length > 0){
            const defaultAddressBook: string | null = this.addressbooks[0].originalId
            if (defaultAddressBook) {
              this.openAddressBook(defaultAddressBook)
            }
          }
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">

@import "node_modules/elly-bs4/sass/variables";




</style>
