import { rpcClient } from "@/api/WebsocketClient"
import SWR, { Call } from "@/api/SWR"
import { reactive } from "@vue/reactivity"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import VirtualMailbox from '@/model/settings/VirtualMailbox';
import Permission from '@/model/entry/Permission';
import Signature from '@/model/settings/Signature';
import Page from '@/model/Page';
import MailFolder from '@/model/directory/MailFolder';
import MailFilter from '@/model/settings/MailFilter';
import OutOfOffice from '@/model/settings/OutOfOffice';
import { virtualMailboxStore } from '@/store/VirtualMailboxStore';

export default class GeneratedVirtualMailboxServiceApi {

    cache: Map<string, Call<any>> = new Map<string, Call<any>>()

    constructor() {
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.apis.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    clearState() {
        this.cache = new Map<string, Call<any>>()
    }

    get connected(): boolean {
        return rpcClient.state.connected
    }

    _setPermissions(address: string, permissions: Permission[]): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('setPermissions', rpcParams, false).then((data: any) => {
            const model = Object.assign(new VirtualMailbox(), data)
            virtualMailboxStore.removeVirtualMailbox(address)
            virtualMailboxStore.addOrReplaceVirtualMailbox(model)
            return model.address
        })
    }

    _setSignatures(address: string, signatures: Signature[]): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('setSignatures', rpcParams, false).then((data: any) => {
            const model = Object.assign(new VirtualMailbox(), data)
            virtualMailboxStore.removeVirtualMailbox(address)
            virtualMailboxStore.addOrReplaceVirtualMailbox(model)
            return model.address
        })
    }

    _deleteVirtualMailbox(address: string): Promise<void> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('deleteVirtualMailbox', rpcParams, false).then(() => {
            virtualMailboxStore.removeVirtualMailbox(address)
        })
    }

    _getFoldersFor(address: string): Promise<MailFolder[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getFoldersFor', rpcParams, false).then((data: any) => {
            return data && Array.isArray(data) ? data.map(it => Object.assign(new MailFolder(), it)) : Promise.reject()
        })
    }

    _setMailfilters(address: string, filters: MailFilter[]): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('setMailfilters', rpcParams, false).then((data: any) => {
            const model = Object.assign(new VirtualMailbox(), data)
            virtualMailboxStore.removeVirtualMailbox(address)
            virtualMailboxStore.addOrReplaceVirtualMailbox(model)
            return model.address
        })
    }

    _setOutOfOfficeFilters(address: string, filters: OutOfOffice[]): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('setOutOfOfficeFilters', rpcParams, false).then((data: any) => {
            const model = Object.assign(new VirtualMailbox(), data)
            virtualMailboxStore.removeVirtualMailbox(address)
            virtualMailboxStore.addOrReplaceVirtualMailbox(model)
            return model.address
        })
    }

    _createVirtualMailbox(address: string): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('createVirtualMailbox', rpcParams, false).then((data: any) => {
            const model = Object.assign(new VirtualMailbox(), data)
            virtualMailboxStore.addOrReplaceVirtualMailbox(model)
            return model.address
        })
    }

    _getVirtualMailboxes(): Promise<VirtualMailbox[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getVirtualMailboxes', rpcParams, true).then((data: any) => {
            if (data && Array.isArray(data)) {
                const virtualMailboxs: VirtualMailbox[] = data.map(virtualMailbox => Object.assign(new VirtualMailbox(), virtualMailbox))
                virtualMailboxStore.replaceVirtualMailboxs(virtualMailboxs)
                return virtualMailboxs
            } else return Promise.reject()
        })
    }


    _addVirtualMailboxPassword(address: string, accessDescription: string): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('addVirtualMailboxPassword', rpcParams, false).then((data: any) => {
            const model = Object.assign(new VirtualMailbox(), data)
            virtualMailboxStore.removeVirtualMailbox(address)
            virtualMailboxStore.addOrReplaceVirtualMailbox(model)
            return model.address
        })
    }

    _deleteVirtualMailboxPassword(address: string, accessDescription: string): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('deleteVirtualMailboxPassword', rpcParams, false).then((data: any) => {
            const model = Object.assign(new VirtualMailbox(), data)
            virtualMailboxStore.removeVirtualMailbox(address)
            virtualMailboxStore.addOrReplaceVirtualMailbox(model)
            return model.address
        })
    }

    getVirtualMailboxes(refresh?: boolean | number): SWR<VirtualMailbox[], string[]> {
        //@ts-ignore
        const result: SWR<VirtualMailbox[], string[]> = reactive(new SWR<VirtualMailbox[], string[]>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 0).filter(arg => arg !== undefined)
        const callId: string = '_getVirtualMailboxes' + JSON.stringify(args)
        const cached: Call<string[]> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_getVirtualMailboxes[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: string[]) => {
                const virtualMailboxs: VirtualMailbox[] = []
                for (const id of data) {
                    const virtualMailbox: VirtualMailbox | undefined = virtualMailboxStore.state.virtualMailboxs.get(id)
                    if (virtualMailbox) {
                        virtualMailboxs.push(virtualMailbox)
                    }
                }
                result.data = virtualMailboxs
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<string[]>()) as Call<string[]>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._getVirtualMailboxes().then((data: VirtualMailbox[]) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data
                //@ts-ignore since Array.filter does not provide nullsafe guard
                call.data = data.map(virtualMailbox => virtualMailbox.address || '')
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        let virtualMailboxs: VirtualMailbox[] = [...virtualMailboxStore.state.virtualMailboxs.values()]
        
        
        
        result.data = virtualMailboxs
        return result
    }

    getVirtualMailbox(address: string): VirtualMailbox | undefined {
        return virtualMailboxStore.state.virtualMailboxs.get(address)
    }
}
