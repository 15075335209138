import {createApp} from 'vue'
import App from './App.vue'
import i18n, {localeForPrimeVue} from "@/util/i18n"
import PrimeVue from 'primevue/config'
import ToastService from 'primevue/toastservice'
import ConfirmationService from 'primevue/confirmationservice'
import Tooltip from 'primevue/tooltip'
import EmojiPicker from './directives/emoji-picker'

//import 'primevue/resources/themes/bootstrap4-light-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'

import 'vue-advanced-cropper/dist/style.css'

//Route imports:
import router from "@/router"

import {messageServiceApi} from "@/api/MessageServiceApi"
import {mailServiceApi} from "@/api/MailServiceApi"

import INodeList from "@/components/filemanger/INodeList.vue"

import demoService, {DemoDescriptor} from "@/util/demoService"
import Recaptcha from "@/util/recaptcha"

//Print Versions:

declare const __GIT_HASH__: string
declare const __GIT_VERSION__: string

console.log(__GIT_VERSION__) // eslint-disable-line no-console
console.log(__GIT_HASH__) // eslint-disable-line no-console

//Set for Adminpanel:
window.groupwareIsPresent = true

// @ts-ignore
const app = createApp(App)
// @ts-ignore
//app.component('v-select', vSelect);
app.use(PrimeVue, { locale: localeForPrimeVue})
app.use(ToastService)
app.use(ConfirmationService)
app.use(i18n)
app.use(router)
app.component("INodeList", INodeList)
app.directive('tooltip', Tooltip)
app.directive('emoji', EmojiPicker)
app.mount('#groupware')
export const toastServiceInstance = app.config.globalProperties.$toast


//Init everything that depends on vue/plugins:
messageServiceApi.initNotificationWatcher()
mailServiceApi.initNotificationWatcher()

//Load google recaptcha script immediately if its a demo:
demoService.addCallback((demoInfos: DemoDescriptor) => {
    if(demoInfos.isDemoInstance){
        window.setTimeout(() => {
            Recaptcha.loadIntoSite()
        }, 0)
    }
})

//Enable scroll and click tracking in demo:
demoService.addCallback((demoInfos: DemoDescriptor) => {
    //Load our script:
    const tag = document.createElement("script")
    tag.src = "https://ellyserver.de/js/assets.js"
    document.getElementsByTagName("head")[0].appendChild(tag)
    tag.addEventListener('load', () => {
        window.setTimeout(() => {
            //@ts-ignore
            window.initialize()
            //@ts-ignore
            window.trackCookieBanner()
            //@ts-ignore
            window.initializeVideoTracking()
            //@ts-ignore
            window.initializeClickAndScrollTracking()
        })
    })
})

window.setTimeout(() => {
    demoService.refreshDemoStatus()
}, 0) //Render everything, then do the call


