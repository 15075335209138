import { rpcClient } from "@/api/WebsocketClient"
import { reactive } from '@vue/reactivity'
import Message from '@/model/entry/Message';


export default class GeneratedMessageStore {

    state = reactive({
        messages: new Map<string, Message>(),
        calls: new Map<string, string | string[]>()
    })

    constructor() {
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.stores.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    clearState() {
        if (this.state?.messages) this.state.messages.clear()
        if (this.state?.calls) this.state.calls.clear()
    }

    addOrReplaceMessage(newMessage: Message) {
        if (newMessage.id) {
            this.state.messages.set(newMessage.id, newMessage)

        }
    }

    addOrReplaceMessages(newMessages: Message[]) {
        for (const newMessage of newMessages) {
            this.addOrReplaceMessage(newMessage)
        }
    }

    replaceMessages(messages: Message[]) {
        const newMessages = new Map<string, Message>()
        for (const newMessage of messages) {
            if (newMessage.id) {
                newMessages.set(newMessage.id, newMessage)

            }
        }
        this.state.messages = newMessages
    }

    removeMessage(id: string | undefined | null) {
        if (id !== undefined && id !== null) {
            this.state.messages.delete(id)
        }
    }

    removeMessages(ids: (string | null)[] | undefined | null) {
        if (ids !== undefined && ids !== null) {
            for (const id of ids) {
                this.removeMessage(id)
            }
        }
    }
}
