
import INode from '@/model/entry/INode'

import {Options, Vue} from "vue-class-component"
import dayjs from 'dayjs'
import Menu from 'primevue/menu'
import { ref } from "@vue/reactivity"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {fileServiceApi} from "@/api/FileServiceApi"
import RpcError from "@/api/RpcError"
import {useConfirm} from "primevue/useconfirm"
import AnimatedInput from "@/components/common/AnimatedInput.vue"
import LoadingButton from "@/components/common/LoadingButton.vue"
import Checkbox from "primevue/checkbox"
import {Watch} from "vue-property-decorator"
import {ClipboardAction, ClipboardEntry} from "@/components/filemanger/subcomponents/Clipboard"
import InputText from "primevue/inputtext"
import Button from "primevue/button"
import ContextMenu from "primevue/contextmenu"
import useToast from "@/util/toasts"
import {iNodeStore} from "@/store/INodeStore"

@Options({
  components: {
    LoadingButton,
    AnimatedInput,
    Menu,
    ContextMenu,
    Checkbox,
    InputText,
    Button
  },
  //@ts-ignore
  props: {
    inode: [ INode, Object ],
    path: String,
    selection: {type: Array, default: []},
    clipboard: {type: Array, default: []},
    historyId: {
      type: String,
      default: ""
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    isEmbedded: {
      type: Boolean,
      default: false
    }
  },
  emits: ['inodeDeleted', 'inodeSelected', 'inodeDeselected', 'comment', 'share', 'history', 'inodeChosen']
})
export default class INodeEntry extends Vue {
  inode!: INode
  path!: string
  selection!: INode[]
  clipboard!: ClipboardEntry[]
  readOnly = false

  i18n: Language = useGettext()
  api = fileServiceApi
  toast = useToast()
  confirm = useConfirm()

  newName = ""

  editMode = false
  selected = false
  historyId = ""

  //@ts-ignore
  menu: Menu = ref(null)
  //@ts-ignore
  contextmenu: Menu = ref(null)

  //Loading:
  renameInodeLoading = false

  historicDownloadLoading = false
  historicRevertLoading = false

  get menuItems(): any {
    if (this.historic) {
      let items = []

      if (!this.readOnly) {
        items.push({
          label: this.i18n.$pgettext('File Context Menu', 'Revert'),
          icon: 'cil-restore',
          command: () => {
            this.revertInode()
          }
        })
      }

      if (this.inode.path && !this.inode.isDirectory) {
        items.push({
          label: this.i18n.$pgettext('File Context Menu', 'Download'),
          icon: 'cil-data-transfer-down',
          command: () => {
            void this.downloadHistoricInode()
          }
        })
      }

      return items
    } else {
      let items = [
      ]

      if(this.inode.canShare || this.inode.parentPath != '/') {
        items.push({
          label: this.i18n.$pgettext('File Context Menu', 'Share'),
          icon: 'cil-share',
          command: () => {
            this.$emit('share', this.inode)
          }
        })
      }

      if (!this.inode.isDirectory) {
        items.push({
          label: this.i18n.$pgettext('File Context Menu', 'Download'),
          icon: 'cil-data-transfer-down',
          command: () => {
            if (this.inode.directLink) {
              window.open(this.inode.directLink + '?dl=1', "_blank", "noopener")
            }
          }
        })
      }

      if (!this.readOnly) {
        items.push(
          {
            label: this.i18n.$pgettext('File Context Menu', 'Rename'),
            icon: 'cil-pencil',
            command: () => {
              this.editMode = true
            }
          },
          {
            label: this.i18n.$pgettext('File Context Menu', 'Delete'),
            icon: 'cil-trash',
            command: () => {
              this.deleteInode()
            }
          }
        )
      }

      if (!this.inode.isDirectory) {
        items.push({
          label: this.i18n.$pgettext('File Context Menu', 'History'),
          icon: 'cil-clock',
          command: () => {
            this.$emit('history', this.inode)
          }
        })
      }

      items.push({
        label: this.i18n.$pgettext('File Context Menu', 'Comment'),
        icon: 'cil-comment-bubble-lines',
        command: () => {
          this.$emit('comment', this.inode)
        }
      })

      if (!this.inode.isDirectory) {
        items.push({
          label: this.i18n.$pgettext('File Context Menu', 'Send Email'),
          icon: 'cil-envelope-closed',
          command: () => {
            this.$emit('email', this.inode)
          }
        })
      }

      return items
    }
  }

  mounted(): void {
    this.newName = this.inode.name || ""
  }

  get isHighlighted(): boolean {
    const queryParams = this.$route.query
    if (queryParams.hasOwnProperty("highlight")) {
      const nameToHighlight = queryParams["highlight"]
      return this.inode.name === nameToHighlight
    } else {
      return false
    }
  }

  get name(): string | null {
    if (this.inode.historyId && this.inode.name) {
      const i: number = this.inode.name.lastIndexOf('/')
      if (i > 0) {
        return this.inode.name.substr(i + 1, this.inode.name.length - i)
      } else {
        return this.inode.name
      }
    } else {
      return this.inode.name
    }
  }

  get historicDir(): string | null {
    if (this.inode.historyId && this.inode.name) {
      const i: number = this.inode.name.lastIndexOf('/')
      if (i > 0) {
        return this.inode.name.substr(0, i)
      }
    }
    return null
  }

  get humanReadableDate(): string {
    const lastModified: string = this.inode.lastModified || ""
    return this.inode.lastModified ? dayjs(lastModified).format("HH:mm DD.MM.YYYY") : ""
  }

  goToInode(): void {
    if (this.inode) {
      this.$emit('inodeChosen', this.inode)
    }
  }

  toggleDropdown(e: Event): void {
    this.menu.toggle(e)
    this.contextmenu.hide()
  }

  showContextMenu(e: Event): void {
    this.contextmenu.show(e)
    this.menu.hide()
  }

  deleteInode(): void {
    this.confirm.require({
      message: this.i18n.$gettext("Do you want to delete this?"),
      header: this.i18n.$gettext("Confirmation"),
      icon: 'cil-warning',
      accept: () => {
        this._deleteInode().then(() => {
          this.toast.success(this.i18n.$gettext("Delete successful"))
        }).catch((e: RpcError) => {
          this.toast.error(e.message, this.i18n.$gettext("Failed to delete"))
        })
      },
      reject: () => {
        //callback to execute when user rejects the action
      }
    })
  }

  _deleteInode(): Promise<void> {
    if (this.inode && this.inode.path) {
      return this.api._deleteINode(this.inode.path + ((this.inode.isDirectory && !this.inode.path.endsWith('/')) ? '/' : '')).then(() => {
        this.$emit('inodeDeleted')
      })
    } else {
      return Promise.reject()
    }
  }

  renameInode(): Promise<void> {
    if (this.newName === "") {
      this.toast.error(this.i18n.$gettext("You need to enter a name"))
      return Promise.reject()
    }
    if (!this.inode) return Promise.reject()
    if (!this.inode.path) return Promise.reject()
    this.renameInodeLoading = true
    return this.api._renameINode(this.inode.path, this.newName).then(() => {
      this.editMode = false
      this.toast.success(this.i18n.$gettext("Rename successful"))
    }).catch((error: RpcError) => {
      this.toast.error(error.message)
      this.editMode = false
    }).finally(() => { this.renameInodeLoading = false })
  }

  @Watch('selected')
  reportSelected(): void {
    if (this.selected) {
      this.$emit("inodeSelected", this.inode)
    } else {
      this.$emit("inodeDeselected", this.inode)
    }
  }

  @Watch('selection')
  updateSelected(): void {
    if (this.selection.length === 0) {
      this.selected = false //deselect on selection clear
    }
  }

  get isSelected(): boolean {
    if (this.selection.length === 0) return false
    return (this.selection.includes(this.inode))
  }

  get shouldBeCut(): boolean {
    return this.isActionInClipboardForThisInode(ClipboardAction.CUT)
  }

  get shouldBeCopied(): boolean {
    return this.isActionInClipboardForThisInode(ClipboardAction.COPY)
  }

  isActionInClipboardForThisInode(action: ClipboardAction) {
    if (this.clipboard.length === 0) return false
    return (this.clipboard.findIndex((entry: ClipboardEntry) => {
      return (entry.path === this.inode.path && entry.action === action)
    }) !== -1)
  }

  get historic(): boolean {
    return this.historyId !== ""
  }

  revertInode(): void {
    if (this.inode.path?.startsWith('/trash/')) {
      this._revertInode()
    } else {
      this.confirm.require({
        message: this.i18n.$gettext("Do you want to revert this?"),
        header: this.i18n.$gettext("Confirmation"),
        icon: 'cil-warning',
        accept: () => {
          this._revertInode()
        },
        reject: () => {
          //callback to execute when user rejects the action
        }
      })
    }
  }

  _revertInode(): void {
    let promise: Promise<void>
    if (!this.inode.path) {
      this.toast.success(this.i18n.$gettext("File could not be reverted"), this.i18n.$gettext("Path not found"))
      return
    }
    if (this.inode.isDirectory) {
      promise = this.api._revertDirectory(this.inode.path, this.historyId)
    } else {
      promise = this.api._restoreFile(this.inode.path, this.historyId)
    }
    this.historicRevertLoading = true
    promise.then(() => {
      this.toast.success(this.i18n.$gettext("File reverted successfully"))
      if (this.inode.path?.startsWith('/trash/')) {
        iNodeStore.removeINode(this.inode.path)
      }
    }).catch((error: RpcError) => {
      if (this.inode.path?.startsWith('/trash/') && error.message?.includes('Not Found')) {
        this.toast.warn(this.i18n.$gettext("File can't be restored because the original parent directory does not exist. Please download the file instead."))
      } else {
        this.toast.error(error.message)
      }
    }).finally(() => { this.historicRevertLoading = false })
  }

  downloadHistoricInode(): Promise<void> {
    if (this.inode.path) {
      this.historicDownloadLoading = true
      return this.api._getDownloadLinkFromHistory(this.inode.path, this.historyId).then((uri: string) => {
        let link = document.createElement("a")
        link.setAttribute('download', '')
        link.href = uri
        document.body.appendChild(link)
        link.click()
        link.remove()
      }).catch((error: RpcError) => {
        this.toast.error(error.message, this.i18n.$gettext("Could not access file in history"))
      }).finally(() => { this.historicDownloadLoading = false})
    } else {
      return Promise.reject('No path given.')
    }
  }

}
