
import INode from "@/model/entry/INode"
import {Options, Vue} from "vue-class-component"
import {fileServiceApi} from "@/api/FileServiceApi"
import ShareLink from "@/model/common/ShareLink"
import Dialog from "primevue/dialog"
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import dayjs from 'dayjs'
import LoadingButton from "@/components/common/LoadingButton.vue"
import InputSwitch from "primevue/inputswitch"
import InputNumber from "primevue/inputnumber"
import Password from "primevue/password"
import ProgressBar from "primevue/progressbar"
import RpcError from "@/api/RpcError"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import Button from "primevue/button"
import useToast from "@/util/toasts"

@Options({
  components: {
    Dialog, TabView, TabPanel, LoadingButton, InputSwitch, InputNumber, Password, ProgressBar, Button
  },
  //@ts-ignore
  props: {
    inode: INode,
  },
  emits: ["shouldClose"]
})
export default class DownloadLinkForm extends Vue {

  api = fileServiceApi

  inode!: INode

  links: ShareLink[] = []
  loaded = false
  loading = false
  toast = useToast()
  createWithPassword = false
  createWithExpiryDate = false
  expiryDays = 14 //default
  password = ""
  fetchFailed = false
  i18n: Language = useGettext()

  actionIsLoading = false;

  get canCreate(): boolean {
    return (!this.createWithPassword || this.password.length >= 8)
  }

  mounted(): void {
    if(this.loaded && !this.fetchFailed) return
    this.loadData().catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext("Failed to retrieve link information"))
    })
  }

  loadData(): Promise<void> {
    if(this.inode.path === null) return Promise.reject()
    this.loading = true
    return this.api._listShareLinks(this.inode.path).then((links: ShareLink[]) => {
      this.links = links
      this.loading = false
      this.loaded = true
    }).catch((error: RpcError) => {
      this.toast.error(error.message)
      this.loading = false
      this.fetchFailed = true
    })
  }

  get hasLink(): boolean {
    return this.inode && this.nonInternalLinks.length > 0
  }

  getLink(): ShareLink | null {
    return this.nonInternalLinks.length > 0 ? this.nonInternalLinks[0] : null
  }

  get nonInternalLinks(): ShareLink[] {
    return this.links.filter(l => !l.internal)
  }

  get href(): string {
    return this.getLink()?.link || ''
  }

  get directHref(): string | null {
    return this.inode.isDirectory ? null : this.href + "?dl=1"
  }

  get humanreadableDate(): string {
    if (this.getLink()?.expireDate === null || this.getLink()?.expireDate === "") {
      return this.i18n.$gettext("This link has no expiration date")
    } else {
      const date: string = this.getLink()?.expireDate || ""
      return this.i18n.$gettext("This link expires on") + dayjs(date).format("HH:mm, DD.MM.YYYY")
    }
  }

  deleteLink(): Promise<void> {
    const token: string = this.getLink()?.token || ""
    if (token === "") {
      return Promise.reject()
    } else {
      this.actionIsLoading = true
      return this.api._deleteShareLink(token).catch((e: RpcError) => {
        this.toast.error(e.message, this.i18n.$gettext("Share Link could not be deleted"))
      }).then(() => {
        this.toast.info(this.i18n.$gettext("Share Link deleted"))
        void this.loadData()
      }).finally(() => { this.actionIsLoading = false })
    }
  }

  createLink(): Promise<void> {
    if (this.inode?.path) {
      const expireDays: number | null = (this.createWithExpiryDate ? this.expiryDays : null)
      const password: string | null =  (this.createWithPassword ? this.password : null)
      this.actionIsLoading = true
      const mustCreateUncheckedLink: boolean = this.links.length > 0
      return this.api._createShareLink(
        this.inode.path, password, expireDays, true, true, false,
        mustCreateUncheckedLink, false
      ).then(() => {
        this.toast.info(this.i18n.$gettext("Share Link created"))
        void this.loadData() //Reload info
      }).catch((e: RpcError) => {
        this.toast.error(e.message, this.i18n.$gettext("Share Link could not be created"))
      }).finally(() => { this.actionIsLoading = false })
    } else {
      return Promise.reject()
    }
  }


}
