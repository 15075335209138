
import { Editor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import Highlight from '@tiptap/extension-highlight'
import Typography from "@tiptap/extension-typography"

import {Options, Vue} from "vue-class-component"
import Button from "primevue/button"
import {Mention} from "@tiptap/extension-mention"
import {Placeholder} from "@tiptap/extension-placeholder"
import {Underline} from "@tiptap/extension-underline"
//@ts-ignore
import suggestion from "./suggestion"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import turndownService from "@/util/Turndown"


@Options({
  components: { EditorContent, Button },
  //@ts-ignore
  props: {
    canUseMentions: Boolean
  },
  emits: ['enter']
})
export default class ChatMessageComposer extends Vue {

  editor: Editor | null = null

  i18n: Language = useGettext()

  canUseMentions!: boolean

  mounted() {
    const extensions: any[] = [
      StarterKit,
      Underline,
      Typography,
      Highlight,
      Placeholder.configure({
        placeholder: this.i18n.$gettext("Type a message and press Enter to send."),
      }),
    ]

    if (this.canUseMentions) {
      extensions.push(Mention.configure({
        HTMLAttributes: {
          class: 'mention',
        }, suggestion
      }))
    }
    this.editor = new Editor({
      content: '',
      extensions: extensions,
      editorProps: {
        attributes: {
          class: 'custom-editor',
        },
        handleDOMEvents: {
          //Must be added here, because adding @keydown to editor-content handles the event too late,
          //after a newline has already been inserted in the editor
          keydown: (view, event) => {
            if (this.editor &&
              event.which == 13 && !event.shiftKey &&
              !this.editor.isActive('bulletList') &&
              !this.editor.isActive('orderedList') &&
              !this.editor.isActive('codeBlock')) {
              event.preventDefault() //Prevent newline from being inserted
              this.$emit("enter")
              return true
            } else {
              return false
            }
          }
        }
      }
    })
  }

  getHTML(): string | undefined {
    return this.editor?.getHTML()
  }

  insertContent(content: string | undefined) {
    if (content && this.editor) this.editor.chain().focus().insertContent(content).run()
  }

  setContent(content: string | undefined) {
    if (content && this.editor) {
      this.editor.chain().focus().setContent(content).run()
    }
  }

  //we do not use reactivity here since we'd have to run turndown everytime the user types. Its more efficient this way
  //Also: This isolates the message and provides a real draft that does no affect the state
  getMarkdownMessage(): string {
    const html: string | undefined = this.getHTML()
    if (html) {
      const md: string | undefined = turndownService.turndown(html)
      return md ? md.trim() : ""
    } else {
      return ""
    }
  }

  addEmojiToEditor(emoji: string): void {
    if (this.editor) this.editor.chain().focus().insertContent(emoji).run()
  }

  clearMessage(): void {
    if (this.editor) this.editor.commands.clearContent()
  }

  beforeUnmount() {
    this.editor?.destroy()
  }
}
