<template>
  <div class="d-flex flex-column h-100 p-2">
    <div class="container-fluid flex-grow-1 card card-lite bg-light z-2 w-100" style="overflow: auto">
      <!--
      <div class="card my-3 mr-1">
        <div class="row m-3">
          <div class="mr-3">
            <ProgressSpinner v-if="!cachedImageInstance.loaded" :style="{height: '128px', width: '128px'}" />
            <img
              v-else
              :alt="i18n.$gettext('User Avatar')"
              :style="{height: '128px', width: '128px'}"
              :src="cachedImageInstance.cached"
            >
          </div>
          <div>
            <h3 class="mb-1">
              <translate>Hello</translate>
              {{ userDisplayName }}!
              <translate v-if="showIntroVideo">Nice to meet you</translate>
              <translate v-else>Welcome back</translate> :)
            </h3>
            <h5 class="mb-1">{{ subGreeting }}</h5>
            <div class="row ml-1">
              <Button
                v-tooltip.top="i18n.$gettext('Chat')"
                class="p-0 mr-3 mt-3"
                aria-label="Chat"
                @click="goToChat"
              >
                <i class="cil-chat-square cil-fw px-3"></i>
                <span class="px-3">{{ unreadChatMessageCnt }}</span>
              </Button>
              <Button
                v-tooltip.top="i18n.$gettext('Mail')"
                class="p-0 mr-3 mt-3"
                aria-label="Mail"
                @click="goToMail"
              >
                <i class="cil-mail cil-fw px-3"></i>
                <span class="px-3">{{ unreadMessagesCnt }}</span>
              </Button>
              <Button
                v-tooltip.top="i18n.$gettext('Calendar')"
                class="p-0 mr-3 mt-3"
                aria-label="Events"
                @click="goToCalendar"
              >
                <i class="cil-calendar cil-fw px-3"></i>
                <span class="px-3">{{ eventCnt }}</span>
              </Button>
              <Button
                v-tooltip.top="i18n.$gettext('Tasks')"
                class="p-0 mr-3 mt-3"
                aria-label="Tasks"
                @click="goToTasks"
              >
                <i class="cil-task cil-fw px-3"></i>
                <span class="px-3">{{ overDueTasksCnt }}</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
      -->
      <div class="row" data-v-6956d1df=""><h1 class="col-12 mt-4 mb-0 h5 d-block" data-v-6956d1df=""><span data-v-6956d1df="">Willkommen, {{ userDisplayName }}!</span></h1></div>
      <div v-if="!hasNothing" class="row">
        <div v-if="showMigrationWidget" class="col-12 col-md-6">
          <MigrationWidget class="mr-md-4 mr-3 mt-6" />
        </div>
        <div v-if="showIntroVideo" class="col-12 col-md-6">
          <IntroVideoWidget class="mr-md-4 mr-3 mt-6" />
        </div>
        <div class="col-12 col-md-6">
          <CalendarWidget
            v-if="hasBookedDav"
            ref="calendarWidgetRef"
            class="mr-md-2 mb-3 mr-1"
          />
        </div>
        <div class="col-12 col-md-6">
          <TaskWidget
            v-if="hasBookedDav"
            ref="taskWidgetRef"
            class="mr-md-2 mb-3 mr-1"
          />
        </div>
        <div class="col-12 col-md-6">
          <EmailWidget
            v-if="hasBookedEmails"
            ref="eMailWidgetRef"
            class="mr-md-2 mb-3 mr-1"
          />
        </div>
        <div class="col-12 col-md-6">
          <ChatWidget
            v-if="hasBookedChat"
            ref="chatWidgetRef"
            class="mr-md-2 mb-3 mr-1"
          />
        </div>
      </div>
      <div v-else class="d-flex flex-grow-1 flex-column justify-content-center h-100">
        <div class="text-center">
          <p class="h5 mb-2">
            <translate>Dashboard has nothing to show</translate>
          </p>
          <p><translate>You have neither Files, nor Calendars, Tasks or Chat booked. So we cannot display anything here.</translate></p>
        </div>
      </div>
    </div>
    <teleport to="#menubarcontent">
      <SearchBar class="pt-2" />
    </teleport>
  </div>
</template>

<script lang="ts">

import {Options, Vue} from "vue-class-component"
import EmailWidget from "@/components/dashboard/widgets/EmailWidget.vue"
import ChatWidget from "@/components/dashboard/widgets/ChatWidget.vue"
import CalendarWidget from "@/components/dashboard/widgets/CalendarWidget.vue"
import TaskWidget from "@/components/dashboard/widgets/TaskWidget.vue"
import featureSubset from "@/util/FeatureSubsets"
import IntroVideoWidget from "@/components/dashboard/widgets/IntroVideoWidget.vue"
import MigrationWidget from "@/components/dashboard/widgets/MigrationWidget.vue"
import SettingsUtil from "@/util/SettingsUtil"
import Constants from "@/util/Constants"
import migrationStatusService from "@/util/migrationStatus"
import {rpcClient} from "@/api/WebsocketClient"
import SearchBar from "@/components/common/SearchBar.vue"
import Avatar from "primevue/avatar"
import {CachedImage, imageLoadingService} from "@/util/ImageLoadingService"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import ProgressSpinner from 'primevue/progressspinner'
import Channel from "@/model/directory/Channel"
import {ref} from "@vue/reactivity"
import Button from "primevue/button"

@Options({
  components: {
    EmailWidget, ChatWidget, CalendarWidget, TaskWidget,
    IntroVideoWidget, MigrationWidget, SearchBar, Avatar,
    ProgressSpinner, Button
  },
  //@ts-ignore
  props: {},
  emits: []
})
export default class DashboardView extends Vue {
  i18n: Language = useGettext()

  //@ts-ignore
  eMailWidgetRef: EmailWidget = ref<EmailWidget | null>(null)
  //@ts-ignore
  chatWidgetRef: ChatWidget = ref<ChatWidget | null>(null)
  //@ts-ignore
  calendarWidgetRef: CalendarWidget = ref<CalendarWidget | null>(null)
  //@ts-ignore
  taskWidgetRef: TaskWidget = ref<TaskWidget | null>(null)

  goToCalendar(): void {
    void this.$router.push('/calendar')
  }

  goToChat(): void {
    void this.$router.push('/chat')
  }

  goToMail(): void {
    void this.$router.push(this.eMailWidgetRef.inboxLink)
  }

  goToTasks(): void {
    void this.$router.push('/tasks')
  }

  get userImage(): CachedImage | null {
    const userName: string | undefined | null = this.userName
    if (userName) {
      return imageLoadingService.getCachedImage(`/groupware-api/v1/users/${userName}/picture`)
    } else {
      return null
    }
  }

  get cachedImageInstance(): CachedImage | null {
    return this.userImage || null
  }

  get userName(): string | null {
    return rpcClient.session?.user?.userName || null
  }

  get userDisplayName(): string | null {
    return rpcClient.session?.user?.displayName || null
  }

  get hasBookedEmails(){
    return featureSubset.hasMail
  }

  get hasBookedChat(){
    return featureSubset.hasChat
  }

  get hasBookedDav(){
    return featureSubset.hasDAV
  }

  get showIntroVideo(){
    return !SettingsUtil.getIntroVideoSeen() && Constants.introductionVideoUrl !== ""
  }

  get showMigrationWidget(){
    return migrationStatusService.hasAnyMigrationStatus && !this.hasAcknowledgedMigrationBanner //Show as long as any migration runs
  }

  get hasAcknowledgedMigrationBanner(): boolean {
    const settings = rpcClient.session?.user?.settings
    if(settings){
      if(settings.hasOwnProperty("acknowledgedMigrationModal")){
        return settings.acknowledgedMigrationModal
      } else {
        return false
      }
    } else {
      return false
    }
  }

  get hasNothing(){
    return !this.hasBookedEmails && !this.hasBookedDav && !this.hasBookedChat
  }
}
</script>

<style lang="scss" scoped>

.dashboard-widget {
  width: 50%;
  display: inline-flex !important;

  &:first-child {

  }

}

@media screen and (max-width:767px){
  .dashboard-widget {
    width: 100%;
  }
}


</style>
