<template>
  <div class="dash-widget d-flex flex-column card card-lite bg-white z-1">
    <div class="dash-widget-count z-2" @click="acknowledgedMigrationBanner">
      X
    </div>
    <div class="w-100 px-3 py-3">
      <p class="h5 mb-0">
        <translate>Data migration status</translate>
      </p>
    </div>
    <div style="min-height: 22.8rem; max-height: 22.8rem; height: 22.8rem; overflow-y: auto; padding: 1.2rem; padding-top: 0;">
      <div>
        <dl class="mb-3">
          <div v-for="migration in migrationsToDo" :key="migration.key" class="row">
            <dt class="col-sm-5">
              <i style="margin-bottom: 0.1rem" :class="getMigrationComponentIcon(migration.key)" /> {{ migration.title }}
            </dt>
            <dd class="col-sm-7">
              <Badge :value="getMigrationStatusTranslatedForComponent(migration.key)" :severity="getMigrationStatusCodeForComponent(migration.key)" />
            </dd>
          </div>
          <div class="row">
            <dt class="col-sm-5 mb-0">
              <i style="margin-bottom: 0.1rem" class="cil-mail cil-fw" /> <translate>Your own Emails</translate>
            </dt>
            <dd class="col-sm-7 mb-0">
              <Badge :value="myMailMigrationStatusTranslated" :severity="myMailMigrationStatusCode" />
            </dd>
          </div>
        </dl>
        <p v-if="isAdmin" class="mb-3">
          <translate>The following users have not logged in to start their migration:</translate>&nbsp;{{ unstartedUserList }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import {Options, Vue} from "vue-class-component"
import migrationStatusService, {MigrationStatus} from "@/util/migrationStatus"
import {rpcClient} from "@/api/WebsocketClient"
import Badge from "primevue/badge"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import UserSettings from "@/model/settings/UserSettings"
import {userServiceApi} from "@/api/UserServiceApi"

@Options({
  components: { Badge },
  //@ts-ignore
  props: {},
  emits: []
})
export default class MigrationWidget extends Vue {

  i18n: Language = useGettext()

  get migrationStatus(): MigrationStatus {
    return migrationStatusService.migrationStatus
  }

  getMigrationStatusForComponent(component: string): string {
    let stateString = ""
    if (this.migrationStatus && this.migrationStatus['migration_state']) {
      const state = this.migrationStatus['migration_state']
      stateString =  state[component]
    }
    return stateString
  }

  getHasMigrationForComponent(component: string): boolean {
    const stateString = this.getMigrationStatusForComponent(component)
    return stateString === 'RUNNING' || stateString === 'PENDING'
  }

  getMigrationStatusTranslatedForComponent(component: string): string {
    const stateString = this.getMigrationStatusForComponent(component)
    return this.getTranslatedMigrationStatus(stateString)
  }

  getMigrationStatusCodeForComponent(component: string): string {
    const stateString = this.getMigrationStatusForComponent(component)
    return this.getMigrationStatusCode(stateString)
  }

  getMigrationStatusCode(status: string): string {
    let statusCode = "info"
    switch (status) {
      case "PENDING":
        statusCode = "warning"
        break
      case "RUNNING":
        statusCode = "info"
        break
      case "DONE":
        statusCode = "success"
        break
      case "FAILED":
        statusCode = "danger"
        break
    }
    return statusCode
  }

  getTranslatedMigrationStatus(status: string): string {
    let translated = this.i18n.$gettext("Status unknown")
    switch (status) {
      case "PENDING":
        translated = this.i18n.$gettext("Pending")
        break
      case "RUNNING":
        translated = this.i18n.$gettext("Running")
        break
      case "DONE":
        translated  =this.i18n.$gettext("Completed")
        break
      case "FAILED":
        translated = this.i18n.$gettext("Failed")
        break
    }
    return translated
  }

  getMigrationComponentIcon(component: string): string {
    switch (component) {
      case "wekan":
        return "cil-task cil-fw"
      case "rocketchat":
        return "cil-chat-square cil-fw"
      case "caldav":
        return "cil-calendar cil-fw"
      case "carddav":
        return "cil-group cil-fw"
      case "imap":
        return "cil-mail cil-fw"
    }
    return "cil-applications cil-fw"
  }

  get migrationsToDo(): { title: string, key: string }[] {

    const availableMigrations = [
      {
        title: this.i18n.$gettext("Tasks"),
        key: "wekan"
      },
      {
        title: this.i18n.$gettext("Chat"),
        key: "rocketchat"
      },
      {
        title: this.i18n.$gettext("Calendars"),
        key: "caldav"
      },
      {
        title: this.i18n.$gettext("Contacts"),
        key: "carddav"
      },
      {
        title: this.i18n.$gettext("All Emails"),
        key: "imap"
      }
    ]

    const actualMigrations = []

    if (this.migrationStatus && this.migrationStatus.migration_state) {
      const options = this.migrationStatus.migration_state
      for (const [key /*, value */] of Object.entries(options)) {
        const optionName = key
        const matchingMigration = availableMigrations.find((mig: { title: string, key: string }) => {
          return mig.key === optionName
        })
        if(matchingMigration){
          actualMigrations.push(matchingMigration)
        }
      }
    }

    return actualMigrations
  }

  get unstartedImapMigrations(): string[] {
    const result = []
    if (this.migrationStatus && this.migrationStatus['imap_state']) {
      const imap_state = this.migrationStatus['imap_state']
      for (const [key, value] of Object.entries(imap_state)) {
        if(value === "PENDING"){
          result.push(key)
        }
      }
    }
    return result
  }

  get unstartedUserList(): string {
    return this.unstartedImapMigrations.join(", ")
  }

  get myMailMigrationStatus(): string {
    let result = ""
    const myUsername = rpcClient.session.user?.userName
    if (this.migrationStatus && this.migrationStatus['imap_state']) {
      const imap_state = this.migrationStatus['imap_state']
      for (const [key, value] of Object.entries(imap_state)) {
        if(key === myUsername){
          result = value as string
        }
      }
    }
    return result
  }

  get myMailMigrationStatusTranslated(): string {
    const status = this.myMailMigrationStatus
    return this.getTranslatedMigrationStatus(status)
  }

  get myMailMigrationStatusCode(): string {
    const status = this.myMailMigrationStatus
    return this.getMigrationStatusCode(status)
  }

  get isAdmin() {
    return rpcClient.session.user?.posixGroupName === 'admin'
  }

  acknowledgedMigrationBanner(): void {
    let settings: UserSettings = rpcClient.session.user?.settings || {}
    settings.acknowledgedMigrationModal = true
    void userServiceApi._saveSettings('acknowledgedMigrationModal', settings)
  }

  unacknowledgedMigrationBanner(): void {
    let settings: UserSettings = rpcClient.session.user?.settings || {}
    settings.acknowledgedMigrationModal = false
    void userServiceApi._saveSettings('acknowledgedMigrationModal', settings)
  }

  mounted() {
    //@ts-ignore
    window["resetMigrationBanner"] = this.unacknowledgedMigrationBanner
  }

}
</script>

<style lang="scss" scoped>

@import "node_modules/elly-bs4/sass/variables";

.dash-widget {
  position: relative;

  .dash-widget-count {
    position: absolute;
    top: -1.5rem;
    right: -1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    //width: 3rem;
    height: 3rem;
    background: $uniki_primary_dark;
    z-index: 10;
    border-radius: 3px;
    line-height: 3rem;
    font-size: 1.8rem;
    text-align: center;
    color: white;

    &:hover {
      cursor: pointer;
      background: $uniki_primary;
    }
  }
}

</style>
