export default class DashboardSettings {
  selectedCalendars!: string[] | null;
  daysToWatchEvents!: number | null;
  myEventsOnly!: boolean | null;
  selectedTaskBoards!: string[] | null;
  daysToWatchTasks!: number | null;
  selectedChannels!: string[] | null;

  constructor() {
    this.selectedCalendars = null;
    this.daysToWatchEvents = null;
    this.myEventsOnly = null;
    this.selectedTaskBoards = null;
    this.daysToWatchTasks = null;
    this.selectedChannels = null;
  }
}
