import { rpcClient } from "@/api/WebsocketClient"
import SWR, { Call } from "@/api/SWR"
import { reactive } from "@vue/reactivity"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import Message from '@/model/entry/Message';
import Page from '@/model/Page';
import Query from '@/model/common/Query';
import { messageStore } from '@/store/MessageStore';

export default class GeneratedMessageServiceApi {

    cache: Map<string, Call<any>> = new Map<string, Call<any>>()

    constructor() {
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.apis.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    clearState() {
        this.cache = new Map<string, Call<any>>()
    }

    get connected(): boolean {
        return rpcClient.state.connected
    }

    _getMessage(id: string): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getMessage', rpcParams, true).then((data: any) => {
            const model = Object.assign(new Message(), data)
            messageStore.addOrReplaceMessage(model)
            return model.id
        })
    }

    _listAutocompleteCommands(teamId: string): Promise<any[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('listAutocompleteCommands', rpcParams, false) as Promise<any[]>
    }

    _executeCommand(teamId: string, channelId: string, command: string): Promise<any> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('executeCommand', rpcParams, false) as Promise<any>
    }

    _createMessage(message: Message, targetUserId: string | null): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('createMessage', rpcParams, false).then((data: any) => {
            const model = Object.assign(new Message(), data)
            messageStore.addOrReplaceMessage(model)
            return model.id
        })
    }

    _forwardMessage(message: Message, channels: string[]): Promise<Message[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('forwardMessage', rpcParams, false).then((data: any) => {
            return data && Array.isArray(data) ? data.map(it => Object.assign(new Message(), it)) : Promise.reject()
        })
    }

    _updateMessage(message: Message): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('updateMessage', rpcParams, false).then((data: any) => {
            const model = Object.assign(new Message(), data)
            messageStore.removeMessage(message.id)
            messageStore.addOrReplaceMessage(model)
            return model.id
        })
    }

    _getPinnedMessages(channelId: string): Promise<Message[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getPinnedMessages', rpcParams, true).then((data: any) => {
            if (data && Array.isArray(data)) {
                const messages: Message[] = data.map(message => Object.assign(new Message(), message))
                messageStore.addOrReplaceMessages(messages)
                return messages
            } else return Promise.reject()
        })
    }


    _patchMessage(id: string, isPinned: boolean | null, text: string | null): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('patchMessage', rpcParams, false).then((data: any) => {
            const model = Object.assign(new Message(), data)
            messageStore.removeMessage(id)
            messageStore.addOrReplaceMessage(model)
            return model.id
        })
    }

    _addReaction(id: string, emojiName: string): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('addReaction', rpcParams, false).then((data: any) => {
            const model = Object.assign(new Message(), data)
            messageStore.addOrReplaceMessage(model)
            return model.id
        })
    }

    _removeReaction(id: string, emojiName: string): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('removeReaction', rpcParams, false).then((data: any) => {
            const model = Object.assign(new Message(), data)
            messageStore.addOrReplaceMessage(model)
            return model.id
        })
    }

    _queryMessages(query: Query, page: number, perPage: number, sortBy: string): Promise<Page<Message>> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('queryMessages', rpcParams, true).then((data: any) => {
            if (data && Array.isArray(data.items)) {
                let messages: Message[] = data.items.map((message: any) => Object.assign(new Message(), message))
                messageStore.addOrReplaceMessages(messages)
                return Object.assign(new Page<Message>(), data)
            } else return Promise.reject()
        })
    }

    _deleteMessage(id: string): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('deleteMessage', rpcParams, false).then((data: any) => {
            const model = Object.assign(new Message(), data)
            messageStore.removeMessage(id)
            messageStore.addOrReplaceMessage(model)
            return model.id
        })
    }

    _getMessageThread(rootId: string): Promise<Message[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getMessageThread', rpcParams, true).then((data: any) => {
            if (data && Array.isArray(data)) {
                const messages: Message[] = data.map(message => Object.assign(new Message(), message))
                messageStore.addOrReplaceMessages(messages)
                return messages
            } else return Promise.reject()
        })
    }


    _getMessagesInChannel(channelId: string, pageIndex: number | null, perPage: number | null, sortBy: string | null): Promise<Page<Message>> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getMessagesInChannel', rpcParams, true).then((data: any) => {
            if (data && Array.isArray(data.items)) {
                let messages: Message[] = data.items.map((message: any) => Object.assign(new Message(), message))
                messageStore.addOrReplaceMessages(messages)
                return Object.assign(new Page<Message>(), data)
            } else return Promise.reject()
        })
    }

    _getFlaggedMessages(page: number | null, perPage: number | null, projectId: string | null, channelId: string | null): Promise<Page<Message>> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getFlaggedMessages', rpcParams, true).then((data: any) => {
            if (data && Array.isArray(data.items)) {
                let messages: Message[] = data.items.map((message: any) => Object.assign(new Message(), message))
                messageStore.addOrReplaceMessages(messages)
                return Object.assign(new Page<Message>(), data)
            } else return Promise.reject()
        })
    }

    _getMessagesSince(channelId: string, since: number): Promise<Message[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getMessagesSince', rpcParams, true).then((data: any) => {
            if (data && Array.isArray(data)) {
                const messages: Message[] = data.map(message => Object.assign(new Message(), message))
                messageStore.addOrReplaceMessages(messages)
                return messages
            } else return Promise.reject()
        })
    }


    _searchMessages(teamId: string, terms: string, isOrSearch: boolean | null): Promise<any> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('searchMessages', rpcParams, false) as Promise<any>
    }

    _getMessagesInChannelAfter(channelId: string, messageId: string): Promise<Message[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getMessagesInChannelAfter', rpcParams, true).then((data: any) => {
            if (data && Array.isArray(data)) {
                const messages: Message[] = data.map(message => Object.assign(new Message(), message))
                messageStore.addOrReplaceMessages(messages)
                return messages
            } else return Promise.reject()
        })
    }


    getPinnedMessages(channelId: string, refresh?: boolean | number, sortBy?: string[] | string): SWR<Message[], string[]> {
        //@ts-ignore
        const result: SWR<Message[], string[]> = reactive(new SWR<Message[], string[]>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 2).filter(arg => arg !== undefined)
        const callId: string = '_getPinnedMessages' + JSON.stringify(args)
        const cached: Call<string[]> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_getPinnedMessages[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: string[]) => {
                const messages: Message[] = []
                for (const id of data) {
                    const message: Message | undefined = messageStore.state.messages.get(id)
                    if (message) {
                        messages.push(message)
                    }
                }
                result.data = messages
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<string[]>()) as Call<string[]>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._getPinnedMessages(channelId).then((data: Message[]) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data
                //@ts-ignore since Array.filter does not provide nullsafe guard
                call.data = data.map(message => message.id || '')
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        let messages: Message[] = [...messageStore.state.messages.values()]
        if (channelId) messages = SortAndFilterUtil.filter(messages, { channelId: channelId })
        SortAndFilterUtil.sort(messages, sortBy)
        
        result.data = messages
        return result
    }

    addReaction(id: string, emojiName: string, refresh?: boolean | number): SWR<Message | null, string> {
        //@ts-ignore
        const result: SWR<Message | null, string> = reactive(new SWR<Message | null, string>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 2).filter(arg => arg !== undefined)
        const callId: string = '_addReaction' + JSON.stringify(args)
        const cached: Call<string> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_addReaction[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((id: string) => {
                result.data = messageStore.state.messages.get(id) || null
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<string>()) as Call<string>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            call.promise = this._addReaction(id, emojiName).then((id: string) => {
                call.data = id
                result.data = messageStore.state.messages.get(id) || null
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        if (cached && cached.data) {
            result.data = messageStore.state.messages.get(cached.data) || null
        }
        return result
    }


    removeReaction(id: string, emojiName: string, refresh?: boolean | number): SWR<Message | null, string> {
        //@ts-ignore
        const result: SWR<Message | null, string> = reactive(new SWR<Message | null, string>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 2).filter(arg => arg !== undefined)
        const callId: string = '_removeReaction' + JSON.stringify(args)
        const cached: Call<string> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_removeReaction[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((id: string) => {
                result.data = messageStore.state.messages.get(id) || null
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<string>()) as Call<string>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            call.promise = this._removeReaction(id, emojiName).then((id: string) => {
                call.data = id
                result.data = messageStore.state.messages.get(id) || null
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        if (cached && cached.data) {
            result.data = messageStore.state.messages.get(cached.data) || null
        }
        return result
    }


    queryMessages(query: Query, page: number, perPage: number, sortBy: string, refresh?: boolean | number): SWR<Message[], Page<string>> {
        //@ts-ignore
        const result: SWR<Message[], Page<string>> = reactive(new SWR<Message[], Page<string>>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 4).filter(arg => arg !== undefined)
        const callId: string = '_queryMessages' + JSON.stringify(args)
        const cached: Call<Page<string>> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_queryMessages[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: Page<string>) => {
                const messages: Message[] = []
                for (const id of data.items || []) {
                    const message: Message | undefined = messageStore.state.messages.get(id)
                    if (message) {
                        messages.push(message)
                    }
                }
                result.data = messages
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<Page<string>>()) as Call<Page<string>>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._queryMessages(query, page, perPage, sortBy).then((data: Page<Message>) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data.items
                call.data = {
                    //@ts-ignore since Array.filter does not provide nullsafe guard
                    items: data.items?.filter(message => !!message.id)?.map(message => message.id) || [],
                    total: data.total,
                    hasMore: data.hasMore,
                    nextId: data.nextId,
                    prevId: data.prevId
                }
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        if (cached && cached.data) {
            const messages: Message[] = []
            for (const id of cached.data.items || []) {
                const message: Message | undefined = messageStore.state.messages.get(id)
                if (message) {
                    messages.push(message)
                }
            }
            result.data = messages
        }
        return result
    }

    getMessageThread(rootId: string, refresh?: boolean | number, sortBy?: string[] | string): SWR<Message[], string[]> {
        //@ts-ignore
        const result: SWR<Message[], string[]> = reactive(new SWR<Message[], string[]>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 2).filter(arg => arg !== undefined)
        const callId: string = '_getMessageThread' + JSON.stringify(args)
        const cached: Call<string[]> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_getMessageThread[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: string[]) => {
                const messages: Message[] = []
                for (const id of data) {
                    const message: Message | undefined = messageStore.state.messages.get(id)
                    if (message) {
                        messages.push(message)
                    }
                }
                result.data = messages
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<string[]>()) as Call<string[]>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._getMessageThread(rootId).then((data: Message[]) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data
                //@ts-ignore since Array.filter does not provide nullsafe guard
                call.data = data.map(message => message.id || '')
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        let messages: Message[] = [...messageStore.state.messages.values()]
        if (rootId) messages = SortAndFilterUtil.filter(messages, { rootId: rootId })
        SortAndFilterUtil.sort(messages, sortBy)
        
        result.data = messages
        return result
    }

    getMessagesInChannel(channelId: string, pageIndex: number | null, perPage: number | null, sortBy: string | null, refresh?: boolean | number): SWR<Message[], Page<string>> {
        //@ts-ignore
        const result: SWR<Message[], Page<string>> = reactive(new SWR<Message[], Page<string>>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 4).filter(arg => arg !== undefined)
        const callId: string = '_getMessagesInChannel' + JSON.stringify(args)
        const cached: Call<Page<string>> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_getMessagesInChannel[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: Page<string>) => {
                const messages: Message[] = []
                for (const id of data.items || []) {
                    const message: Message | undefined = messageStore.state.messages.get(id)
                    if (message) {
                        messages.push(message)
                    }
                }
                result.data = messages
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<Page<string>>()) as Call<Page<string>>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._getMessagesInChannel(channelId, pageIndex, perPage, sortBy).then((data: Page<Message>) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data.items
                call.data = {
                    //@ts-ignore since Array.filter does not provide nullsafe guard
                    items: data.items?.filter(message => !!message.id)?.map(message => message.id) || [],
                    total: data.total,
                    hasMore: data.hasMore,
                    nextId: data.nextId,
                    prevId: data.prevId
                }
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        let messages: Message[] = [...messageStore.state.messages.values()]
        if (channelId) messages = SortAndFilterUtil.filter(messages, { channelId: channelId })
        SortAndFilterUtil.sort(messages, sortBy)
        if (perPage !== null && perPage !== undefined) {
            messages = messages.slice((pageIndex || 0) * perPage, ((pageIndex || 0) + 1) * perPage)
        }
        result.data = messages
        return result
    }

    getMessagesSince(channelId: string, since: number, refresh?: boolean | number): SWR<Message[], string[]> {
        //@ts-ignore
        const result: SWR<Message[], string[]> = reactive(new SWR<Message[], string[]>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 2).filter(arg => arg !== undefined)
        const callId: string = '_getMessagesSince' + JSON.stringify(args)
        const cached: Call<string[]> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_getMessagesSince[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: string[]) => {
                const messages: Message[] = []
                for (const id of data) {
                    const message: Message | undefined = messageStore.state.messages.get(id)
                    if (message) {
                        messages.push(message)
                    }
                }
                result.data = messages
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<string[]>()) as Call<string[]>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._getMessagesSince(channelId, since).then((data: Message[]) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data
                //@ts-ignore since Array.filter does not provide nullsafe guard
                call.data = data.map(message => message.id || '')
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        if (cached && cached.data) {
            const messages: Message[] = []
            for (const id of cached.data) {
                const message: Message | undefined = messageStore.state.messages.get(id)
                if (message) {
                    messages.push(message)
                }
            }
            result.data = messages
        }
        return result
    }

    getMessagesInChannelAfter(channelId: string, messageId: string, refresh?: boolean | number): SWR<Message[], string[]> {
        //@ts-ignore
        const result: SWR<Message[], string[]> = reactive(new SWR<Message[], string[]>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 2).filter(arg => arg !== undefined)
        const callId: string = '_getMessagesInChannelAfter' + JSON.stringify(args)
        const cached: Call<string[]> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_getMessagesInChannelAfter[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: string[]) => {
                const messages: Message[] = []
                for (const id of data) {
                    const message: Message | undefined = messageStore.state.messages.get(id)
                    if (message) {
                        messages.push(message)
                    }
                }
                result.data = messages
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<string[]>()) as Call<string[]>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._getMessagesInChannelAfter(channelId, messageId).then((data: Message[]) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data
                //@ts-ignore since Array.filter does not provide nullsafe guard
                call.data = data.map(message => message.id || '')
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        if (cached && cached.data) {
            const messages: Message[] = []
            for (const id of cached.data) {
                const message: Message | undefined = messageStore.state.messages.get(id)
                if (message) {
                    messages.push(message)
                }
            }
            result.data = messages
        }
        return result
    }

    getMessagesFilterByChannelId(channelId: string, sortBy?: string[] | string, pageIndex?: number, pageSize?: number, refresh?: boolean | number): SWR<Message[], string[]> {
        //@ts-ignore
        const result: SWR<Message[], string[]> = reactive(new SWR<Message[], string[]>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 4).filter(arg => arg !== undefined)
        const callId: string = '_getPinnedMessages' + JSON.stringify(args)
        const cached: Call<string[]> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_getPinnedMessages[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: string[]) => {
                const messages: Message[] = []
                for (const id of data) {
                    const message: Message | undefined = messageStore.state.messages.get(id)
                    if (message) {
                        messages.push(message)
                    }
                }
                result.data = messages
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<string[]>()) as Call<string[]>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._getPinnedMessages(channelId).then((data: Message[]) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data
                //@ts-ignore since Array.filter does not provide nullsafe guard
                call.data = data.map(message => message.id || '')
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        let messages: Message[] = [...messageStore.state.messages.values()]
        messages = SortAndFilterUtil.filter(messages, { channelId: channelId })
        SortAndFilterUtil.sort(messages, sortBy)
        if (pageSize !== null && pageSize !== undefined) {
            messages = messages.slice((pageIndex || 0) * pageSize, ((pageIndex || 0) + 1) * pageSize)
        }
        result.data = messages
        return result
    }

    getMessagesFilterByQueryId(queryId: string, sortBy?: string[] | string, pageIndex?: number, pageSize?: number): Message[] {
        let messages: Message[] = [...messageStore.state.messages.values()]
        messages = SortAndFilterUtil.filter(messages, { queryId: queryId })
        SortAndFilterUtil.sort(messages, sortBy)
        if (pageSize !== null && pageSize !== undefined) {
            messages = messages.slice((pageIndex || 0) * pageSize, ((pageIndex || 0) + 1) * pageSize)
        }
        return messages
    }

    getMessages(sortBy?: string[] | string, pageIndex?: number, pageSize?: number): Message[] {
        let messages: Message[] = [...messageStore.state.messages.values()]
        
        SortAndFilterUtil.sort(messages, sortBy)
        if (pageSize !== null && pageSize !== undefined) {
            messages = messages.slice((pageIndex || 0) * pageSize, ((pageIndex || 0) + 1) * pageSize)
        }
        return messages
    }

    getMessage(id: string, refresh?: boolean | number): SWR<Message | null, string> {
        //@ts-ignore
        const result: SWR<Message | null, string> = reactive(new SWR<Message | null, string>())
        const callId: string = '_getMessage' + JSON.stringify(id)
        const cached: Call<string> | undefined = this.cache.get(callId)
        if (refresh === undefined) {
            refresh = 3000
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((id: string) => {
                result.data = messageStore.state.messages.get(id) || null
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<string>()) as Call<string>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            call.promise = this._getMessage(id).then((id: string) => {
                call.data = id
                result.data = messageStore.state.messages.get(id) || null
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        result.data = messageStore.state.messages.get(id) || null
        return result
    }

}
