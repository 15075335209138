<template>
  <div class="dash-widget d-flex flex-column card card-lite bg-white z-1">
    <div class="dash-widget-count z-2" @click="handleHideClick">
      X
    </div>
    <div class="w-100 px-3 py-3">
      <p class="h5 mb-0">
        <translate>Introduction</translate>
      </p>
    </div>
    <div style="min-height: 22.8rem">
      <iframe
        v-if="videoUrl"
        class="intro-video"
        :src="videoUrl"
        title="Introduction Video"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      />
      <div v-else class="d-flex flex-column justify-content-center" style="text-align: center;">
        <strong><translate>Could not load introduction video</translate></strong>
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import {Options, Vue} from "vue-class-component"
import Constants from "@/util/Constants"
import SettingsUtil from "@/util/SettingsUtil"

@Options({
})
export default class IntroVideoWidget extends Vue {

  videoUrl: string = Constants.introductionVideoUrl

  handleHideClick(){
    SettingsUtil.setIntroVideoSeen()
  }
}
</script>

<style lang="scss" scoped>


@import "node_modules/elly-bs4/sass/variables";

.dash-widget {
  position: relative;


  .dash-widget-count {
    position: absolute;
    top: -1.5rem;
    right: -1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    //width: 3rem;
    height: 3rem;
    background: $uniki_primary_dark;
    z-index: 10;
    border-radius: 3px;
    line-height: 3rem;
    font-size: 1.8rem;
    text-align: center;
    color: white;

    &:hover {
      cursor: pointer;
      background: $uniki_primary;
    }
  }

}

$padding-video: 0.5rem;
.intro-video {
  padding-left: $padding-video;
  padding-right: $padding-video;
  width: 100%;
  height: 22.8rem;
}

</style>
