<template>
  <Dialog
    v-model:visible="visible"
    content-class="compose-dialog-content"
    position="right"
    :style="modalStyle"
    :draggable="false"
    :close-on-escape="false"
    :closable="false"
    @hide="hide"
  >
    <template #header>
      <div class="w-100 d-flex">
        <div class="d-flex flex-wrap flex-shrink-1 flex-grow-1 align-self-start">
          <Button
            class="p-button-raised mr-2"
            icon="cil-send"
            :label="i18n.$gettext('Send')"
            :loading="sendIsLoading"
            @click="send"
          />
          <Button
            class="p-button-raised mr-2"
            icon="cil-save"
            :loading="saveIsLoading"
            @click="save"
          />
          <Button
            v-tooltip="i18n.$gettext('Add attachment')"
            class="p-button-raised mr-2"
            icon="cil-paperclip"
            @click="attachMenu.toggle($event)"
          />
          <Button
            v-tooltip="signatures.length > 0 ? i18n.$gettext('Add or change signature') : i18n.$gettext('No signatures')"
            :style="{ 'opacity': signatures.length > 0 ? '1' : '0.6' }"
            class="p-button-raised mr-2"
            icon="fa fa-file-signature"
            @click="openSignaturesMenu"
          />
          <Button class="p-button-raised mr-2" label="CC" @click="cc = !cc" />
          <Button class="p-button-raised mr-2" label="BCC" @click="bcc = !bcc" />
          <Button class="p-button-raised" icon="cil-settings" @click="emailSettings = !emailSettings" />
        </div>
        <div class="d-flex flex-shrink-0 align-self-end">
          <Button
            icon="cil-x"
            class="p-button-text p-button-secondary"
            @click="confirmClose"
          />
        </div>
      </div>
    </template>
    <div v-if="visible" class="d-flex flex-column w-100 h-100">
      <div class="p-inputgroup">
        <span class="p-float-label">
          <Dropdown
            v-model="fromAddress"
            :options="fromOptions"
            :loading="loadingFrom"
            display="chip"
            class="m-1 w-50"
          />
          <label><slot name="placeholder"><translate>From</translate></slot></label>
        </span>
      </div>
      <div class="p-inputgroup mt-3">
        <span class="p-float-label">
          <AutoComplete
            ref="toInput"
            v-model="draft.to"
            multiple
            class="w-100"
            :suggestions="toSuggestions"
            @complete="filterToSuggestions"
            @keyup.prevent.stop.enter="addToViaEnter"
            @blur.prevent.stop="addToViaEnter"
          />
          <label><slot name="placeholder"><translate>To</translate></slot></label>
        </span>
      </div>
      <div v-if="cc" class="p-inputgroup mt-3">
        <span class="p-float-label">
          <AutoComplete
            ref="ccInput"
            v-model="draft.cc"
            multiple
            class="w-100"
            :suggestions="ccSuggestions"
            @complete="filterCCSuggestions"
            @keyup.prevent.stop.enter="addCCViaEnter"
            @blur.prevent.stop="addCCViaEnter"
          />
          <label><slot name="placeholder"><translate>CC</translate></slot></label>
        </span>
      </div>
      <div v-if="bcc" class="p-inputgroup mt-3">
        <span class="p-float-label">
          <AutoComplete
            ref="bccInput"
            v-model="draft.bcc"
            multiple
            class="w-100"
            :suggestions="bccSuggestions"
            @complete="filterBCCSuggestions"
            @keyup.prevent.stop.enter="addBCCViaEnter"
            @blur.prevent.stop="addBCCViaEnter"
          />
          <label><slot name="placeholder"><translate>BCC</translate></slot></label>
        </span>
      </div>
      <div v-if="emailSettings" class="row w-100 justify-content-between mt-3">
        <div class="col-12 col-md-auto d-flex align-items-center">
          <Dropdown
            v-model="draft.priority"
            :options="priorities"
            :label="i18n.$gettext('Priority')"
            option-label="label"
            option-value="value"
          />
        </div>
        <div class="col-12 col-md-auto d-flex align-items-center">
          <InputSwitch v-model="requestDeliveryReceipt" />
          <label class="ml-1 mt-2"><translate>Delivery Receipt</translate></label>
        </div>
        <div class="col-12 col-md-auto d-flex align-items-center">
          <InputSwitch v-model="requestReadReceipt" />
          <label class="ml-1 mt-2"><translate>Read Receipt</translate></label>
        </div>
      </div>
      <div class="p-inputgroup mt-3 mb-3">
        <span class="p-float-label">
          <InputText v-model="draft.subject" class="w-100" />
          <label><translate>Subject</translate></label>
        </span>
      </div>
      <TokenAttachmentList ref="attachmentcontrol" v-model="attachments" list-style="pb-2" />

      <Editor
        ref="editor"
        v-model="draft.htmlBody"
        :init="{
          height: '100%',
          width: '100%',
          resize: false,
          branding: false,
          menubar: false,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount textcolor', 'contextmenu'
          ],
          toolbar:
            'undo redo | formatselect | bold italic forecolor backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | image | removeformat | help',
          contextmenu: 'link linkchecker conferencelink image imagetools spellchecker table configurepermanentpen copy cut paste',
          file_picker_types: 'image',
          file_picker_callback: handleTinyMceFilePick,
          image_prepend_url: '',
          custom_shortcuts: true,
          setup: setupEditor
        }"
      />
      <Menu ref="signaturesMenu" :model="signaturesMenuItems" :popup="true" />
      <Menu ref="attachMenu" :model="attachMenuItems" :popup="true" />
    </div>
  </Dialog>
</template>

<script lang="ts">
import {Options, Vue} from "vue-class-component"
import AnimatedInput from "../common/AnimatedInput.vue"
import Editor from "@tinymce/tinymce-vue"
import Email from "../../model/entry/Email"
import LoadingButton from "@/components/common/LoadingButton.vue"
import {mailServiceApi} from "@/api/MailServiceApi"
import {reactive, ref} from "@vue/reactivity"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import Dialog from "primevue/dialog"
import Button from "primevue/button"
import InputText from "primevue/inputtext"
import TokenAttachmentList from "@/components/common/TokenAttachmentList.vue"
import {rpcClient} from "@/api/WebsocketClient"
import AutoComplete from "primevue/autocomplete"
import RpcError from "@/api/RpcError"
import useToast from "@/util/toasts"
import EmailUtil from "@/util/EmailUtil"
import Menu from "primevue/menu"
import Signature from "@/model/settings/Signature"
import {useConfirm} from "primevue/useconfirm"
import breakpointUtil from "@/util/BreakpointUtil"
import SWR from "@/api/SWR"
import FromAddress from "@/model/settings/FromAddress"
import {fromAddressesApi} from "@/api/FromAddressesApi"
import Dropdown from "primevue/dropdown"
import featureSubset from "@/util/FeatureSubsets"
import INode from "@/model/entry/INode"
import {toAddressesApi} from "@/api/ToAddressesApi"
import ToAddress from "@/model/settings/ToAddress"
import InputSwitch from "primevue/inputswitch"

@Options({
  components: {
    //@ts-ignore
    AnimatedInput, LoadingButton, Dialog, Editor, Button, InputText, TokenAttachmentList, AutoComplete, Menu, Dropdown, InputSwitch
  }
})
export default class EmailComposer extends Vue {

  i18n: Language = useGettext()
  toast = useToast()
  confirm = useConfirm()

  visible = false
  cc = false
  bcc = false
  emailSettings = false
  requestDeliveryReceipt = false
  requestReadReceipt = false
  folderId: string | null = null
  draft: Email | null = null
  draftId: string | null = null
  replyToMessageId: string | null = null
  appendMessageId: string | null = null

  fromAddress: string = ''

  sendIsLoading = false
  saveIsLoading = false

  editor: any

  //@ts-ignore
  fromInput: AutoComplete = ref<AutoComplete | null>(null)
  //@ts-ignore
  toInput: AutoComplete = ref<AutoComplete | null>(null)
  //@ts-ignore
  ccInput: AutoComplete = ref<AutoComplete | null>(null)
  //@ts-ignore
  bccInput: AutoComplete = ref<AutoComplete | null>(null)
  //@ts-ignore
  signaturesMenu: Menu = ref(null)
  //@ts-ignore
  attachMenu: Menu = ref(null)
  //@ts-ignore
  attachmentcontrol: TokenAttachmentList = ref<TokenAttachmentList | null>(null)
  attachments: {name: string, size: string, handle: string, loading: boolean, progress: number}[] = []

  knownEmailAddresses: SWR<ToAddress[], string[]> | null = null
  fromSuggestions: string[] = []
  toSuggestions: string[] = []
  ccSuggestions: string[] = []
  bccSuggestions: string[] = []
  signaturesMenuItems: any[] = []
  currentSignature: string = ''

  attachMenuItems = [
    {
      label: this.i18n.$gettext('Upload from your computer'),
      icon: 'cil-data-transfer-up',
      command: () => {
        this.attachmentcontrol.openNativeFileChooser()
      }
    },
    {
      label: this.i18n.$gettext('Choose from files'),
      icon: 'cil-inbox-out',
      command: () => {
        this.attachmentcontrol.openInodeChooser()
      }
    },
  ]

  priorities: {label: string, value: number}[] = [
    {label: this.i18n.$gettext('Highest'), value: 1},
    {label: this.i18n.$gettext('High'),    value: 2},
    {label: this.i18n.$gettext('Normal'),  value: 3},
    {label: this.i18n.$gettext('Low'),     value: 4},
    {label: this.i18n.$gettext('Lowest'),  value: 5},
  ]

  get signatures(): Signature[] {
    return rpcClient.session.user?.settings?.signatures || []
  }

  get defaultSignature(): Signature | null {
    return this.signatures.find(s => s.isDefault) || null
  }

  show(email: Email, draftId: string | null, folderId: string | null, replyToMessageId: string | null, appendMessageId: string | null, inode?: INode) {
    this.folderId = folderId
    this.fromAddress = ''
    if (email.from && email.from.length > 0) {
      this.fromAddress = email.from[0]
    }
    if (!this.fromAddress && rpcClient.session.user?.email) {
      this.fromAddress = rpcClient.session.user.email
    }
    email.from = [ this.fromAddress ]
    if (!email.to) {
      email.to = []
    }
    if (!email.cc) {
      email.cc = []
    }
    if (!email.bcc) {
      email.bcc = []
    }
    email.to = this.removeDoubles(email.to)
    email.cc = this.removeDoubles(email.cc)
    email.bcc = this.removeDoubles(email.bcc)
    this.cc =  email.cc ?.length > 0
    this.bcc = email.bcc?.length > 0
    this.emailSettings = false
    if (email.priority) {
      if (email.priority > 0 && email.priority < 5) this.emailSettings = true
      else email.priority = 3 //default prio
    } else {
      email.priority = 3
    }
    this.requestDeliveryReceipt = !!(email.requestDeliveryReceipt && email.requestDeliveryReceipt.length > 0)
    this.requestReadReceipt = !!(email.requestReadReceipt && email.requestReadReceipt.length > 0)
    this.emailSettings = this.emailSettings || this.requestDeliveryReceipt || this.requestReadReceipt

    if (this.defaultSignature) {
      this.currentSignature = this.defaultSignature.html || ''
      if (email.htmlBody) {
        email.htmlBody = email.htmlBody.replace('</body></html>', this.defaultSignature.html + '</body></html>')
      } else {
        email.htmlBody = '<html><body><br>' + this.defaultSignature.html + '</body></html>'
      }
    }
    this.draft = email
    this.draftId = draftId
    this.replyToMessageId = replyToMessageId
    this.appendMessageId = appendMessageId
    this.visible = true
    if (inode) {
      this.$nextTick(() => {
        this.attachmentcontrol.createAttachmendFromInode(inode)
      })
    }
    if (email.attachments) {
      for (let f of email.attachments) {
        this.attachments.push(
          reactive({
            name: f,
            size: "",
            handle: f,
            loading: false,
            progress: 100
          })
        )
      }
    }
  }

  removeDoubles(list: string[]): string[] {
    const listAsSet: Set<string> = new Set(list)
    return Array.from(listAsSet.values())
  }

  hide() {
    this.attachments = []
    this.visible = false
  }

  handleTinyMceFilePick(callback: any, value: any, meta: any){

    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.setAttribute('multiple', 'multiple')

    /*
      Note: In modern browsers input[type="file"] is functional without
      even adding it to the DOM, but that might not be the case in some older
      or quirky browsers like IE, so you might want to add it to the DOM
      just in case, and visually hide it. And do not forget do remove it
      once you do not need it anymore.
    */

    input.onchange = () => {
      if (input && input.files) {
        for (let f of input.files) {
          let data = new FormData()
          data.append("file", f)

          const client = rpcClient.getAjaxClient()
          this.toast.info(this.i18n.$gettext("Uploading your image now"))
          client.post('uploads', data).then((res) => {
            const url: string = res.data.uri
            const name: string = res.data.name
            callback(url, {title: name})
          }).catch(() => {
            this.toast.error(this.i18n.$gettext("Image could not be uploaded"))
          })
        }
      }
    }

    input.click()
  }

  confirmClose() {
    if (this.draft?.htmlBody) {
      this.confirm.require({
        message: this.i18n.$gettext('Do you want to save the message as a draft?'),
        header: this.i18n.$gettext('Save & Close'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.save().then(() => {
            this.hide()
          }).catch((e: RpcError) => {
            this.toast.error(e.message, this.i18n.$gettext("Could not save draft"))
          })
        },
        reject: () => {
          this.hide()
        }
      })
    } else {
      this.hide()
    }
  }

  send(): Promise<any> {
    let hasIncompleteUploads: boolean = this.attachmentcontrol.checkForIncompleteUploads()
    if(hasIncompleteUploads){
      this.toast.error(this.i18n.$gettext("You cannot send the message while attachments are still uploading"))
      return Promise.reject()
    }
    if (this.draft) {
      if (this.fromAddress) {
        this.draft.from = [ this.fromAddress ]
        if (this.requestDeliveryReceipt) this.draft.requestDeliveryReceipt = this.fromAddress
        if (this.requestReadReceipt) this.draft.requestReadReceipt = this.fromAddress
      } else {
        this.draft.requestDeliveryReceipt = null
        this.draft.requestReadReceipt = null
      }
      //@ts-ignore
      this.draft.textBody = this.editor.getContent({format: 'text'})
      this.draft.fileTokens = this.attachmentcontrol.getFileTokens()
      this.sendIsLoading = true
      return mailServiceApi._sendMail(this.draft, this.draftId, this.replyToMessageId, this.appendMessageId).then(() => {
        this.visible = false
        this.attachments = []
      }).finally( () => { this.sendIsLoading = false})
    } else {
      return Promise.reject()
    }
  }

  save(): Promise<any> {
    if (this.draft) {
      this.saveIsLoading = true
      if (this.draftId) {
        return mailServiceApi._updateMail(this.draftId, this.draft).then((newDraftId: string) => {
          this.draftId = newDraftId
        }).catch((e: RpcError) => {
          this.toast.error(e.message, this.i18n.$gettext("Saving draft failed."))
        }).finally(() => {
          this.saveIsLoading = false
        })
      } else {
        return mailServiceApi._createDraftMail(this.draft).then((draftId: string) => {
          this.draftId = draftId
        }).catch((e: RpcError) => {
          this.toast.error(e.message, this.i18n.$gettext("Saving draft failed."))
        }).finally(() => {
          this.saveIsLoading = false
        })
      }
    } else {
      return Promise.reject()
    }
  }

  openSignaturesMenu(e: Event) {
    if (this.signatures.length > 0) {
      this.signaturesMenuItems = this.signatures.map(s => {
        return {
          label: s.name,
          icon: 'fa fa-file-signature',
          command: () => {
            this.changeSignature(s.html || '')
          }
        }
      })
      void this.$nextTick(() => {
        this.signaturesMenu.show(e)
      })
    }
  }

  changeSignature(html: string) {
    if (this.draft?.htmlBody) {
      if (this.currentSignature && this.draft.htmlBody.includes(this.currentSignature)) {
        this.draft.htmlBody = this.draft.htmlBody.replace(this.currentSignature, html)
      } else if (this.draft.htmlBody.includes('</body>')) {
        this.draft.htmlBody = this.draft.htmlBody.replace('</body>', html + '</body>')
      } else if (this.draft.htmlBody.includes('</html>')) {
        this.draft.htmlBody = this.draft.htmlBody.replace('</html>', html + '</html>')
      } else {
        this.draft.htmlBody += html
      }
      this.currentSignature = html
    } else if (this.draft) {
      this.draft.htmlBody = '<html><body><br>' + html + '</body></html>'
    }
  }

  addFromViaEnter() {
    if (this.draft) {
      //@ts-ignore
      const input = this.fromInput.$refs?.input?.value
      if (!this.draft?.from) {
        this.draft.from = []
      }
      if (EmailUtil.isValidEmail(input) && this.draft.from.indexOf(input) < 0) {
        this.draft.from.push(input)
        //@ts-ignore
        this.fromInput.$refs.input.value = ''
      }
    }
  }

  addToViaEnter() {
    if (this.draft) {
      //@ts-ignore
      const input = this.toInput.$refs?.input?.value
      if (!this.draft?.to) {
        this.draft.to = []
      }
      if (EmailUtil.isValidEmail(input) && this.draft.to.indexOf(input) < 0) {
        this.draft.to.push(input)
        //@ts-ignore
        this.toInput.$refs.input.value = ''
      }
    }
  }

  addCCViaEnter() {
    if (this.draft) {
      //@ts-ignore
      const input = this.ccInput.$refs?.input?.value
      if (!this.draft?.cc) {
        this.draft.cc = []
      }
      if (EmailUtil.isValidEmail(input) && this.draft.cc.indexOf(input) < 0) {
        this.draft.cc.push(input)
        //@ts-ignore
        this.ccInput.$refs.input.value = ''
      }
    }
  }

  addBCCViaEnter() {
    if (this.draft) {
      //@ts-ignore
      const input = this.bccInput.$refs?.input?.value
      if (!this.draft?.bcc) {
        this.draft.bcc = []
      }
      if (EmailUtil.isValidEmail(input) && this.draft.bcc.indexOf(input) < 0) {
        this.draft.bcc.push(input)
        //@ts-ignore
        this.bccInput.$refs.input.value = ''
      }
    }
  }

  addVideoConference() {
    if (featureSubset.conferenceUrl) {
      let uri = featureSubset.conferenceUrl
      if (!uri.endsWith('/')) {
        uri += '/'
      }
      for (let i = 0; i < 3; i++) {
        uri += Math.random().toString(36).substr(2)
      }
      const html = '<p>Meeting-Link: <a href="' + uri + '"></a>' + uri + '</p>'

      //@ts-ignore
      this.editor.execCommand('mceInsertContent', false, html)
    }
  }

  setupEditor(editor: any) {
    this.editor = editor
    //@ts-ignore
    editor.ui.registry.addIcon('video', '<i class="cil cil-video"></i>')
    //@ts-ignore
    editor.ui.registry.addMenuItem('conferencelink', {
      type: 'item',
      text: this.i18n.$gettext('Conference Link'),
      icon: 'video',
      shortcut: 'meta+shift+C',
      disabled: !featureSubset.conferenceUrl,
      onAction: this.addVideoConference
    })
    //@ts-ignore
    editor.addShortcut('meta+shift+C', this.i18n.$gettext('Conference Link'), this.addVideoConference)
  }

  get fromOptions(): string[] {
    let options: string[] = []
    this.allowedFromAddresses?.forEach((address: FromAddress) => {
      if (address.name) {
        options.push(address.name)
      }
    })
    if (rpcClient.session.user?.email) {
      options.push(rpcClient.session.user.email)
    }
    return options
  }

  get loadingFrom(): boolean {
    return Boolean(this.fromAddressesSWR?.call?.loading)
  }

  fromAddressesSWR!: SWR<FromAddress[], string[]>
  get allowedFromAddresses(): FromAddress[] {
    this.fromAddressesSWR = fromAddressesApi.getAllowedFromAddresses()
    return this.fromAddressesSWR.data || rpcClient.session.user?.settings?.mailFilters || []
  }

  filterToSuggestions(event: any) {
    const lowerQuery = event.query.toLowerCase()
    this.toSuggestions = (this.knownEmailAddresses?.data || []).map(t => t.id || t.address?.address || '')
      .filter(address => address.toLowerCase().includes(lowerQuery)).sort((a1: string, a2: string) => {
        let index1: number = a1.toLowerCase().indexOf(lowerQuery)
        let index2: number = a2.toLowerCase().indexOf(lowerQuery)
        return index1 - index2
      })
  }

  filterCCSuggestions(event: any) {
    const lowerQuery = event.query.toLowerCase()
    this.ccSuggestions = (this.knownEmailAddresses?.data || []).map(t => t.id || t.address?.address || '')
      .filter(address => address.toLowerCase().includes(lowerQuery)).sort((a1: string, a2: string) => {
        let index1: number = a1.toLowerCase().indexOf(lowerQuery)
        let index2: number = a2.toLowerCase().indexOf(lowerQuery)
        return index1 - index2
      })
  }

  filterBCCSuggestions(event: any) {
    const lowerQuery = event.query.toLowerCase()
    this.bccSuggestions = (this.knownEmailAddresses?.data || []).map(t => t.id || t.address?.address || '')
      .filter(address => address.toLowerCase().includes(lowerQuery)).sort((a1: string, a2: string) => {
        let index1: number = a1.toLowerCase().indexOf(lowerQuery)
        let index2: number = a2.toLowerCase().indexOf(lowerQuery)
        return index1 - index2
      })
  }

  get modalStyle(){
    if(breakpointUtil.isOnMobile()){
      return { width: "100%", margin: "0", height: "100% !important", maxHeight: "100%" }
    } else {
      return { minWidth: "50%", margin: "1rem", height: "100%" }
    }
  }

  mounted() {
    this.knownEmailAddresses = toAddressesApi.getToAddresses()
  }
}
</script>

<style scoped lang="scss">

.compose-dialog-content .tox-edit-area__iframe {
  background: white !important;
}

.btn-group > button {
  margin-top: 0.5rem;
}
</style>
