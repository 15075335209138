

import {Options, Vue} from "vue-class-component"
import SettingsListItem from "@/components/settings/SettingsListItem.vue"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import Button from "primevue/button"
import InputText from "primevue/inputtext"
import Dialog from "primevue/dialog"
import Editor from "@tinymce/tinymce-vue"
import SignatureConfig from "@/model/settings/SignatureConfig"
import Skeleton from "primevue/skeleton"
import {globalSignatureServiceApi} from "@/api/GlobalSignatureServiceApi"
import useToast from "@/util/toasts"

@Options({
  components: {
    SettingsListItem, Button, Editor, InputText, Dialog, Skeleton
  },
  //@ts-ignore
  props: {},
  emits: []
})
export default class GlobalSignatureSettings extends Vue {

  toast = useToast()
  i18n: Language = useGettext()

  signatures: SignatureConfig[] | null = null
  newSignature: SignatureConfig | null = null
  editedSignature: SignatureConfig | null = null

  loadingSave: boolean = false
  settingsLoading:  boolean = false

  mounted() {
    this.settingsLoading = true
    void globalSignatureServiceApi._getGlobalSignatures().then((signatures: SignatureConfig[]) => {
      this.signatures = signatures
    }).catch(() => {
      this.toast.error(this.i18n.$gettext("Error while loading signatures"))
    }).finally(() => {
      this.settingsLoading = false
    })
  }

  get showEditModal(): boolean {
    return !!this.editedSignature
  }

  set showEditModal(show: boolean) {
    if (!show) {
      this.editedSignature = null
    }
  }

  get showNewModal(): boolean {
    return !!this.newSignature
  }

  set showNewModal(show: boolean) {
    if (!show) {
      this.newSignature = null
    }
  }

  createSignature() {
    this.newSignature = { name: '', textSignature: '', htmlSignature: '<p>---<br/><br/></p>', toRegex: '', fromRegex: '', notToRegex: '' }
    this.editedSignature = null
  }

  editSignature(signature: SignatureConfig) {
    this.editedSignature = signature
    this.newSignature = null
  }

  removeSignature(signature: SignatureConfig) {
    if (this.signatures) {
      const existing: number | undefined = this.signatures.indexOf(signature)
      if (existing >= 0) {
        this.signatures.splice(existing, 1)
      }
      void this.saveSignatures()
    }
  }

  saveSignature() {
    const editedSignature = this.editedSignature || this.newSignature
    if (this.signatures && editedSignature) {
      //@ts-ignore
      editedSignature.textSignature = window.tinyMCE.editors[0].getContent({format: 'text'})
      //@ts-ignore
      editedSignature.htmlSignature = window.tinyMCE.editors[0].getContent({format: 'html'})
      const existing: number = this.signatures.indexOf(editedSignature)
      if (existing >= 0) {
        this.signatures[existing] = editedSignature
      } else {
        this.signatures.push(editedSignature)
      }
      void this.saveSignatures()
    }
  }

  saveSignatures(): Promise<any> {
    if (this.signatures) {
      this.loadingSave = true
      return globalSignatureServiceApi._setGlobalSignatures(this.signatures).then(() => {
        this.newSignature = null
        this.editedSignature = null
        this.toast.success(this.i18n.$gettext("Signatures saved."))
      }).catch(() => {
        this.toast.error(this.i18n.$gettext("Error while saving signatures"))
      }).finally(() => {
        this.loadingSave = false
      })
    } else {
      return Promise.reject()
    }
  }

  dataImageFilter(img: any) {
    return img.hasAttribute('internal-blob')
  }

  handleTinyMceFilePick(callback: any, value: any, meta: any) {

    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.setAttribute('multiple', 'multiple')
    /*
      Note: In modern browsers input[type="file"] is functional without
      even adding it to the DOM, but that might not be the case in some older
      or quirky browsers like IE, so you might want to add it to the DOM
      just in case, and visually hide it. And do not forget do remove it
      once you do not need it anymore.
    */

    input.onchange = () => {
      if (input && input.files) {
        //check file size limit. only up to 200kB allowed
        let filesize = 0 //in kB
        for (let f of input.files) {
          filesize += f.size // kB
          if (filesize > 200000) {
            this.toast.error(this.i18n.$gettext("File size exceeds limit of 200kB"))
            return
          }
        }

        for (let f of input.files) {
          let reader = new FileReader()
          reader.onload = (e) => {
            if (e?.target?.result) {
              callback(e.target.result, {alt: ''})
            }
          }
          reader.readAsDataURL(f)
        }
      }
    }

    input.click()
  }
}
