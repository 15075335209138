<template>
  <div class="d-flex flex-column justify-content-between" :class="{ collapse: true }">
    <ul class="nav nav-pills flex-column mb-auto">
      <li v-for="link in links" :key="link.href" class="nav-item">
        <router-link
          v-tooltip="link.text"
          :to="link.href"
          class="nav-link"
          :class="{ 'router-link-active': currentRouteStartsWith(link.href) }"
          aria-current="page"
        >
          <i :class="link.icon" style="font-size: 1.4rem" /><span class="nav-link-text">&emsp;{{ link.text }}</span>
          <Badge v-if="link.notificationCount" style="margin-top: -1rem">
            {{ link.notificationCount }}
          </Badge>
        </router-link>
      </li>
    </ul>
    <div class="d-flex flex-column justify-content-end">
      <!--<router-link
          v-tooltip="i18n.$gettext('Manuals')"
          to="/manual"
          class="nav-link"
          aria-current="page"
      >
        <i class="cil-info-circle" style="font-size: 1.4rem" /><span class="nav-link-text">&emsp;{{ i18n.$gettext('Manuals') }}</span>
      </router-link>-->
      <ul class="nav nav-pills flex-column mb-auto">
        <li class="nav-item">
          <a
            v-if="integrateAdminpanel"
            v-tooltip="i18n.$gettext('Help')"
            class="nav-link"
            :class="{'help-link-flash': !hasSeenHelpCenter}"
            style="cursor: pointer"
            @click.prevent.stop="showHelpOverlay"
          >
            <i class="cil-lightbulb" style="font-size: 1.6rem" /><span class="nav-link-text">&emsp;{{ i18n.$gettext('Help') }}</span>
          </a>
        </li>
        <li class="nav-item">
          <router-link
            v-tooltip="i18n.$gettext('Notifications')"
            to="/messages"
            class="nav-link"
            aria-current="page"
          >
            <i class="cil-bell" style="font-size: 1.4rem" /><span class="nav-link-text">&emsp;{{ i18n.$gettext('Notifications') }}</span>
            <Badge v-if="notificationCount">
              {{ notificationCount }}
            </Badge>
          </router-link>
        </li>
        <li class="nav-item">
          <a
            v-if="integrateAdminpanel"
            v-tooltip="i18n.$gettext('Settings')"
            class="nav-link"
            style="cursor: pointer"
            @click="openSettingsMenu"
          >
            <i class="cil-settings" style="font-size: 1.6rem" /><span class="nav-link-text">&emsp;{{ i18n.$gettext('Settings') }}</span>
          </a>
        </li>
      </ul>
      <!--<router-link
          v-tooltip="i18n.$gettext('Customer Center')"
          to="/notifications"
          class="nav-link"
          aria-current="page"
      >
        <i class="cil-credit-card" style="font-size: 1.4rem" /><span class="nav-link-text">&emsp;{{ i18n.$gettext('Customer Center') }}</span>
      </router-link>-->

      <Avatar
        id="globalavatar"
        class="profile-avatar pt-1"
        :username="userName"
        :label="userDisplayName"
        :size="48"
        generate-initials
        @click="openUserMenu"
      />
      <TieredMenu ref="menu" :model="menuItems" popup />
    </div>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component'
import Avatar from "@/components/common/Avatar.vue"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import {CachedImage, imageLoadingService} from "@/util/ImageLoadingService"
import {rpcClient} from '@/api/WebsocketClient'
import {userServiceApi} from "@/api/UserServiceApi"
import ContextMenu from "primevue/contextmenu"
import { ref } from "@vue/reactivity"
import {Router, useRouter} from "vue-router"
import AdminpanelHolder from "@/util/AdminpanelHolder"
import Badge from 'primevue/badge'
import OverlayPanel from "primevue/overlaypanel"
import TieredMenu from "primevue/tieredmenu"
import SettingsUtil from "@/util/SettingsUtil"

@Options({
  components: {
    //@ts-ignore
    Avatar, ContextMenu, Badge, OverlayPanel, TieredMenu
  },
  //@ts-ignore
  props: {
    links: Array,
    collapse: Boolean,
    integrateAdminpanel: Boolean
  },
  emits: ['showHelpOverlay']
})
export default class SideBar extends Vue {

  i18n: Language = useGettext()
  router: Router = useRouter()
  rpcClient = rpcClient

  links!: any[]
  collapse!: boolean
  integrateAdminpanel!: boolean
  portalURL: any | undefined

  //@ts-ignore
  menu: TieredMenu = ref<TieredMenu | null>(null);

  menuItems: any[] = []
  notificationCount: number = 0

  helpOverlayIsVisible: boolean = false

  get userName(): string | null {
    return rpcClient.session?.user?.userName || null
  }

  get userDisplayName(): string | null {
    return rpcClient.session?.user?.displayName || null
  }

  get profilePicture(): CachedImage | null {
    if (this.userName) {
      return imageLoadingService.getCachedImage(`/groupware-api/v1/users/${this.userName}/picture`)
    } else {
      return null
    }
  }

  get isAdmin(): boolean {
    return rpcClient.session?.user?.posixGroupName === 'admin'
  }

  get isManager(): boolean {
    return !!rpcClient?.session?.user?.memberOf?.find((groupDn) => {
      return groupDn.startsWith('cn=signing,')
    })
  }

  openSettingsMenu(e: Event) {
    this.updateSettingsMenuIcons()
    void this.$nextTick(() => {
      this.menu.show(e)
    })
  }

  openUserMenu(e: Event) {
    this.updateUserMenuIcons()
    void this.$nextTick(() => {
      this.menu.show(e)
    })
  }

  updateSettingsMenuIcons() {
    this.menuItems = [
      {
        label: this.i18n.$gettext("Manuals"), icon: 'cil-info-circle', command:() => {
          void this.router.push('/manual')
        }
      },
      {
        label: this.i18n.$gettext("User Preferences"), icon: 'cil-user', command:() => {
          void this.router.push('/settings')
        }
      }
    ]

    if (this.isAdmin) {
      this.menuItems.push({
        label: this.i18n.$gettext("System Settings"), icon: 'cil-settings-alt', command:() => {
          void this.router.push('/settings/system')
        }
      })
    }

    if (this.isManager) {
      this.menuItems.push({
        label: this.i18n.$gettext("Customer Center"), icon: 'cil-credit-card', command:() => {
          this.redirectToCustomerCenter()
        }
      })
    }
  }

  updateUserMenuIcons() {
    this.menuItems = [
      {
        label: this.i18n.$gettext("Manuals"), icon: 'cil-info-circle', command:() => {
          void this.router.push('/manual')
        }
      },
      {
        label: this.i18n.$gettext("My Status"), icon: 'cil-mood-good', items: [
          {
            label: 'Online',
            command:() => {
              void userServiceApi._updateStatus('ONLINE')
            }
          },
          {
            label: 'Away',
            command:() => {
              void userServiceApi._updateStatus('AWAY')
            }
          },
          {
            label: 'DND',
            command:() => {
              void userServiceApi._updateStatus('DND')
            }
          },
          {
            label: 'Offline',
            command:() => {
              void userServiceApi._updateStatus('OFFLINE')
            }
          }
        ]
      },
      {
        label: this.i18n.$gettext("My Account"), icon: 'cil-user-secret', command:() => {
          void this.router.push('/account')
        }
      },
      {
        label: this.i18n.$gettext("User Preferences"), icon: 'cil-user', command:() => {
          void this.router.push('/settings')
        }
      }
    ]

    if (this.isManager) {
      this.menuItems.push({
        label: this.i18n.$gettext("Customer Center"), icon: 'cil-credit-card', command:() => {
          this.redirectToCustomerCenter()
        }
      })
    }

    this.menuItems.push({
      label: this.i18n.$gettext("Logout"), icon: 'cil-account-logout', command:() => {
        rpcClient.logout()
        void this.router.push('/')
      }
    })
  }

  redirectToCustomerCenter() {
    this.portalURL = this.portalURL || AdminpanelHolder.instance?.store?.getters?.getInvoiceContact?.portalURL
    if (this.portalURL) {
      window.open(this.portalURL, '_blank')
    } else {
      window.open('https://portal.uniki.de', '_blank')
    }
  }

  currentRouteStartsWith(h: string): boolean {
    const path = this.$router.currentRoute?.value?.path || this.$router.currentRoute?.path
    if(h === "/"){
      return path === h
    } else {
      return path?.startsWith(h)
    }
  }

  showHelpOverlay(){
    SettingsUtil.setHelpCenterSeen()
    this.$emit('showHelpOverlay')
  }

  get hasSeenHelpCenter(){
    return SettingsUtil.getHelpCenterSeen()
  }

  mounted() {
    //Preload users here because they are needed in multiple places
    userServiceApi.getUsers()
    AdminpanelHolder.watchers.push((state: any) => {
      this.notificationCount = state.message?.notifications?.length || 0
      this.portalURL = AdminpanelHolder.instance?.store?.getters?.getInvoiceContact?.portalURL || this.portalURL
    })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "node_modules/elly-bs4/sass/variables.scss";
@import "node_modules/elly-bs4/elly.scss";

.nav-item {
  width: 100%;
}

.nav-link {
  white-space: nowrap;
  display: flex;
  align-items: center;
  margin-bottom: 1rem
}

.nav-link i.fa {
  width: 1rem;
}

.nav-link .nav-link-text {
  white-space: nowrap;
  overflow: hidden;
  visibility: visible;
  opacity: 1;
  transition: opacity .5s ease-in-out;
}

.nav-link .nav-link-text, .nav-link i {
  color: white;
}

.nav-link:hover .nav-link-text, .nav-link:hover i {
  color: $uniki_primary;
}

.nav-link.router-link-active {
  background-color: $uniki_primary;
}

.nav-link.router-link-active .nav-link-text, .nav-link.router-link-active i {
  color: white;
}

.collapse .nav-link .nav-link-text {
  max-width: 4rem;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s .5s, opacity .5s ease-in-out;
}

.profile-avatar:hover {
  border-radius: 3px;
  background-color: $uniki_primary;
  cursor: pointer;
}

.help-link-flash {
  animation-name: glow-help;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-delay: 1s; // Give the page some time to load

  &:hover {
    animation: none;
  }
}

@keyframes glow-help {
  30% {
    background-color: transparent;
  }
  40% {
   background-color: $uniki_primary;
  }
}

</style>
