

import {Options, Vue} from "vue-class-component"
import {addressBookServiceApi} from "@/api/AddressBookServiceApi"
import AddressBook from "@/model/directory/AddressBook"
import AnimatedInput from "@/components/common/AnimatedInput.vue"
import ContactsList from "@/components/contacts/ContactsList.vue"
import { ref } from "@vue/reactivity"
import Menu from "primevue/menu"
import Dialog from "primevue/dialog"
import {useGettext} from "@jshmrtn/vue3-gettext"
import InputText from "primevue/inputtext"
import Button from "primevue/button"
import RpcError from "@/api/RpcError"
import Contact from "@/model/entry/Contact"
import StructuredName from "@/model/common/carddav/StructuredName"
import TextValue from "@/model/common/carddav/TextValue"
import {contactServiceApi} from "@/api/ContactServiceApi"
import ProgressBar from "primevue/progressbar"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import useToast from "@/util/toasts"
import Skeleton from "primevue/skeleton"
import SettingsUtil from "@/util/SettingsUtil"
import {useConfirm} from "primevue/useconfirm"
import ContextMenu from "primevue/contextmenu"
import DavSharingDialog from "@/components/common/DavSharingDialog.vue"
import featureSubset from "@/util/FeatureSubsets"
import breakpointUtil from "@/util/BreakpointUtil"
import InnerLayoutWithSidebar from "@/components/common/InnerLayoutWithSidebar.vue"
import SearchBar from "@/components/common/SearchBar.vue"
import Query from "@/model/common/Query"

@Options({
  components: {
    InnerLayoutWithSidebar, ContactsList, AnimatedInput, Menu, Dialog, InputText, Button, ProgressBar,
    Skeleton, DavSharingDialog, ContextMenu, SearchBar
  },
  //@ts-ignore
  props: {},
  emits: []
})
export default class ContactsView extends Vue {

  addressbookApi = addressBookServiceApi
  i18n = useGettext()
  toast = useToast()
  confirm = useConfirm()

  menuItems: any[] = []
  addressBooksAreLoading: boolean = true

  showCreateBookModal = false
  newBook: AddressBook = new AddressBook()
  createBookLoading = false

  addressBookToShare: AddressBook | null = null
  addressBookToRename: AddressBook | null = null
  renameLoading: boolean = false

  showCreateContactModal = false
  newContact: Contact = new Contact()
  createContactLoading = false

  //@ts-ignore
  contextMenu: ContextMenu = ref<ContextMenu | null>(null)
  //@ts-ignore
  menu: Menu = ref<Menu | null>(null)

  openMobileMenuDefault: boolean = false

  searchQuery: Query | null = null

  openNewAddressbookModal(): void {
    this.newBook = new AddressBook()
    this.showCreateBookModal = true
  }

  openNewContactModal(): void {
    this.setupNewContact()
    this.showCreateContactModal = true
  }

  get selectedAddressBook() {
    return (this.$route.params["book"] as string) || ""
  }

  get addressbooks(): AddressBook[] {
    let swr = this.addressbookApi.getAddressBooks()
    this.addressBooksAreLoading = Boolean(swr.call?.loading && swr.call?.promise)
    if (swr.call?.promise) {
      swr.call.promise.finally(() => { this.addressBooksAreLoading  = false })
    }
    const collections = swr.data ? [...swr.data] : []
    const orderedCollections: AddressBook[] = []
    const order: string[] | null | undefined = SettingsUtil.getLastViewedCollectionOrder('addressBook')
    if (order) {
      for (let id of order) {
        const collectionIndex: number = collections.findIndex(b => b.originalId === id || b.id === id)
        if (collectionIndex >= 0) {
          orderedCollections.push(collections[collectionIndex])
          collections.splice(collectionIndex, 1)
        }
      }
    }
    orderedCollections.push(...collections.sort((a, b) => SortAndFilterUtil.compare(a.name, b.name)))
    if (order) void SettingsUtil.setLastViewedCollectionOrder('addressBook', orderedCollections.map(b => b.originalId || ''))
    return orderedCollections
  }

  get hasAddressbooks(): boolean {
    return this.addressbooks.length > 0
  }

  get selectedAddressbookObject(): AddressBook | null {
    return this.addressbookApi.getAddressBook(this.selectedAddressBook) || null
  }

  get selectedAddressbookName(): string | null {
    return this.selectedAddressbookObject?.name || null
  }

  addressBookIsActive(addressBook: AddressBook): boolean {
    return this.selectedAddressBook === addressBook.originalId
  }

  openAddressBook(addressBookId: string): void {
    void this.$router.push({path: `/contacts/${addressBookId}`})
    void SettingsUtil.setLastViewedCollection('addressBook', addressBookId)
  }

  createAdressbook(): void {
    if (this.newBook.name == "") return
    this.createBookLoading = true
    void addressBookServiceApi._createAddressBook(this.newBook).then(() => {
      this.toast.success(this.i18n.$gettext("Addressbook created"))
      this.showCreateBookModal = false
    }).catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext("Could not create Addressbook"))
    }).finally(() => {
      this.createBookLoading = false
    })
  }

  setupNewContact() {
    this.newContact = new Contact()
    this.newContact.structuredName = new StructuredName()
    this.newContact.formattedName = new TextValue()
  }

  createContact(){
    this.createContactLoading = true
    const bookId: string = this.selectedAddressBook
    if (!bookId || !this.newContact.formattedName || !this.newContact.structuredName) return
    this.newContact.formattedName.text = (this.newContact.structuredName.given || '') + " " + (this.newContact.structuredName.family || '')
    this.newContact.vCardVersion = "4.0"
    this.newContact.originalParentId = bookId
    contactServiceApi._addContact(this.newContact).then((id: string) => {
      this.toast.success(this.i18n.$gettext("Contact created"))
      this.showCreateContactModal = false
      this.setupNewContact()
      void this.$router.push({path: `/contacts/${bookId}/${id}`})
    }).catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext("Contact could not be created"))
    }).finally(() => { this.createContactLoading = false})
  }

  showAddressBookContextMenu(addressBook: AddressBook, event: Event) {
    this.updateMenuItems(addressBook)
    this.menu.hide()
    void this.$nextTick(() => {
      this.contextMenu.toggle(event)
    })
  }

  showAddressBookMenu(addressBook: AddressBook, event: Event) {
    this.updateMenuItems(addressBook)
    this.contextMenu.hide()
    void this.$nextTick(() => {
      this.menu.toggle(event)
    })
  }

  updateMenuItems(addressBook: AddressBook) {
    this.menuItems = []
    if (['OWNER'].includes(addressBook.shareAccess || '')) {
      this.menuItems.push({
        label: this.i18n.$gettext('Share with...'),
        icon: 'cil-share',
        command: () => {
          this.addressBookToShare = addressBook
        }
      })
    }
    this.menuItems.push({
      label: this.i18n.$gettext('Rename Addressbook'),
      icon:'cil-pencil',
      command: () => {
        this.addressBookToRename = Object.assign(new AddressBook(), addressBook)
      }
    })
    if (!addressBook.isDefault) {
      this.menuItems.push({
        label: this.i18n.$gettext('Delete'),
        icon: 'cil-trash',
        command: () => {
          this.deleteAddressBook(addressBook)
        }
      })
    }
  }

  saveName() {
    if (this.addressBookToRename) {
      this.renameLoading = true
      addressBookServiceApi._updateAddressBook(this.addressBookToRename).then(() => {
        this.toast.success(this.i18n.$gettext("Addressbook renamed"))
      }).catch((e: RpcError) => {
        this.toast.error(e.message, this.i18n.$gettext("Addressbook could not be renamed"))
      }).finally(() => {
        this.renameLoading = false
      })
      this.addressBookToRename = null
    }
  }

  deleteAddressBook(addressBook: AddressBook) {
    this.confirm.require({
      message: this.i18n.$gettext('Do you really want to delete this address book?'),
      header: this.i18n.$gettext('Confirmation'),
      icon: 'cil-warning',
      accept: () => {
        if (addressBook.originalId) {
          addressBookServiceApi._deleteAddressBook(addressBook.originalId).then(() => {
            this.toast.success(this.i18n.$gettext("Address book deleted"))
          }).catch((e: RpcError) => {
            this.toast.error(e.message, this.i18n.$gettext("Address book could not be deleted"))
          })
        }
      },
      reject: () => {
        //callback to execute when user rejects the action
      }
    })
  }

  get isAllowedToCreate(): boolean {
    if (this.selectedAddressbookObject) {
      return ['WRITE', 'OWNER'].includes(this.selectedAddressbookObject.shareAccess || '')
    }
    return false
  }

  get hasContactsBooked(): boolean {
    return featureSubset.hasDAV
  }

  get isOnMobile(){
    return breakpointUtil.isOnMobile()
  }

  mounted() {
    if (!this.selectedAddressBook) {
      let lastViewed: string | null | undefined = SettingsUtil.getLastViewedCollection('addressBook')
      const addressBook = (this.addressbooks || []).find((a: AddressBook) => a.originalId === lastViewed || a.id === lastViewed)
      if (!this.addressBooksAreLoading && !addressBook) {
        //This addressBook no longer exist.
        lastViewed = undefined
        //Reset settings
        void SettingsUtil.setLastViewedCollection('addressBook', '')
      }
      if (addressBook?.originalId) {
        this.openAddressBook(addressBook.originalId)
      } else {
        if(this.isOnMobile){
          this.openMobileMenuDefault = true
        } else {
          //Select default addressbook:
          if(this.addressbooks.length > 0){
            const defaultAddressBook: string | null = this.addressbooks[0].originalId
            if (defaultAddressBook) {
              this.openAddressBook(defaultAddressBook)
            }
          }
        }
      }
    }
  }
}
