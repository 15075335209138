import UserSettings from "@/model/settings/UserSettings"
import {rpcClient} from "@/api/WebsocketClient"
import ProjectCollectionOrder from "@/model/settings/ProjectCollectionOrder"
import LastViewedCollections from "@/model/settings/LastViewedCollections"
import LastViewedCollectionOrder from "@/model/settings/LastViewedCollectionOrder"
import {userServiceApi} from "@/api/UserServiceApi"
import DashboardSettings from "@/model/settings/DashboardSettings"

export default class SettingsUtil {

    static setLastViewedCollection(type: string, collectionId: string): Promise<void> {
        if (rpcClient.session.user) {
            let settings: UserSettings | undefined = rpcClient.session.user.settings
            if (!settings) {
                settings = new UserSettings()
                rpcClient.session.user.settings = settings
            }
            if (!settings.projectCollections) {
                settings.projectCollections = new ProjectCollectionOrder()
            }
            if (!settings.projectCollections.lastViewed) {
                settings.projectCollections.lastViewed = new LastViewedCollections()
            }
            (settings.projectCollections.lastViewed as any)[type] = collectionId
            return userServiceApi._saveSettings('projectCollections.lastViewed', rpcClient.session.user.settings)
        } else {
            return Promise.resolve()
        }
    }

    static getLastViewedCollection(type: string): string | undefined | null {
        const collections: LastViewedCollections = rpcClient.session.user?.settings?.projectCollections?.lastViewed
        return collections ? (collections as any)[type] : undefined
    }

    static setLastViewedCollectionOrder(type: string, collectionOrder: string[]): Promise<void> {
        if (rpcClient.session.user) {
            let settings: UserSettings | undefined = rpcClient.session.user.settings
            if (!settings) {
                settings = new UserSettings()
                rpcClient.session.user.settings = settings
            }
            if (!settings.projectCollections) {
                settings.projectCollections = new ProjectCollectionOrder()
            }
            if (!settings.projectCollections.order) {
                settings.projectCollections.order = new LastViewedCollectionOrder()
            }
            (settings.projectCollections.order as any)[type] = collectionOrder
            return userServiceApi._saveSettings('projectCollections.order', rpcClient.session.user.settings)
        } else {
            return Promise.resolve()
        }
    }

    static getLastViewedCollectionOrder(type: string): string[] | undefined | null {
        const order: LastViewedCollectionOrder | undefined = rpcClient.session.user?.settings?.projectCollections?.order
        return order ? ((order as any)[type] || []) : undefined
    }

    static setLastSetCalendarView(view: string): void {
        if (view !== 'dayGridMonth' && view !== 'timeGridWeek' && view !== 'timeGridDay'
            && view !== 'listMonth' && view !== 'listWeek' && view !== 'listDay') return

        if (rpcClient.session.user) {
            let settings: UserSettings | undefined = rpcClient.session.user.settings
            if (!settings) {
                settings = new UserSettings()
                rpcClient.session.user.settings = settings
            }

            if (settings.lastSetCalendarView !== view) {
                settings.lastSetCalendarView = view
                void userServiceApi._saveSettings('lastSetCalendarView', rpcClient.session.user.settings)
            }
        }
    }

    static getLastSetCalendarView(): string {
        const view: string = rpcClient.session.user?.settings?.lastSetCalendarView || 'dayGridMonth'
        if (view !== 'dayGridMonth' && view !== 'timeGridWeek' && view !== 'timeGridDay'
            && view !== 'listMonth' && view !== 'listWeek' && view !== 'listDay') return 'dayGridMonth'
        return view
    }

    static setHelpCenterSeen(): void {
        if (rpcClient.session.user) {
            let settings: UserSettings | undefined = rpcClient.session.user.settings
            if (!settings) {
                settings = new UserSettings()
                rpcClient.session.user.settings = settings
            }

            settings.helpCenterSeen = true

            void userServiceApi._saveSettings('helpCenterSeen', rpcClient.session.user.settings)
        }
    }

    static getHelpCenterSeen(): boolean {
        return rpcClient.session.user?.settings?.helpCenterSeen || false
    }

    static getIntroVideoSeen(): boolean {
        return rpcClient.session.user?.settings?.introVideoSeen || false

    }

    static setIntroVideoSeen(): void {
        if (rpcClient.session.user) {
            let settings: UserSettings | undefined = rpcClient.session.user.settings
            if (!settings) {
                settings = new UserSettings()
                rpcClient.session.user.settings = settings
            }

            settings.introVideoSeen = true

            void userServiceApi._saveSettings('introVideoSeen', rpcClient.session.user.settings)
        }
    }

    static setIntroVideoUnSeen(): void {
        if (rpcClient.session.user) {
            let settings: UserSettings | undefined = rpcClient.session.user.settings
            if (!settings) {
                settings = new UserSettings()
                rpcClient.session.user.settings = settings
            }

            settings.introVideoSeen = false

            void userServiceApi._saveSettings('introVideoSeen', rpcClient.session.user.settings)
        }
    }

    static setHideNoBackupJobsWarning(): void {
        if (rpcClient.session.user) {
            let settings: UserSettings | undefined = rpcClient.session.user.settings
            if (!settings) {
                settings = new UserSettings()
                rpcClient.session.user.settings = settings
            }

            settings.hideNoBackupJobsWarning = true

            void userServiceApi._saveSettings('hideNoBackupJobsWarning', rpcClient.session.user.settings)
        }
    }

    static getHideNoBackupJobsWarning(): boolean {
        if (rpcClient.session.user?.settings) {
            return rpcClient.session.user?.settings?.hideNoBackupJobsWarning || false
        } else {
            return true
        }
    }

    static getLoadImagesBySender(sender: string): boolean {
        const senders: string[] | null = rpcClient.session.user?.settings?.loadImagesBySender
        if (senders) {
            return senders.includes('*') || senders.includes(sender)
        } else {
            return false
        }
    }

    static addSenderToLoadImagesBySender(sender: string): Promise<any> {
        if (rpcClient.session.user) {
            let settings: UserSettings | undefined = rpcClient.session.user.settings
            if (!settings) {
                settings = new UserSettings()
                rpcClient.session.user.settings = settings
            }
            const senders: string[] = rpcClient.session.user.settings.loadImagesBySender || []
            if (senders.includes(sender)) {
                return Promise.resolve()
            } else {
                senders.push(sender)
                rpcClient.session.user.settings.loadImagesBySender = senders
                return userServiceApi._saveSettings('loadImagesBySender', rpcClient.session.user.settings)
            }
        } else {
            return Promise.reject('No user!')
        }
    }

    static showLoadImagesDialog(): boolean {
        return !rpcClient.session.user?.settings?.disableLoadImagesDialog
    }

    static disableLoadImagesDialog(): Promise<any> {
        if (rpcClient.session.user) {
            let settings: UserSettings | undefined = rpcClient.session.user.settings
            if (!settings) {
                settings = new UserSettings()
                rpcClient.session.user.settings = settings
            }
            settings.disableLoadImagesDialog = true
            return userServiceApi._saveSettings('disableLoadImagesDialog', rpcClient.session.user.settings)
        } else {
            return Promise.reject('No user!')
        }
    }

    static getDashboardSettings(): DashboardSettings  {
        return rpcClient.session.user?.settings?.dashboardSettings || new DashboardSettings()
    }

    static setDashboardMyEventsOnly(flag: boolean): void {
        if (rpcClient.session.user) {
            let settings: UserSettings | undefined = rpcClient.session.user.settings
            if (!settings) {
                settings = new UserSettings()
                rpcClient.session.user.settings = settings
            }
            if (!settings.dashboardSettings) {
                settings.dashboardSettings = new DashboardSettings()
            }

            settings.dashboardSettings.myEventsOnly = flag

            void userServiceApi._saveSettings('dashboardSettings.myEventsOnly', rpcClient.session.user.settings)
        }
    }

    static setDashboardDaysToWatchEvents(days: number): void {
        if (rpcClient.session.user) {
            let settings: UserSettings | undefined = rpcClient.session.user.settings
            if (!settings) {
                settings = new UserSettings()
                rpcClient.session.user.settings = settings
            }
            if (!settings.dashboardSettings) {
                settings.dashboardSettings = new DashboardSettings()
            }

            settings.dashboardSettings.daysToWatchEvents = days

            void userServiceApi._saveSettings('dashboardSettings.daysToWatchEvents', rpcClient.session.user.settings)
        }
    }

    static setDashboardDaysToWatchTasks(days: number): void {
        if (rpcClient.session.user) {
            let settings: UserSettings | undefined = rpcClient.session.user.settings
            if (!settings) {
                settings = new UserSettings()
                rpcClient.session.user.settings = settings
            }
            if (!settings.dashboardSettings) {
                settings.dashboardSettings = new DashboardSettings()
            }

            settings.dashboardSettings.daysToWatchTasks = days

            void userServiceApi._saveSettings('dashboardSettings.daysToWatchTasks', rpcClient.session.user.settings)
        }
    }

    static setDashboardSelectedCalendars(originalIds: string[]): void {
        if (rpcClient.session.user) {
            let settings: UserSettings | undefined = rpcClient.session.user.settings
            if (!settings) {
                settings = new UserSettings()
                rpcClient.session.user.settings = settings
            }
            if (!settings.dashboardSettings) {
                settings.dashboardSettings = new DashboardSettings()
            }

            settings.dashboardSettings.selectedCalendars = originalIds

            void userServiceApi._saveSettings('dashboardSettings.selectedCalendars', rpcClient.session.user.settings)
        }
    }

    static setDashboardSelectedTaskboards(originalIds: string[]): void {
        if (rpcClient.session.user) {
            let settings: UserSettings | undefined = rpcClient.session.user.settings
            if (!settings) {
                settings = new UserSettings()
                rpcClient.session.user.settings = settings
            }
            if (!settings.dashboardSettings) {
                settings.dashboardSettings = new DashboardSettings()
            }

            settings.dashboardSettings.selectedTaskBoards = originalIds

            void userServiceApi._saveSettings('dashboardSettings.selectedTaskBoards', rpcClient.session.user.settings)
        }
    }

    static setDashboardSelectedChannels(ids: string[]): void {
        if (rpcClient.session.user) {
            let settings: UserSettings | undefined = rpcClient.session.user.settings
            if (!settings) {
                settings = new UserSettings()
                rpcClient.session.user.settings = settings
            }
            if (!settings.dashboardSettings) {
                settings.dashboardSettings = new DashboardSettings()
            }

            settings.dashboardSettings.selectedChannels = ids

            void userServiceApi._saveSettings('dashboardSettings.selectedChannels', rpcClient.session.user.settings)
        }
    }
}

//@ts-ignore
window.settings = SettingsUtil
