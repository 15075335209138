import { rpcClient } from "@/api/WebsocketClient"
import SWR, { Call } from "@/api/SWR"
import { reactive } from "@vue/reactivity"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import EntryLink from '@/model/EntryLink';
import Page from '@/model/Page';
import { entryLinkStore } from '@/store/EntryLinkStore';

export default class GeneratedEntryLinkServiceApi {

    cache: Map<string, Call<any>> = new Map<string, Call<any>>()

    constructor() {
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.apis.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    clearState() {
        this.cache = new Map<string, Call<any>>()
    }

    get connected(): boolean {
        return rpcClient.state.connected
    }

    _createLink(entryLink: EntryLink): Promise<number> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('createLink', rpcParams, false).then((data: any) => {
            const model = Object.assign(new EntryLink(), data)
            entryLinkStore.addOrReplaceEntryLink(model)
            return model.id
        })
    }

    _getLinks(leftType: string, rightType: string, leftBackendId: string): Promise<EntryLink[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getLinks', rpcParams, true).then((data: any) => {
            if (data && Array.isArray(data)) {
                const entryLinks: EntryLink[] = data.map(entryLink => Object.assign(new EntryLink(), entryLink))
                entryLinkStore.addOrReplaceEntryLinks(entryLinks)
                return entryLinks
            } else return Promise.reject()
        })
    }


    getLinks(leftType: string, rightType: string, leftBackendId: string, refresh?: boolean | number): SWR<EntryLink[], number[]> {
        //@ts-ignore
        const result: SWR<EntryLink[], number[]> = reactive(new SWR<EntryLink[], number[]>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 3).filter(arg => arg !== undefined)
        const callId: string = '_getLinks' + JSON.stringify(args)
        const cached: Call<number[]> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_getLinks[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: number[]) => {
                const entryLinks: EntryLink[] = []
                for (const id of data) {
                    const entryLink: EntryLink | undefined = entryLinkStore.state.entryLinks.get(id)
                    if (entryLink) {
                        entryLinks.push(entryLink)
                    }
                }
                result.data = entryLinks
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<number[]>()) as Call<number[]>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._getLinks(leftType, rightType, leftBackendId).then((data: EntryLink[]) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data
                //@ts-ignore since Array.filter does not provide nullsafe guard
                call.data = data.map(entryLink => entryLink.id || '')
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        let entryLinks: EntryLink[] = [...entryLinkStore.state.entryLinks.values()]
        if (leftType) entryLinks = SortAndFilterUtil.filter(entryLinks, { leftType: leftType })
if (rightType) entryLinks = SortAndFilterUtil.filter(entryLinks, { rightType: rightType })
if (leftBackendId) entryLinks = SortAndFilterUtil.filter(entryLinks, { leftBackendId: leftBackendId })
        
        
        result.data = entryLinks
        return result
    }

    getEntryLinks(pageIndex?: number, pageSize?: number): EntryLink[] {
        let entryLinks: EntryLink[] = [...entryLinkStore.state.entryLinks.values()]
        
        
        if (pageSize !== null && pageSize !== undefined) {
            entryLinks = entryLinks.slice((pageIndex || 0) * pageSize, ((pageIndex || 0) + 1) * pageSize)
        }
        return entryLinks
    }

    getEntryLink(id: number): EntryLink | undefined {
        return entryLinkStore.state.entryLinks.get(id)
    }
}
