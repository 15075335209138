import { rpcClient } from "@/api/WebsocketClient"
import SWR, { Call } from "@/api/SWR"
import { reactive } from "@vue/reactivity"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import Email from '@/model/entry/Email';
import Page from '@/model/Page';
import Event from '@/model/entry/Event';
import Query from '@/model/common/Query';
import { emailStore } from '@/store/EmailStore';

export default class GeneratedMailServiceApi {

    cache: Map<string, Call<any>> = new Map<string, Call<any>>()

    constructor() {
        this.init()
    }

    init() {
        window.setTimeout(() => {
            if (rpcClient) {
                rpcClient.apis.push(this)
            } else {
                this.init()
            }
        }, 1)
    }

    clearState() {
        this.cache = new Map<string, Call<any>>()
    }

    get connected(): boolean {
        return rpcClient.state.connected
    }

    _move(originalId: string[], sourceFolderId: string, targetFolderId: string, copy: boolean | null): Promise<Email[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('move', rpcParams, false).then((data: any) => {
            if (data && Array.isArray(data)) {
                return data.map(email => {
                    const model = Object.assign(new Email(), email)
                    emailStore.removeEmail(email.originalId)
                    emailStore.addOrReplaceEmail(model)
                    return model
                })
            } else return Promise.reject()
        })
    }


    _sendMail(email: Email, draftOriginalId: string | null, replyToMessageId: string | null, appendMessageId: string | null): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('sendMail', rpcParams, false).then((data: any) => {
            const model = Object.assign(new Email(), data)
            emailStore.removeEmail(email.originalId)
            emailStore.addOrReplaceEmail(model)
            return model.originalId
        })
    }

    _handleIMIPReply(originalId: string, reply: Event, newStatus: string): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('handleIMIPReply', rpcParams, false).then((data: any) => {
            const model = Object.assign(new Email(), data)
            emailStore.removeEmail(originalId)
            emailStore.addOrReplaceEmail(model)
            return model.originalId
        })
    }

    _handleIMIPRequest(originalId: string, toUpdate: Event, newStatus: string): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('handleIMIPRequest', rpcParams, false).then((data: any) => {
            const model = Object.assign(new Email(), data)
            emailStore.removeEmail(originalId)
            emailStore.addOrReplaceEmail(model)
            return model.originalId
        })
    }

    _handleIMIPRefresh(originalId: string, event: Event): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('handleIMIPRefresh', rpcParams, false).then((data: any) => {
            const model = Object.assign(new Email(), data)
            emailStore.removeEmail(originalId)
            emailStore.addOrReplaceEmail(model)
            return model.originalId
        })
    }

    _handleIMIPCounter(originalId: string, counter: Event, accepted: boolean): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('handleIMIPCounter', rpcParams, false).then((data: any) => {
            const model = Object.assign(new Email(), data)
            emailStore.removeEmail(originalId)
            emailStore.addOrReplaceEmail(model)
            return model.originalId
        })
    }

    _getKnownEmailAddresses(): Promise<string[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getKnownEmailAddresses', rpcParams, false) as Promise<string[]>
    }

    _getEmailPreviews(originalParentId: string, sortBy: string | null, systemFlagsContainsOne: string[] | null, systemFlagsContainsNone: string[] | null, page: number, max: number): Promise<Page<Email>> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getEmailPreviews', rpcParams, true).then((data: any) => {
            if (data && Array.isArray(data.items)) {
                let emails: Email[] = data.items.map((email: any) => Object.assign(new Email(), email))
                emailStore.addOrReplaceEmails(emails)
                return Object.assign(new Page<Email>(), data)
            } else return Promise.reject()
        })
    }

    _markAllRead(folderId: string): Promise<void> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('markAllRead', rpcParams, false) as Promise<void>
    }

    _deleteMail(originalId: string): Promise<void> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('deleteMail', rpcParams, false).then(() => {
            emailStore.removeEmail(originalId)
        })
    }

    _updateFlags(originalId: string, systemFlags: any, userFlags: any): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('updateFlags', rpcParams, false).then((data: any) => {
            const model = Object.assign(new Email(), data)
            emailStore.addOrReplaceEmail(model)
            return model.originalId
        })
    }

    _updateMail(originalId: string, mail: Email): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('updateMail', rpcParams, false).then((data: any) => {
            const model = Object.assign(new Email(), data)
            emailStore.addOrReplaceEmail(model)
            return model.originalId
        })
    }

    _createDraftMail(mail: Email): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('createDraftMail', rpcParams, false).then((data: any) => {
            const model = Object.assign(new Email(), data)
            emailStore.addOrReplaceEmail(model)
            return model.originalId
        })
    }

    _queryEmails(query: Query, page: number, perPage: number, sortBy: string): Promise<Page<Email>> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('queryEmails', rpcParams, true).then((data: any) => {
            if (data && Array.isArray(data.items)) {
                let emails: Email[] = data.items.map((email: any) => Object.assign(new Email(), email))
                emailStore.addOrReplaceEmails(emails)
                return Object.assign(new Page<Email>(), data)
            } else return Promise.reject()
        })
    }

    _getEmailThread(threadId: string): Promise<Email[]> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getEmailThread', rpcParams, true).then((data: any) => {
            if (data && Array.isArray(data)) {
                const emails: Email[] = data.map(email => Object.assign(new Email(), email))
                emailStore.addOrReplaceEmails(emails)
                return emails
            } else return Promise.reject()
        })
    }


    _getFullEmail(originalId: string): Promise<string> {
        let rpcParams: any[] = Array.prototype.slice.call(arguments, 0, arguments.length).filter(arg => arg !== undefined)
        return rpcClient.call('getFullEmail', rpcParams, true).then((data: any) => {
            const model = Object.assign(new Email(), data)
            emailStore.addOrReplaceEmail(model)
            return model.originalId
        })
    }

    getEmailPreviews(originalParentId: string, sortBy: string | null, systemFlagsContainsOne: string[] | null, systemFlagsContainsNone: string[] | null, page: number, max: number, refresh?: boolean | number): SWR<Email[], Page<string>> {
        //@ts-ignore
        const result: SWR<Email[], Page<string>> = reactive(new SWR<Email[], Page<string>>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 6).filter(arg => arg !== undefined)
        const callId: string = '_getEmailPreviews' + JSON.stringify(args)
        const cached: Call<Page<string>> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_getEmailPreviews[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: Page<string>) => {
                const emails: Email[] = []
                for (const id of data.items || []) {
                    const email: Email | undefined = emailStore.state.emails.get(id)
                    if (email) {
                        emails.push(email)
                    }
                }
                result.data = emails
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<Page<string>>()) as Call<Page<string>>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._getEmailPreviews(originalParentId, sortBy, systemFlagsContainsOne, systemFlagsContainsNone, page, max).then((data: Page<Email>) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data.items
                call.data = {
                    //@ts-ignore since Array.filter does not provide nullsafe guard
                    items: data.items?.filter(email => !!email.originalId)?.map(email => email.originalId) || [],
                    total: data.total,
                    hasMore: data.hasMore,
                    nextId: data.nextId,
                    prevId: data.prevId
                }
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        let emails: Email[] = [...emailStore.state.emails.values()]
        if (originalParentId) emails = SortAndFilterUtil.filter(emails, { originalParentId: originalParentId })
if (systemFlagsContainsOne) emails = SortAndFilterUtil.containsOne(emails, { systemFlags: systemFlagsContainsOne })
if (systemFlagsContainsNone) emails = SortAndFilterUtil.containsNone(emails, { systemFlags: systemFlagsContainsNone })
        SortAndFilterUtil.sort(emails, sortBy)
        if (max !== null && max !== undefined) {
            emails = emails.slice((page || 0) * max, ((page || 0) + 1) * max)
        }
        result.data = emails
        return result
    }

    updateFlags(originalId: string, systemFlags: any, userFlags: any, refresh?: boolean | number): SWR<Email | null, string> {
        //@ts-ignore
        const result: SWR<Email | null, string> = reactive(new SWR<Email | null, string>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 3).filter(arg => arg !== undefined)
        const callId: string = '_updateFlags' + JSON.stringify(args)
        const cached: Call<string> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_updateFlags[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((originalId: string) => {
                result.data = emailStore.state.emails.get(originalId) || null
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<string>()) as Call<string>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            call.promise = this._updateFlags(originalId, systemFlags, userFlags).then((originalId: string) => {
                call.data = originalId
                result.data = emailStore.state.emails.get(originalId) || null
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        if (cached && cached.data) {
            result.data = emailStore.state.emails.get(cached.data) || null
        }
        return result
    }


    updateMail(originalId: string, mail: Email, refresh?: boolean | number): SWR<Email | null, string> {
        //@ts-ignore
        const result: SWR<Email | null, string> = reactive(new SWR<Email | null, string>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 2).filter(arg => arg !== undefined)
        const callId: string = '_updateMail' + JSON.stringify(args)
        const cached: Call<string> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_updateMail[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((originalId: string) => {
                result.data = emailStore.state.emails.get(originalId) || null
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<string>()) as Call<string>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            call.promise = this._updateMail(originalId, mail).then((originalId: string) => {
                call.data = originalId
                result.data = emailStore.state.emails.get(originalId) || null
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        if (cached && cached.data) {
            result.data = emailStore.state.emails.get(cached.data) || null
        }
        return result
    }


    queryEmails(query: Query, page: number, perPage: number, sortBy: string, refresh?: boolean | number): SWR<Email[], Page<string>> {
        //@ts-ignore
        const result: SWR<Email[], Page<string>> = reactive(new SWR<Email[], Page<string>>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 4).filter(arg => arg !== undefined)
        const callId: string = '_queryEmails' + JSON.stringify(args)
        const cached: Call<Page<string>> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_queryEmails[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: Page<string>) => {
                const emails: Email[] = []
                for (const id of data.items || []) {
                    const email: Email | undefined = emailStore.state.emails.get(id)
                    if (email) {
                        emails.push(email)
                    }
                }
                result.data = emails
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<Page<string>>()) as Call<Page<string>>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._queryEmails(query, page, perPage, sortBy).then((data: Page<Email>) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data.items
                call.data = {
                    //@ts-ignore since Array.filter does not provide nullsafe guard
                    items: data.items?.filter(email => !!email.originalId)?.map(email => email.originalId) || [],
                    total: data.total,
                    hasMore: data.hasMore,
                    nextId: data.nextId,
                    prevId: data.prevId
                }
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        if (cached && cached.data) {
            const emails: Email[] = []
            for (const id of cached.data.items || []) {
                const email: Email | undefined = emailStore.state.emails.get(id)
                if (email) {
                    emails.push(email)
                }
            }
            result.data = emails
        }
        return result
    }

    getEmailThread(threadId: string, refresh?: boolean | number): SWR<Email[], string[]> {
        //@ts-ignore
        const result: SWR<Email[], string[]> = reactive(new SWR<Email[], string[]>())
        const args: any[] = Array.prototype.slice.call(arguments, 0, 1).filter(arg => arg !== undefined)
        const callId: string = '_getEmailThread' + JSON.stringify(args)
        const cached: Call<string[]> | undefined = this.cache.get(callId)
        if (refresh === undefined && cached && cached.ended) {
            for (const [ id, call ] of this.cache) {
                if (id.startsWith('_getEmailThread[') && id !== callId && (!call.ended || call.ended > cached.ended)) {
                    refresh = 3000
                    break
                }
            }
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((data: string[]) => {
                const emails: Email[] = []
                for (const id of data) {
                    const email: Email | undefined = emailStore.state.emails.get(id)
                    if (email) {
                        emails.push(email)
                    }
                }
                result.data = emails
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<string[]>()) as Call<string[]>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            //@ts-ignore since Array.filter does not provide nullsafe guard
            call.promise = this._getEmailThread(threadId).then((data: Email[]) => {
                //@ts-ignore since Array.filter does not provide nullsafe guard
                result.data = data
                //@ts-ignore since Array.filter does not provide nullsafe guard
                call.data = data.map(email => email.originalId || '')
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        if (cached && cached.data) {
            const emails: Email[] = []
            for (const id of cached.data) {
                const email: Email | undefined = emailStore.state.emails.get(id)
                if (email) {
                    emails.push(email)
                }
            }
            result.data = emails
        }
        return result
    }

    getEmailsFilterByOriginalParentId(originalParentId: string, sortBy?: string[] | string, pageIndex?: number, pageSize?: number): Email[] {
        let emails: Email[] = [...emailStore.state.emails.values()]
        emails = SortAndFilterUtil.filter(emails, { originalParentId: originalParentId })
        SortAndFilterUtil.sort(emails, sortBy)
        if (pageSize !== null && pageSize !== undefined) {
            emails = emails.slice((pageIndex || 0) * pageSize, ((pageIndex || 0) + 1) * pageSize)
        }
        return emails
    }

    getEmailsFilterByThreadId(threadId: string, sortBy?: string[] | string, pageIndex?: number, pageSize?: number): Email[] {
        let emails: Email[] = [...emailStore.state.emails.values()]
        emails = SortAndFilterUtil.filter(emails, { threadId: threadId })
        SortAndFilterUtil.sort(emails, sortBy)
        if (pageSize !== null && pageSize !== undefined) {
            emails = emails.slice((pageIndex || 0) * pageSize, ((pageIndex || 0) + 1) * pageSize)
        }
        return emails
    }

    getEmailsFilterByQueryId(queryId: string, sortBy?: string[] | string, pageIndex?: number, pageSize?: number): Email[] {
        let emails: Email[] = [...emailStore.state.emails.values()]
        emails = SortAndFilterUtil.filter(emails, { queryId: queryId })
        SortAndFilterUtil.sort(emails, sortBy)
        if (pageSize !== null && pageSize !== undefined) {
            emails = emails.slice((pageIndex || 0) * pageSize, ((pageIndex || 0) + 1) * pageSize)
        }
        return emails
    }

    getEmails(sortBy?: string[] | string, pageIndex?: number, pageSize?: number): Email[] {
        let emails: Email[] = [...emailStore.state.emails.values()]
        
        SortAndFilterUtil.sort(emails, sortBy)
        if (pageSize !== null && pageSize !== undefined) {
            emails = emails.slice((pageIndex || 0) * pageSize, ((pageIndex || 0) + 1) * pageSize)
        }
        return emails
    }

    getFullEmail(originalId: string, refresh?: boolean | number): SWR<Email | null, string> {
        //@ts-ignore
        const result: SWR<Email | null, string> = reactive(new SWR<Email | null, string>())
        const callId: string = '_getFullEmail' + JSON.stringify(originalId)
        const cached: Call<string> | undefined = this.cache.get(callId)
        if (refresh === undefined) {
            refresh = 3000
        }
        if (cached && !cached.ended) {
            result.call = cached
            cached.promise?.then((originalId: string) => {
                result.data = emailStore.state.emails.get(originalId) || null
            })
        } else if (refresh !== -1 && (!cached || refresh === true || (typeof refresh === 'number' && (cached.ended || 0) < (Date.now() - refresh)))) {
            const call = reactive(new Call<string>()) as Call<string>
            this.cache.set(callId, call)
            call.loading = !cached
            call.refreshing = !!cached
            call.promise = this._getFullEmail(originalId).then((originalId: string) => {
                call.data = originalId
                result.data = emailStore.state.emails.get(originalId) || null
                return call.data
            }).catch(e => {
                setTimeout(() => {
                  this.cache.delete(callId)
                }, 1000)
                return Promise.reject(e)
            }).finally(() => {
                call.ended = Date.now()
                call.loading = false
                call.refreshing = false
            })
            result.call = call
        }
        result.data = emailStore.state.emails.get(originalId) || null
        return result
    }

}
