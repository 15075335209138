<template>
  <div class="h-100 d-flex flex-column" style="min-width: 0">
    <!--<div class="d-flex flex-row card card-lite z-1 pl-4 pr-4 sort-bar">
      <h6 class="mt-auto mb-auto d-inline text-dark"><translate>Messages</translate></h6>
      <MultiSelect class="mt-auto mb-auto ml-6 mr-6 flex-grow-1" v-model="sortBy" :options="sortOptions" :canDeselect="false" label="Sort By" inline/>
      <MultiSelect class="mt-auto mb-auto flex-grow-1" v-model="sortBy" :options="sortOptions" :canDeselect="false" label="Sort By" inline/>
    </div>-->
    <div class="flex-column flex-shrink-0 sort-bar separator-bottom bg-white mb-1 d-none d-md-flex">
      <MenuBar class="mt-auto mb-auto w-100 ">
        <template #start>
          <h1 class="h5 d-inline">
            <translate>Messages</translate>
          </h1>
        </template>
        <template #content>
          <div class="p-2 justify-content-end w-100 d-flex">
            <Dropdown
              v-model="sortBy"
              inline
              class="mt-auto mb-auto"
              label="Sort By"
              display="button"
              :options="sortOptions"
              option-label="label"
              :can-deselect="false"
            >
              <template #value>
                <translate>Sort By:</translate>&nbsp;{{ sortBy.label }}&nbsp;<i :class="sortBy.icon" />
              </template>
              <template #option="slotProps">
                <span><i :class="slotProps.option.icon" />&nbsp;&nbsp;{{ slotProps.option.label }}</span>
              </template>
            </Dropdown>
            <MultiSelect
              v-if="!searchQuery"
              v-model="filterBy"
              inline
              class="mt-auto mb-auto"
              label="Filter"
              display="button"
              placeholder="Filter"
              :options="filterOptions"
              option-label="label"
              :can-deselect="false"
            >
              <template #value>
                <translate>Filter</translate>
              </template>
            </MultiSelect>
          </div>
        </template>
      </MenuBar>
    </div>
    <div class="flex-shrink-1 flex-grow-1 result-list" style="min-height: 0">
      <InfiniteList
        v-if="folderId"
        :get-all-items="allItems"
        :get-item-page="itemPage"
        :page-size="pageSize"
        id-property="originalId"
      >
        <template #element="{ item }">
          <EmailListItem
            :folder-id="folderId"
            :item="item"
            :selected="emailId === item.originalId"
            :is-sent="isSentFolder"
            @click="selectEmail(item)"
            @email:reply="replyMessage"
            @email:forward="forwardMessage"
            @email:flag="flagMessage"
            @email:move="moveToFolder"
            @email:delete="deleteMessage"
          />
        </template>
        <template #empty>
          <div class="d-flex flex-column justify-content-center" style="min-height: 30rem">
            <div class="text-center">
              <p class="h5 mb-2">
                <translate>No Emails in this Folder</translate>
              </p>
              <p><translate>Emails will be shown as soon as you move them here or receive new ones.</translate></p>
            </div>
          </div>
        </template>
      </InfiniteList>
      <div v-else class="p-4">
        <p class="text-dark">
          <translate>Please enter a search term...</translate>
        </p>
      </div>
    </div>
    <EmailComposer ref="composer" />
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component'
import {mailServiceApi} from "@/api/MailServiceApi"
import SWR from "@/api/SWR"
import EmailListItem from "@/components/email/EmailListItem.vue"
import InfiniteList from "@/components/common/InfiniteList.vue"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import MenuBar from "@/components/common/MenuBar.vue"
import EmailComposer from "@/components/email/EmailComposer.vue"
import { ref } from "@vue/reactivity"
import EmailUtil from "@/util/EmailUtil"
import RpcError from "@/api/RpcError"
import useToast from "@/util/toasts"
import Email from "@/model/entry/Email"
import SortAndFilterUtil from "@/util/SortAndFilterUtil"
import MultiSelect from "primevue/multiselect"
import Dropdown from "primevue/dropdown"
import {mailFolderStore} from "@/store/MailFolderStore"
import FocusListener from "@/util/focusUtil"
import demoService from "@/util/demoService"
import Page from "@/model/Page"
import Query from "@/model/common/Query"

@Options({
  components: {
    //@ts-ignore
    InfiniteList, Dropdown, EmailListItem, MenuBar, EmailComposer, MultiSelect
  },
  //@ts-ignore
  props: {
    folderId: String,
    emailId: String,
    searchQuery: {
      type: [ Query, Object ],
      default: null
    }
  },
  emits: ['compose:email']
})
export default class EmailList extends Vue {

  i18n: Language = useGettext()
  toast = useToast()

  folderId!: string
  emailId!: string
  searchQuery!: Query | null

  //@ts-ignore
  composer: EmailComposer = ref<EmailComposer | null>(null)

  focusListener: FocusListener = new FocusListener(() => {
    if (this.folderId) {
      mailServiceApi.getEmailPreviews(this.folderId, this.sortBy.value, this.systemFlagsContainsOne, null, 0, 100, 30000)
    }
  })

  pageSize = 50
  sortOptions: { value: string, label: string, icon: string, searchValue: string }[] = [
    { value: 'receivedDate:desc', label: this.i18n.$gettext('Date'), icon: 'fa fa-arrow-down', searchValue: 'ctime:desc' },
    { value: 'receivedDate:asc', label: this.i18n.$gettext('Date'), icon: 'fa fa-arrow-up', searchValue: 'ctime:asc' },
    { value: 'from:asc', label: this.i18n.$gettext('Sender'), icon: 'fa fa-arrow-up', searchValue: 'meta:from:asc' },
    { value: 'from:desc', label: this.i18n.$gettext('Sender'), icon: 'fa fa-arrow-down', searchValue: 'meta:from:desc' }
  ]
  sortBy: { value: string, label: string, icon: string, searchValue: string } = this.sortOptions[0]

  filterOptions: { value: string, label: string, icon: string }[] = [
    { value: 'flagged', label: this.i18n.$gettext('Flagged'), icon: '' },
    { value: 'unread', label: this.i18n.$gettext('Unread'), icon: '' },
  ]
  filterBy: { value: string, label: string, icon: string }[] = []

  setSortBy(event: any): void {
    if (event?.item?.value) {
      this.sortBy = event.item.value
    }
  }

  get sortLabel(): string {
    const sortBy = this.sortBy
    const option: any = this.sortOptions.find(option => option.value === sortBy.value)
    return option ? option.label : ''
  }

  get total(): number | null {
    return this.folderId ? Math.max(mailServiceApi.state.total || 0, this.allItems.length) : this.allItems.length
  }

  get allItems(): Email[] {
    const sortBy = this.sortBy //Access sortBy in order to make it reactive!
    let emails: Email[] = mailServiceApi.getEmailsFilterByOriginalParentId(this.folderId, sortBy.value)
    let systemFlagsContainsOne: string[] | null = this.systemFlagsContainsOne
    if (this.filterBy.find(f => f.value.includes('flagged'))) {
      emails = SortAndFilterUtil.containsOne(emails, { systemFlags: systemFlagsContainsOne })
    }
    return emails
  }

  get itemPage(): ((pageIndex: number, pageSize: number) => SWR<Email[], Page<string>>) | null {
    const folderId = this.folderId
    const sortBy = this.sortBy //Access sortBy in order to make it reactive!
    if (this.searchQuery) {
      const searchQuery = this.searchQuery
      return (pageIndex: number, pageSize: number) => {
        //Short refresh threshold because otherwise changing sort order does not always work => needs investigation!
        return mailServiceApi.queryEmails(searchQuery, pageIndex, pageSize, sortBy.searchValue, 1000)
      }
    } else if (folderId) {
      let systemFlagsContainsOne: string[] | null = this.systemFlagsContainsOne
      return (pageIndex: number, pageSize: number) => {
        let swr: SWR<Email[], Page<string>> = mailServiceApi.getEmailPreviews(folderId, sortBy.value, systemFlagsContainsOne, null, pageIndex, pageSize, 120000)
        if (swr.call?.promise) swr.call?.promise.then((page: Page<string>) => {
          if (typeof page.total === 'number') {
            mailServiceApi.state.total = page.total
            return page.total
          } else {
            return page.hasMore
          }
        }).catch((e: RpcError) => {
          this.toast.error(e.message, this.i18n.$gettext('Failed to load results.'))
        })
        return swr
      }
    } else {
      return null
    }
  }

  get systemFlagsContainsOne(): string[] | null {
    const filterBy = this.filterBy
    let systemFlagsContainsOne: string[] | null = null
    if (filterBy.find(f => f.value.includes('unread'))) {
      systemFlagsContainsOne = []
      systemFlagsContainsOne.push('UNSEEN')
    }
    if (filterBy.find(f => f.value.includes('flagged'))) {
      if (!systemFlagsContainsOne) {
        systemFlagsContainsOne = []
      }
      systemFlagsContainsOne.push('FLAGGED')
    }
    return systemFlagsContainsOne
  }

  get isSentFolder(): boolean {
    return !!(this.folderId && mailFolderStore.state.mailFolders.get(this.folderId)?.type === '\\Sent')
  }

  get isDraftFolder(): boolean {
    return !!(this.folderId && mailFolderStore.state.mailFolders.get(this.folderId)?.type === '\\Drafts')
  }

  selectEmail(email: Email): void {
    if (email && email.originalId && email.systemFlags && this.isDraftFolder) {
      mailServiceApi.getFullMail(email.originalId).then((fullMail: Email | null) => {
        if (fullMail) this.composer.show(fullMail, email.originalId, this.folderId, null, null)
      }).catch((e: RpcError) => {
        this.toast.error(e.message, this.i18n.$gettext("Failed to get message from server"))
      })
    } else if (email.originalId) {
      const originalId: string = email.originalId
      void mailServiceApi._updateFlags(originalId, {SEEN: true}, {}).finally(() => {
        void mailServiceApi.getFullMail(originalId).then((fullMail: Email | null) => {
          if (fullMail && !fullMail.systemFlags) fullMail.systemFlags = [ 'SEEN' ]
          else if (fullMail && !fullMail.systemFlags?.includes('SEEN')) fullMail.systemFlags?.push('SEEN')
        })
      })
      void this.$router.push('/mail/' + this.folderId + '/' + email.originalId)
    }
  }

  flagMessage(event: {id: string, folderId: string, systemFlags: any}) {
    mailServiceApi._updateFlags(event.id, event.systemFlags, {}).catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext("Could not update flag"))
    })
  }

  deleteMessage(event: {id: string, folderId: string}) {
    mailServiceApi._deleteMail(event.id).then(() => {
      this.toast.success(this.i18n.$gettext("Email deleted"))
    }).catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext("Could not delete message"))
    })
    if (this.$route?.params?.hasOwnProperty("email") && this.$route.params["email"] === event.id) {
      void this.$router.push('/mail/' + (this.$route.params['folder'] || ''))
    } else {
      return null
    }
  }

  moveToFolder(event: {id: string, sourceFolderId: string, targetFolderId: string}) {
    mailServiceApi._move([ event.id ], event.sourceFolderId, event.targetFolderId, false).then(() => {
      this.toast.success(this.i18n.$gettext("Email moved"))
    }).catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext("Could not move email"))
    })
    if (this.$route?.params?.hasOwnProperty("email") && this.$route.params["email"] === event.id) {
      void this.$router.push('/mail/' + (this.$route.params['folder'] || ''))
    } else {
      return null
    }
  }

  replyMessage(event: {id: string, folderId: string, replyAll: boolean}): Promise<void> {
    return mailServiceApi.getFullMail(event.id).then((email: Email | null) => {
      if (email) {
        const reply: Email = EmailUtil.createReply(email, event.replyAll)
        this.composer.show(reply, null, event.folderId, event.id, null)
      }
    }).catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext("Failed to get message from server"))
    })
  }

  forwardMessage(event: {id: string, folderId: string, replyAll: boolean}) {
    return mailServiceApi.getFullMail(event.id).then((email: Email | null) => {
      if (email) {
        const forward: Email = EmailUtil.createForward(email)
        this.composer.show(forward, null, event.folderId, null, event.id)
      }
    }).catch((e: RpcError) => {
      this.toast.error(e.message, this.i18n.$gettext("Failed to get message from server"))
    })
  }

  unmounted() {
    demoService.setIntervalCallback(() => {})
    this.focusListener.remove()
  }
}
</script>

<style scoped lang="scss">
.sort-bar {
  //height: 80px
}
.result-list {
  //height: calc(100% - 180px)
}
@media screen and (min-width: 1200px) {
  .sort-bar {
    height: 80px
  }
  .result-list {
    //height: calc(100% - 80px)
  }
}
</style>
