

import {Options, Vue} from "vue-class-component"
import Calendar from "@/model/directory/Calendar"
import SWR from "@/api/SWR"
import {calendarServiceApi} from "@/api/CalendarServiceApi"
import {eventServiceApi} from "@/api/EventServiceApi"
import CalendarEvent from "@/model/entry/Event"
import dayjs from "@/util/dayjs"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import Skeleton from "primevue/skeleton"
import DateTimeUtil from "@/util/DateTimeUtil"
import {rpcClient} from "@/api/WebsocketClient"
import InfiniteList from "@/components/common/InfiniteList.vue"
import {ref} from "@vue/reactivity"
import OverlayPanel from "primevue/overlaypanel"
import Checkbox from "primevue/checkbox"
import DashboardSettings from "@/model/settings/DashboardSettings"
import SettingsUtil from "@/util/SettingsUtil"
import Slider from "primevue/slider"
import Button from "primevue/button"
import {toEvent} from "@/router"

@Options({
  components: { Skeleton, InfiniteList, OverlayPanel, Checkbox, Slider, Button },
  //@ts-ignore
  props: {},
  emits: []
})
export default class CalendarWidget extends Vue {

  calendarsAreLoading : boolean = false
  eventsAreLoading: boolean = false
  i18n: Language = useGettext()

  //@ts-ignore
  settingsPanel: OverlayPanel = ref<OverlayPanel | null>(null);

  dashboardSettings: DashboardSettings = new DashboardSettings()
  myEventsOnly: boolean = false
  selectedCalendars: string[] = []
  daysToWatchEvents: number = 1
  myEventsOnlySettings: boolean = false
  selectedCalendarsSettings: string[] = []
  daysToWatchEventsSettings: number = 1

  goToItem(item: CalendarEvent): void {
    if (item.originalParentId && item.originalId) {
      toEvent(item.originalParentId, item.originalId)
    }
  }

  calendarCheckboxStyle(cal: Calendar): any {
    if (cal.colorHex) {
      return {
        'background-color': cal.colorHex
      }
    }
  }

  toggleOverlayPanel(e: Event) {
    this.resetSettings()
    this.settingsPanel.toggle(e)
  }

  resetSettings(): void {
    this.myEventsOnlySettings = this.myEventsOnly
    this.selectedCalendarsSettings = this.selectedCalendars
    this.daysToWatchEventsSettings = this.daysToWatchEvents
  }

  updateSelection(): void {
    this.myEventsOnly = this.myEventsOnlySettings
    this.selectedCalendars = this.selectedCalendarsSettings
    this.daysToWatchEvents = this.daysToWatchEventsSettings
    SettingsUtil.setDashboardMyEventsOnly(this.myEventsOnly)
    SettingsUtil.setDashboardDaysToWatchEvents(this.daysToWatchEvents)
    SettingsUtil.setDashboardSelectedCalendars(this.selectedCalendars)
    this.settingsPanel.hide()
  }

  itemDayString(event: CalendarEvent) : string {
    if(!event.start) return ""
    let start = dayjs(event.start)

    if(start.isToday()){
      return this.i18n.$gettext("Today at")
    } else {
      return start.fromNow() + start.format(', dddd DD.MM ') + this.i18n.$gettext('at')
    }
  }

  elementClass(event: CalendarEvent, beginn: number): string[] {
    let result = []
    const time = dayjs().unix()
    const min = 60 //in seconds
    if (event.allDay) {
      result.push('is-all-day')
    } else if (beginn <= time) {
      result.push('in-progress')
    } else if (beginn - time < 5*min) {
      result.push('about-to-start')
    } else if (beginn - time < 15*min) {
      result.push('get-ready')
    }
    return result
  }

  get isLoading(): boolean {
    return this.calendarsAreLoading || this.eventsAreLoading
  }

  get start(): string {
    let startOfDay = new Date()
    startOfDay.setHours(23,59,59,999)
    startOfDay.setDate(startOfDay.getDate() - 1)
    return startOfDay.toISOString()
  }

  get end(): string {
    const endOfDay = new Date()
    endOfDay.setHours(23,59,59,999)
    if (this.daysToWatchEvents > 1) {
      endOfDay.setDate(endOfDay.getDate() + this.daysToWatchEvents)
    }
    return endOfDay.toISOString()
  }

  get allCalendars(): Calendar[] {
    const swr: SWR<Calendar[], string[]> = calendarServiceApi.getCalendars(false)
    if (swr.call?.loading && swr.call?.promise){
      this.calendarsAreLoading = true
      swr.call.promise.finally(() => {
        this.calendarsAreLoading = false
      })
    }
    return swr.data || []
  }

  get calendars(): Calendar[] {
    if (this.selectedCalendars.length <= 0) return this.allCalendars
    let selected: Calendar[] = []
    this.selectedCalendars.forEach((originalId) => {
      const cal: Calendar | undefined = this.allCalendars.find((c) => c.originalId && c.originalId == originalId)
      if (cal !== undefined) selected.push(cal)
    })
    if (selected.length <= 0) {
      //selected calendars seem not to exist anymore
      //reset selection and return all calenders
      SettingsUtil.setDashboardSelectedCalendars([]) //empty list means all
      return this.allCalendars
    }
    return selected
  }

  get eventCount(): number {
    return this.allItems?.length || 0
  }

  shouldInclude(event: CalendarEvent): boolean {
    return !this.myEventsOnly || this.isAttendee(event) || this.isOrganizer(event)
  }

  isOrganizer(event: CalendarEvent) : boolean {
    return event.organizer ? event.organizer.email == this.userEmail : false
  }

  isAttendee(event: CalendarEvent): boolean {
    if (!this.userEmail) return false
    return Boolean(event.attendees?.find(attendee => attendee.email && attendee.email === this.userEmail && attendee.status !== "DECLINED" && attendee.status !== "DELEGATED"))
  }

  get userEmail(): string | null {
    return rpcClient.session?.user?.email || null
  }

  get allItems(): { id: string, desc: string, date: string, start: string, end: string, duration: string, begin: number,calendarName: string, event: CalendarEvent }[] {
    const allEvents: { id: string, desc: string, date: string, start: string, end: string, duration: string, begin: number,calendarName: string, event: CalendarEvent }[] = []
    let promises: Promise<any>[] = []
    for (const calendar of this.calendars) {
      const calendarId = calendar.originalId
      if (!calendarId) continue
      try {
        const swr = eventServiceApi.getEvents(calendarId, this.start, this.end, false)
        if (swr.call?.loading && swr.call?.promise) {
          this.eventsAreLoading = true
          promises.push(swr.call.promise)
        }
        const events: CalendarEvent[] = swr.data ? [...swr.data] : []
        const stamp: Date = new Date()
        events.forEach((ce: CalendarEvent) => {
          if (ce.summary && ce.start) {
            if (this.shouldInclude(ce)) {
              if (!ce.end) {
                ce.end = new Date(Date.parse(ce.start) + DateTimeUtil.getDurationInMillis(ce)).toISOString()
              }
              if (stamp < new Date(ce.end)) {
                const newElem = {
                  id: ce.originalId || "",
                  desc: ce.summary,
                  date: dayjs(ce.start).format("DD.MM.YYYY"),
                  start: dayjs(ce.start).format("HH:mm"),
                  begin: dayjs(ce.start).unix(),
                  end: dayjs(ce.end).format("HH:mm"),
                  duration: this.getDurationString(ce),
                  calendarName: (calendar?.name) ? calendar.name : "",
                  event: ce
                }
                allEvents.push(newElem)
              }
            }
          }
        })
      } catch (error) {}
    }
    if (promises.length > 0){
      Promise.all(promises).finally(() => {
        this.eventsAreLoading = false
      })
    }
    return allEvents.sort(({begin:a}, {begin:b}) => a-b)
  }

  getDurationString(event: CalendarEvent): string {
    const millis = DateTimeUtil.getDurationInMillis(event)
    const seconds = Math.floor(millis / 1000)
    const hours =  Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds - (hours * 3600)) / 60)
    if (hours === 0){
      return minutes + " " + this.i18n.$gettext("Minutes")
    } else {
      return `${hours}:${minutes}`
    }
  }

  getCalendarForEvent(event: CalendarEvent): Calendar | undefined {
    return this.calendars.find((c: Calendar) => {
      return c.originalId === event.originalParentId
    })
  }

  beforeMount() {
    this.dashboardSettings = SettingsUtil.getDashboardSettings()
    this.myEventsOnly = this.dashboardSettings.myEventsOnly || false
    this.selectedCalendars = this.dashboardSettings.selectedCalendars || []
    this.daysToWatchEvents = this.dashboardSettings.daysToWatchEvents || 1
    if (!this.selectedCalendars) {
      this.allCalendars.forEach((cal) => {
        if (cal.originalId) this.selectedCalendars.push(cal.originalId)
      })
    }
  }
}
