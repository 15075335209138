
import {Options, Vue} from "vue-class-component"
import Button from "primevue/button"
import AutoComplete from "primevue/autocomplete"
import Dropdown from "primevue/dropdown"
import {Language, useGettext} from "@jshmrtn/vue3-gettext"
import User from "@/model/User"
import {userServiceApi} from "@/api/UserServiceApi"
import EmailUtil from "@/util/EmailUtil"
import {Watch} from "vue-property-decorator"
import {rpcClient} from "@/api/WebsocketClient"
import useToast from "@/util/toasts"
import Avatar from "@/components/common/Avatar.vue"

@Options({
  //@ts-ignore
  props: {
    modelValue: Array,
    accessOptions: {
      type: Array,
      default: null
    },
    onlyAllowSuggestions: {
      type: Boolean,
      default: false
    },
    ignoreSessionUser: {
      type: Boolean,
      default: true
    }
  },
  components: {
    Avatar, Button, AutoComplete, Dropdown
  },
  emits: [  'added', 'removed','updated', 'update:modelValue' ]
})
export default class UserSharingInput extends Vue {

  i18n: Language = useGettext()
  toast = useToast()

  modelValue!: any[]
  accessOptions!: any[] | null
  onlyAllowSuggestions = false
  ignoreSessionUser = true

  sharedTo: any[] = []
  selectableUsers: string[] = []
  newSharee = ''
  myUsername = rpcClient.session.user?.userName

  get tAccessOptions(): any[] {
    return this.accessOptions ||  [
      {
        id: 'READ',
        name: this.i18n.$gettext('Read Only')
      },
      {
        id: 'WRITE',
        name: this.i18n.$gettext('Read & Write')
      }
    ]
  }

  get users(): User[] {
    return userServiceApi.getUsers().data || []
  }

  filterUsers(event: any) {
    let users = this.users
    return this.selectableUsers = users.filter((user: User) => {
      return user.userName &&
        (user.userName != rpcClient.session.user?.userName || !this.ignoreSessionUser) &&
        user.userName?.toLowerCase()?.indexOf(event.query?.toLowerCase()) !== -1 &&
        !this.sharedTo.find((a: any) => (!a.userName && a.sharee === ('mailto:' + user.email)) || a.userName === user.userName)
    }).map((user: User) => {
      return user.userName || ""
    }).filter((str: string) => {return str !== "" })
  }

  addSharee() {
    const email = this.newSharee
    if (email === "" || !!this.sharedTo.find(a => (!a.userName && a.sharee === ('mailto:' + email)) || (a.userName || a.commonName) === email)) {
      return
    } else if (EmailUtil.isValidEmail(email)) {
      const sharee: any = {}
      const user: User | undefined = this.users.find(u => u.cn === email)
      sharee.sharee = 'mailto:' + email
      if (user) sharee.userName = user.userName
      sharee.commonName = (user && user.userName) ? user.userName : email
      if (this.tAccessOptions && this.tAccessOptions.length > 0) sharee.accessRight = this.tAccessOptions[0].id
      this.sharedTo.push(sharee)
      this.$emit('added', sharee)
      this.$emit('update:modelValue', this.sharedTo)
      this.newSharee = ""
    } else {
      this.toast.error(this.i18n.$gettext("Attendee must be an email address"))
    }
  }

  addShareeViaEnter() {
    if (!this.onlyAllowSuggestions) {
      this.addSharee()
    }
  }

  updateSharee(sharee: any) {
    this.$emit('updated', sharee)
  }

  removeSharee(sharee: any) {
    const removed: number = this.sharedTo.indexOf(sharee)
    if (removed >= 0) {
      const removedSharee = this.sharedTo.splice(removed, 1)[0]
      this.$emit('removed', removedSharee)
      this.$emit('update:modelValue', this.sharedTo)
    }
  }

  @Watch('modelValue')
  onValueChanged(sharedTo: any[]) {
    this.sharedTo = sharedTo
  }

  mounted() {
    this.onValueChanged(this.modelValue)
  }
}
